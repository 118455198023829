const formatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2
});

const functions = {
  /**
   * accepts dateString object or in string format
   * and returns formatted date as a string
   */
  formatDate(dateString) {
    if (!dateString || dateString.length <= 0) {
      return null;
    }

    // if(typeof dateString == 'string'){
    //   dateString = dateString.split('T')[0]
    // }
    return this.$moment(dateString).utc().format("ddd MMM DD");
    // return this.$moment(dateString).format('MM/DD/YYYY');
  },

  /**
   * accepts dateString object and months as arguments
   * and returns formatted date as a string with months added into dateString
   */
  addMonthsTo(dateString, months) {
    if (!dateString || dateString.length <= 0) {
      return null;
    }
    if (typeof dateString == "string") {
      dateString = dateString.split("T")[0];
    }
    return this.$moment(dateString).add(months, "M").format("ddd MMM DD");
  },

  /**
   * accepts price as a parameter and returns price in a string format with commas
   */
  formatPrice(val) {
    // return val
    console.log(val);
    const price = parseFloat(val);
    console.log(price);
    if (isNaN(price)) {
      return "N/A";
    }
    // let fp = Math.trunc(price * 100) / 100;
    // return formatter.format(fp.toString());
    return price.toFixed(2);
  }
};

export default functions;

 

  /**
   * Extracts any useful information from the database,
   * and pushes unto the error messages array.
   */
    export function extractError(err){
      let errorMessages = [];
      if(typeof err === "string"){
        errorMessages.push(err)
      }
      else if(err.message !== null && typeof err.message !== 'undefined'){
        errorMessages.push(err.message);
      }
      else if(err.data){
        errorMessages.push(err.data);
      }
      else{
        errorMessages.push("Error Ocurred");
      }
      return errorMessages;
    }

 
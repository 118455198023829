<template>
  <b-form @submit.prevent="saveData">
    <b-row>
      <b-col cols="12" id="formColumn">
        <b-card>
          <form ref="exp_form" class="inp_form">
            <div id="formId" class="form-row" ref="exp_form">
              <div
                ref="upload"
                id="phoneUpload"
                class="form-group full-row-title col-12 col-sm-4 col-md-4 col-lg-4 label"
                style="display: block"
              >
                <label for="DropBox">Upload Resume:</label>
              </div>
              <div
                ref="filepond"
                style="display: block"
                class="form-group col-12 col-sm-8 col-md-8 col-lg-8 short"
              >
                <file-pond
                  ref="pond"
                  id="filepond"
                  label-idle="Click to select file to upload"
                  v-bind:allow-multiple="false"
                  :server="myServer"
                  :allow-revert="false"
                  maxFileSize="10MB"
                  accepted-file-types="image/png, image/jpeg, application/pdf, application/msword"
                />
                <div v-if="imgColumnValidation" style="color: red">
                  Resume is required.
                </div>
              </div>
              <div v-if="loading" class="loading-spinner"></div>
              <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4 phone">
                <label for="Discipline">Discipline:</label>
              </div>
              <div class="form-group col-12 col-sm-8 col-md-8 col-lg-8 phone">
                <b-form-input
                  class="bars disableClass disableClass2"
                  v-debounce:700ms="updateDiscipline"
                  id="Discipline"
                  name="Discipline"
                  type="text"
                  placeholder="Add Discipline"
                  v-model="formData.discipline"
                ></b-form-input>
                <div v-if="disciplineColumnValidation" style="color: red">
                  Discipline is required.
                </div>
              </div>
              <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4 phone">
                <label for="FName">Name:</label>
              </div>
              <div class="form-group col-12 col-sm-8 col-md-8 col-lg-4 phone">
                <b-form-input
                  class="bars disableClass disableClass2"
                  v-debounce:700ms="updateFName"
                  id="FName"
                  name="FName"
                  type="text"
                  placeholder="Add First Name"
                  v-model="formData.firstName"
                ></b-form-input>
                <div v-if="FNameColumnValidation" style="color: red">
                  First name is required.
                </div>
              </div>
              <div class="form-group col-12 col-sm-8 col-md-8 col-lg-4 phone">
                <b-form-input
                  class="bars disableClass disableClass2"
                  v-debounce:700ms="updateLName"
                  id="LName"
                  name="LName"
                  type="text"
                  placeholder="Add Last Name"
                  v-model="formData.lastName"
                ></b-form-input>
                <div v-if="LNameColumnValidation" style="color: red">
                  Last name is required.
                </div>
              </div>
              <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4 phone">
                <label for="Email">Email:</label>
              </div>
              <div class="form-group col-12 col-sm-8 col-md-8 col-lg-8 phone">
                <b-form-input
                  class="bars"
                  v-debounce:700ms="updateEmail"
                  id="Email"
                  name="Email"
                  type="email"
                  placeholder="Add Email"
                  v-model="formData.email"
                ></b-form-input>
                <div v-if="EmailColumnValidation" style="color: red">
                  Valid Email is required.
                </div>
              </div>

              <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4 phone">
                <label for="LocalToCalgary">Local to Calgary:</label>
              </div>
              <div class="form-group col-12 col-sm-8 col-md-8 col-lg-8 phone">
                <b-form-checkbox
                  v-debounce:700ms="updateLocal"
                  id="LocalToCalgary"
                  name="LocalToCalgary"
                  v-model="formData.localToCalgary"
                ></b-form-checkbox>
              </div>

              <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4 phone">
                <label for="ApplicationDate">Application Date:</label>
              </div>
              <div class="form-group col-12 col-sm-8 col-md-8 col-lg-8 phone">
                <b-form-input
                  class="bars"
                  v-debounce:700ms="updateApplicationDate"
                  id="ApplicationDate"
                  name="ApplicationDate"
                  type="date"
                  v-model="formData.applicationDate"
                ></b-form-input>
                <div v-if="ApplicationDateColumnValidation" style="color: red">
                  Application Date is required.
                </div>
              </div>

              <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4 phone">
                <label for="InterviewDate">Interview Date:</label>
              </div>
              <div class="form-group col-12 col-sm-8 col-md-8 col-lg-8 phone">
                <b-form-input
                  class="bars"
                  v-debounce:700ms="updateInterviewDate"
                  id="InterviewDate"
                  name="InterviewDate"
                  type="date"
                  v-model="formData.interviewDate"
                ></b-form-input>
                <div v-if="InterviewDateColumnValidation" style="color: red">
                  Interview Date is required.
                </div>
              </div>

              <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4 phone">
                <label for="InterviewNotes">Interview Notes:</label>
              </div>
              <div class="form-group col-12 col-sm-8 col-md-8 col-lg-8 phone">
                <b-form-textarea
                  class="bars"
                  v-debounce:700ms="updateInterviewNotes"
                  id="InterviewNotes"
                  name="InterviewNotes"
                  v-model="formData.interviewNotes"
                  max-rows="7"
                ></b-form-textarea>
                <!-- <div v-if="InterviewNotesColumnValidation" style="color: red">
                   is required.
                </div> -->
              </div>

              <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4 phone">
                <label for="Status">Status:</label>
              </div>
              <div class="form-group col-12 col-sm-8 col-md-8 col-lg-4 phone">
                <b-form-select
                  class="bars"
                  v-debounce:700ms="updateStatus"
                  id="Status"
                  name="Status"
                  v-model="formData.status"
                >
                  <option value="Rejected" style="background-color: red">
                    Rejected
                  </option>
                  <option
                    value="Shortlisted"
                    style="background-color: lightgreen"
                  >
                    Shortlisted
                  </option>
                  <option
                    value="Offer Pending"
                    style="background-color: rgb(171, 171, 5)"
                  >
                    Offer Pending
                  </option>
                  <option
                    value="Onboarding"
                    style="background-color: rgb(95, 95, 230)"
                  >
                    Onboarding
                  </option>
                </b-form-select>
                <div v-if="StatusColumnValidation" style="color: red">
                  Status is required.
                </div>
              </div>
            </div>
          </form>
          <div class="d-flex justify-content-end pt-5">
            <span ref="buttonForSubmit" id="buttonForSubmit">
              <b-button
                type="submit"
                :class="{ 'disabled-button': saveDisabled }"
                class="admin btn btn-primary"
              >
                Submit
              </b-button>
            </span>
            <span ref="buttonForSubmit2" id="buttonForSubmit2">
              <b-button
                @click.prevent="submitPerDiem"
                class="admin btn btn-primary"
              >
                Submit this Expense
              </b-button>
            </span>
          </div>
        </b-card>
      </b-col>

      <!-- <div id="submitExpense">
        <b-col cols="15">
          <b-card ref="bCard">
            
          </b-card>
        </b-col>
      </div> -->
    </b-row>
  </b-form>
</template>

<script>
import Vue from "vue";
//import pdf from 'vue-pdf'
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";

// import Vuetify from 'vuetify'
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

extend("required", {
  ...required,
  message: "{_field_} must have a value"
});

// Vue.use(VCalendar, {
//   componentPrefix: "vc"
// });

export default {
  // imported components registered
  components: {
    // vImg: Vuetify.VImg,
    // CurrencyCalculator
  },

  props: {
    profile: Object
  },

  // data initialization
  data() {
    return {
      formData: {
        imageUrl: "",
        discipline: "",
        firstName: "",
        lastName: "",
        email: "",
        localToCalgary: false,
        applicationDate: null,
        interviewDate: null,
        interviewNotes: "",
        status: ""
        // ... other form data properties
      },
      resumeFile: null,
      imgColumnValidation: false,
      disciplineColumnValidation: false,
      FNameColumnValidation: false,
      LNameColumnValidation: false,
      EmailColumnValidation: false,
      ApplicationDateColumnValidation: false,
      InterviewDateColumnValidation: false,
      InterviewNotesColumnValidation: false,
      StatusColumnValidation: false,
      fileUploaded: 0,
      loading: false,
      imageUrl: "",
      cropper: null,
      fileUploading: false,
      mode: "single",
      file: null,
      myServer: {
        process: (fieldName, file, metadata, load) => {
          let data = this.fileSelected(file);
          console.log(load(data));
        }
      }
    };
  },

  mounted() {
    this.getUserInfo();
    // this.getFilteredProjects();
  },

  // computed properties
  computed: {
    // Returns boolean to conditionally disable button
    saveDisabled() {
      return "";
    }
  },

  // All the methods defined for this component
  methods: {
    async getUserInfo() {
      try {
        const res = await this.$axios.get(`/getUserInfo`, {});
        if (res && res.data.length > 0) {
          this.userInfo = res.data;
          // console.log(this.userInfo[0].NeedReceipt);
        }
      } catch (err) {
        console.error(err);
      }
    },
    saveData() {
      let imgUrl = this.formData.imageUrl;
      let discipline = this.formData.discipline;
      if (discipline.length == 0) {
        document.getElementById("Discipline").style.borderColor = "red";
        this.disciplineColumnValidation = true;
      }
      let firstName = this.formData.firstName;
      if (firstName.length == 0) {
        document.getElementById("FName").style.borderColor = "red";
        this.FNameColumnValidation = true;
      }
      let lastName = this.formData.lastName;
      if (lastName.length == 0) {
        document.getElementById("LName").style.borderColor = "red";
        this.LNameColumnValidation = true;
      }
      let email = this.formData.email;
      //validate if correct email
      if (email.length == 0 || !email.includes("@") || !email.includes(".")) {
        document.getElementById("Email").style.borderColor = "red";
        this.EmailColumnValidation = true;
      }
      let applicationDate = this.formData.applicationDate;
      if (applicationDate == null) {
        document.getElementById("ApplicationDate").style.borderColor = "red";
        this.ApplicationDateColumnValidation = true;
      }
      let interviewDate = this.formData.interviewDate;
      if (interviewDate == null) {
        document.getElementById("InterviewDate").style.borderColor = "red";
        this.InterviewDateColumnValidation = true;
      }
      let interviewNotes = this.formData.interviewNotes;
      if (interviewNotes.length == 0) {
        document.getElementById("InterviewNotes").style.borderColor = "red";
      }
      let status = this.formData.status;
      if (status.length == 0) {
        document.getElementById("Status").style.borderColor = "red";
        this.StatusColumnValidation = true;
      }

      if (
        this.StatusColumnValidation == false &&
        this.InterviewNotesColumnValidation == false &&
        this.InterviewDateColumnValidation == false &&
        this.ApplicationDateColumnValidation == false &&
        this.EmailColumnValidation == false &&
        this.LNameColumnValidation == false &&
        this.FNameColumnValidation == false &&
        this.disciplineColumnValidation == false &&
        this.imgColumnValidation == false
      ) {
        this.$emit("onExpenseSave", this.formData);
      }
      //clear all fields
      this.formData.imageUrl = "";
      this.formData.discipline = "";
      this.formData.firstName = "";
      this.formData.lastName = "";
      this.formData.email = "";
      this.formData.localToCalgary = false;
      this.formData.applicationDate = null;
      this.formData.interviewDate = null;
      this.formData.interviewNotes = "";
      this.formData.status = "";
      this.fileUploaded = 0;
      document.getElementById("InterviewNotes").style.borderColor = "black";
      this.$refs.pond.removeFiles();
    },

    /**
     * Fires when a file is selected.
     * Selects the appropriate file and adds it to the expense form.
     * @param e the event data when a file is selected.
     */
    async fileSelected(file) {
      if (!file) {
        return;
      }
      try {
        var userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("mobile") !== -1) {
          this.setLoading(true);
        }
        this.fileUploading = true;
        let formData = new FormData();
        // console.log(props.expenseTypes);
        formData.append("type", "resume");
        const pdfReceipt = file;
        formData.append("file", pdfReceipt);
        const receiptReturn = await this.$axios.post(
          `profile/UploadResume`,
          formData
        );
        this.setLoading(false);
        let elements = document.querySelectorAll(".phone");
        elements.forEach((element) => {
          element.style.display = "block";
        });
        this.loading = false;
        this.$refs.buttonForSubmit.style.display = "block";
        this.fileUploaded = 1;
        this.resumeFile = file;
        //console.log(receiptReturn.data.fileUrl)
        const date = new Date();
        this.formData.imageUrl = receiptReturn.data.fileUrl;
        console.log(this.formData.imageUrl);
      } catch (err) {
        console.log(err);
      } finally {
        this.fileUploading = false;
      }
    },
    updateDiscipline(value) {
      this.formData.discipline = value;
      if (value.length > 6) {
        document.getElementById("Discipline").style.borderColor = "grey";
        this.disciplineColumnValidation = false;
      }
    },

    updateFName(value) {
      this.formData.firstName = value;
      if (value.length > 0) {
        document.getElementById("FName").style.borderColor = "grey";
        this.FNameColumnValidation = false;
      }
    },

    updateLName(value) {
      this.formData.lastName = value;
      if (value.length > 0) {
        document.getElementById("LName").style.borderColor = "grey";
        this.LNameColumnValidation = false;
      }
    },

    updateEmail(value) {
      this.formData.email = value;
      if (value.length > 0) {
        document.getElementById("Email").style.borderColor = "grey";
        this.EmailColumnValidation = false;
      }
    },

    updateApplicationDate(value) {
      this.formData.applicationDate = value;
      if (value.length > 0) {
        document.getElementById("ApplicationDate").style.borderColor = "grey";
        this.ApplicationDateColumnValidation = false;
      }
    },

    updateInterviewDate(value) {
      this.formData.interviewDate = value;
      if (value.length > 0) {
        document.getElementById("InterviewDate").style.borderColor = "grey";
        this.InterviewDateColumnValidation = false;
      }
    },

    updateInterviewNotes(value) {
      this.formData.interviewNotes = value;
      if (value.length > 0) {
        document.getElementById("InterviewNotes").style.borderColor = "grey";
        this.InterviewNotesColumnValidation = false;
      }
    },

    updateStatus(value) {
      this.formData.status = value;
      if (value.length > 0) {
        document.getElementById("Status").style.borderColor = "grey";
        this.StatusColumnValidation = false;
      }
    },
    updateLocal(value) {
      this.formData.localToCalgary = value;
    }
  },

  watch: {}
};
</script>

<style>
.filepond--panel[data-scalable="true"] {
  box-shadow: 0px 0px 0px 1px #495057 !important;
}
.filepond--wrapper *:hover {
  cursor: pointer;
}

.video-container {
  position: relative;
}

.video-overlay {
  position: absolute;
  bottom: -10%;
  left: 64%;
  transform: translateX(-50%);
}

#click {
  display: none;
}

.video {
  display: none;
}

#retake {
  display: none;
}

.video-container {
  display: none;
}

.canvas {
  display: none;
}

#summary {
  display: none;
}

#filepond {
  height: 80px;
  width: 200px;
  float: left;
}

#start {
  height: 70px;
  width: 100px;
}

#divStart {
  height: 70px;
  width: 100px;
  margin-left: 20px;
  border-color: #d3d3d3;
  border: none;
  background-color: white;
}

.canvas {
  border-radius: 20px;
  text-align: center;
  margin-left: 250px;
}

.video {
  border-radius: 20px;
  width: 400px;
  height: 300px;
  margin-left: 250px;
}

#manually {
  display: none;
}

/* #projectType{
    border-color: red;
  } */

#validationProject {
  display: none;
  color: red;
}
#validationCurrency {
  display: none;
  color: red;
}

#validationExpenseType {
  display: none;
  color: red;
}
.loading-spinner {
  /* styles for the loading spinner */
  display: none;
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 70%;
  left: 65%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#buttonForSubmit {
  display: none;
}

#buttonForSubmit2 {
  display: none;
}
#formColumn {
  margin-left: -40px;
}

.img {
  width: 380px;
  height: 580px;
  border-radius: 20px;
  margin-top: 40px;
}

#cancelCamera {
  display: none;
}

#helpImage {
  height: 30px;
  width: 30px;
  margin-left: 10%;
  margin-top: 2%;
}

#submitExpense {
  margin-left: 20%;
}

@media only screen and (max-width: 800px) {
  .phone {
    margin-left: 10vw;
    display: none;
    float: left;
  }

  #phoneUpload {
    margin-left: 22%;
  }

  .phone2 {
    margin-left: 10vw;
    float: left;
  }

  .img {
    display: none;
  }
  #formColumn {
    margin-left: none;
  }

  #cancelCamera {
    display: none;
  }

  .bars {
    width: 170%;
  }

  .expenseDate {
    margin-top: 20%;
  }

  .calendar {
    width: 170%;
  }

  #manually {
    display: block;
    margin-top: 80px;
    margin-left: 31vw;
  }

  .label {
    display: none;
  }

  .short {
    height: 50px;
    width: 5px;
  }

  #divStart {
    display: none;
  }

  #submitExpense {
    /* display: none;
    height: 400px; */
    width: 1000px;
    height: 0;
  }

  #filepond {
    height: 80px;
    width: 80vw;
    margin-left: 7.5vw;
    float: left;
  }

  #helpImage {
    height: 1vh;
    width: 10vw;
    margin-left: 0;
    margin-top: 0;
    display: none;
  }

  #summary {
    display: block;
  }

  .loading-spinner {
    /* styles for the loading spinner */
    display: block;
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 220%;
    left: 85%;
    transform: translate(-50%, -50%);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

/* #formId{
    display: none;
  } */
</style>

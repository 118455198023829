<template>
  <div id="page-content-wrapper">
    <div class="container-fluid">
      <h1>Insight AWP Administration</h1>
      <h2>Edit Project</h2>
    </div>
  </div>
</template>

<script>
export default {}
</script>
<template>
  <div>
    <div class="d-flex align-items-center">
      <h1 class="mt-3 ml-3">Project Planned Hours</h1>
      <div
        v-if="tableFormed"
        class="ml-auto mr-5 mt-2 button-container align-items-end"
      >
        <b-button @click="exportReport">Export</b-button>
      </div>
      <!-- <b-button
      v-if="canEdit"
      @click="saveData"
      variant="primary"
      class="ml-auto mr-3 mt-3"
      :disabled="loading"
    >
      Save
    </b-button> -->
    </div>
    <hr class="my-3" />
    <div class="project-selection mt-3 ml-3 d-flex align-items-end">
      <b-form-group label="Select Project" class="mb-0">
        <b-form-select
          v-model="selectedProject"
          :options="projectOptions"
          class="interactive-dropdown"
          size="lg"
        >
        </b-form-select>
      </b-form-group>
      <b-form-group label="Start Date" class="mb-0 ml-3">
        <DatePicker
          v-model="startDate"
          type="month"
          format="MMM YYYY"
          valueType="format"
          :default-value="new Date()"
        />
      </b-form-group>
      <b-form-group label="End Date" class="mb-0 ml-3">
        <DatePicker
          v-model="endDate"
          type="month"
          format="MMM YYYY"
          valueType="format"
          :default-value="new Date()"
        />
      </b-form-group>
      <div v-if="tableFormed" class="button-container">
        <b-button @click="toggleEdit">{{
          isEditing ? "Save" : "Edit"
        }}</b-button>
      </div>
    </div>

    <div class="tableDiv" v-if="tableFormed">
      <table>
        <thead>
          <tr>
            <th class="employee-name sticky-col">Employee Name</th>
            <td
              v-for="date in dateRange"
              :key="date"
              class="rotated-text date-cell"
            >
              {{ date }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="employee in filteredEmployees" :key="employee.id">
            <td class="employee-name sticky-col">{{ employee.Name }}</td>
            <td v-for="date in dateRange" :key="date" class="date-cell">
              <div v-if="isEditing">
                <!-- <input type="number" v-model="employee.hours[date]" class="editable-cell" /> -->
                <input
                  type="number"
                  v-model.number="employee.hours[date]"
                  :class="{
                    'invalid-input': !Number.isInteger(employee.hours[date])
                  }"
                  class="editable-cell no-spinner"
                  style="width: 100%; text-align: center"
                  min="0"
                  step="1"
                  @input="validateHours(employee, date)"
                  @focus="handleFocus(employee, date)"
                />
              </div>
              <div v-else class="non-editable-cell">
                {{ employee.hours[date] }}
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="total-hours-row">
            <th class="employee-name sticky-col">Total Hours</th>
            <td
              v-for="date in dateRange"
              :key="date"
              class="date-cell total-hours"
            >
              {{ totalHours[date] }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axios from "axios";
import moment from "moment";
import Swal from 'sweetalert2';

import * as XLSX from "xlsx";
// import { get } from "jquery";
// import { set } from "lodash";
// import { all } from "core-js/fn/promise";

export default {
  name: "AdminProjectPlannedHours",
  components: {
    DatePicker
  },
  data() {
    return {
      employees: [],
      projects: [],
      selectedProject: "",
      startDate: "",
      endDate: "",
      dateRange: [],
      plannedHoursData: [], // Array of data from the planned hours table
      filteredEmployees: [],
      isAllowedToBillData: [],
      isEditing: false,
      tableFormed: false
    };
  },
  mounted() {
    this.getEmployees();
    this.getProjects();
    this.getRoster();
  },
  computed: {
    totalHours() {
      const totals = {};
      this.dateRange.forEach(date => {
        const total = this.filteredEmployees.reduce((sum, employee) => {
          return sum + (employee.hours[date] || 0);
        }, 0);
        totals[date] = total === 0 ? '' : total;
      });
      return totals;
    },
    allFieldsFilled() {
      return this.selectedProject && this.startDate && this.endDate;
    },
    projectOptions() {
      return [
        { value: "", text: "Select Project" },
        ...this.projects
          .filter((project) => project.ClientID !== 4)
          .map((project) => ({
            value: project.ProjectID,
            text: project.ProjectName
          }))
      ];
    },
    ...mapGetters({
      currentUser: "getLoggedInUser"
    }),
    authToken() {
      return localStorage.getItem('access_token'); // Retrieve the access token from local storage
    }

  },
  methods: {
    //Gets all employees that are active
    async getEmployees() {
      try {
        const res = await this.$axios.get(`/activeStaffs`);
        if (res && res.data.length > 0) {
          this.employees = res.data.map((employee) => ({
            ...employee,
            hours: {}
          }));
        }
      } catch (err) {
        console.error(err);
      }
    },
    //get sa list of users and what projects they are assigned to
    async getRoster() {
      try {
        const res = await this.$axios.get(`/peRoaster`);
        if (res && res.data.length > 0) {
          const data = res.data;
          let newData = [];
          for (let i = 0; i < data.length; i++) {
            newData.push({
              employeeId: data[i].empId,
              projectId: data[i].projectId,
              isAllowedToBill: data[i].canBillHours
            });
          }
          this.isAllowedToBillData = newData;
          console.log("Fetched roster data:", this.isAllowedToBillData);
        }
      } catch (err) {
        console.error(err);
      }
    },
    //get all active projects
    async getProjects() {
      console.log(this.setLoading);
      this.setLoading(true);
      console.log(this.setLoading);
      try {
        const res = await this.$axios.get(`/projects?sort_by=ProjectName`);
        if (res && res.data.length > 0) {
          this.projects = res.data;
          console.log("Fetched projects:", this.projects);
        }
      } catch (err) {
        console.error(err);
      }
      finally {
        this.setLoading(false);
        console.log(this.setLoading);

      }
    },
    //fetches planned hours for the selected project
    async getPlannedHours() {
      try {
        console.log("Fetching planned hours data...");
        const response = await axios.get("/plannedHours", {
          params: {
            projectId: this.selectedProject
          }
        });
        console.log("Fetched planned hours data:", response.data);
        this.plannedHoursData = response.data; // Save the fetched data
      } catch (error) {
        console.error("Error fetching planned hours:", error);
      }
    },
        //shows employees that are allowed to bill for the project and creates a union between thee employees allowed to bill and the employees fetched from database table for planned hours
    filterEmployees() {
      if (this.selectedProject) {
        // console.log("Selected Project:", this.selectedProject);
        // console.log("Planned Hours Data:", this.plannedHoursData);

        // Employees allowed to bill
        const allowedToBillEmployees = this.employees.filter((employee) => {
          return this.isAllowedToBillData.some(
            (entry) =>
              entry.employeeId === employee.id &&
              entry.projectId === this.selectedProject &&
              entry.isAllowedToBill === true
          );
        });

        console.log("Allowed to bill employees:", allowedToBillEmployees);

        // Employees from the planned hours table
        const plannedHoursEmployeeIds = this.plannedHoursData
          .filter((entry) => {
            console.log("Checking entry:", entry);
            return entry.projectId === this.selectedProject;
          })
          .map((entry) => entry.userId);

        console.log("Planned hours employee IDs:", plannedHoursEmployeeIds);

        // Union of both sets of employee IDs
        const unionSet = new Set([
          ...allowedToBillEmployees.map((employee) => employee.id),
          ...plannedHoursEmployeeIds
        ]);

        console.log("Union of employee IDs:", Array.from(unionSet));

        // Convert the set back to an array of employee objects
        this.filteredEmployees = this.employees.filter((employee) =>
          unionSet.has(employee.id)
        );

        console.log("Filtered employees:", this.filteredEmployees);
      }
    },

    async populateTable() {
      console.log("Populating table with planned hours data:", this.plannedHoursData);
      this.setLoading(true);
      try {
        this.filteredEmployees.forEach((employee) => {
          console.log("Processing employee:", employee);
          this.dateRange.forEach((date) => {
            const formattedDate = moment.utc(date).format("YYYY-MM-DD");
            console.log("Processing date:", formattedDate);
            const plannedHour = this.plannedHoursData.find(
              (ph) => ph.userId === employee.id && ph.date === formattedDate
            );
            if (plannedHour && plannedHour.plannedHours !== null) {
              console.log(`Setting planned hours for ${employee.Name} on ${formattedDate}:`, plannedHour.plannedHours);
              this.$set(employee.hours, date, plannedHour.plannedHours);
            } else {
              console.log(`No planned hours for ${employee.Name} on ${formattedDate}`);
              this.$set(employee.hours, date, ""); // Set to empty string if plannedHours is null
            }
          });
        });
      } catch (error) {
        console.error("Error populating table:", error);
      } finally {
        // Introduce a small delay before setting loading to false
        setTimeout(() => {
          this.tableFormed = this.filteredEmployees.length > 0 && this.dateRange.length > 0;
          this.setLoading(false);
          console.log("Loading state after populateTable:", this.loading);
        }, 500); // 500ms delay
      }
    },
    generateDateRange() {
      if (!this.startDate || !this.endDate) return;

      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
      const dateRange = [];
      let currentDate = start;

      while (currentDate <= end) {
        dateRange.push(
          currentDate.toLocaleDateString("default", {
            month: "short",
            year: "numeric"
          })
        );
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      this.dateRange = dateRange;
      console.log("Generated date range:", this.dateRange);
    },
    toggleEdit() {
      if (this.isEditing) {
        this.saveData();
      }
      this.isEditing = !this.isEditing;
    },
    // handleInput(employee, date) {
    //   let value = employee.hours[date].toString();
    //   if (value.length > 1 &&  value[1] !== ".") {
    //     employee.hours[date] = parseInt(value.slice(1));
    //   }
    // },
    handleFocus(employee, date) {
      if (employee.hours[date] === 0) {
        employee.hours[date] = "";
      }
    },
     validateHours(employee, date) {
      let value = employee.hours[date];
      if (value === null || value === "") {
        employee.hours[date] = "";
        return;
      }
      value = value.toString();
      if (value.length > 1 && value.startsWith("0")) {
        value = value.replace(/^0+/, '');
      }
      value = parseInt(value, 10);
      if (!Number.isInteger(value) || value < 0) {
        employee.hours[date] = Math.max(0, Math.floor(value));
      } else {
        employee.hours[date] = value;
      }
    },
    async saveData() {
        this.tableFormed = false;
      this.setLoading(true);
      try {
        const plannedHoursData = [];

        this.filteredEmployees.forEach((employee) => {
          this.dateRange.forEach((date) => {
            let plannedHours = employee.hours[date];
            if (plannedHours === "" || plannedHours === undefined) {
              plannedHours = null; // Send null instead of empty or undefined
            }
            plannedHoursData.push({
              projectId: this.selectedProject,
              userId: employee.id,
              date: moment.utc(date).format("YYYY-MM-DD"), // Format date as YYYY-MM-DD using UTC
              plannedHours: plannedHours // Include all values, including null
            });
          });
        });

        // Log the data being sent
        // console.log("Data being sent:", plannedHoursData);

        // Batch the data into smaller chunks
        const batchSize = 60; // Adjust the batch size as needed
        for (let i = 0; i < plannedHoursData.length; i += batchSize) {
          const batch = plannedHoursData.slice(i, i + batchSize);
          console.log("Sending batch:", batch);

          const response = await axios.post(
            "/plannedHours/save",
            batch,
            {
              headers: {
                Authorization: `Bearer ${this.authToken}` // Include the authentication token
              }
            }
          );
          console.log("Response:", response.data);
        }
        this.tableFormed = true;
        this.setLoading(false);

        // Show success message
        await Swal.fire({
          title: 'Success',
          text: 'Data saved successfully!',
          icon: 'success',
          confirmButtonText: 'OK'
        });

      } catch (error) {
        console.error("Error saving planned hours:", error);
        if (error.response) {
          console.error("Error response data:", error.response.data);
        } else {
          console.error("Error message:", error.message);
        }
        // Handle error (e.g., show an error message)
        await Swal.fire({
          title: 'Error',
          text: 'Error saving data. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      } 
    },
    exportReport() {
      const data = this.filteredEmployees.map((employee) => {
        const row = { Name: employee.Name };
        console.log(row);
        this.dateRange.forEach((date) => {
          const formattedDate = new Date(date).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
          });
          row[formattedDate] = employee.hours[date] || 0;
        });
        return row;
      });

      const worksheet = XLSX.utils.json_to_sheet(data);

      // Bold the headers
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      const headerRow = range.s.r; // Usually the first row (0)
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_cell({ r: headerRow, c: C }); // Encode cell address (e.g., A1, B1, etc.)
        if (!worksheet[address]) continue;
        if (!worksheet[address].s) worksheet[address].s = {};
        worksheet[address].s.font = { bold: true };
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Planned Hours");

      XLSX.writeFile(workbook, "PlannedHours.xlsx");
    }
  },
  watch: {
    async selectedProject() {
      this.tableFormed = false;
      await this.getPlannedHours();
      this.filterEmployees();
      if (this.allFieldsFilled) {
        this.populateTable();
      }
 
    },
    startDate() {
      this.tableFormed = false;
      this.generateDateRange();
      if (this.allFieldsFilled) {
        this.populateTable();
      }

    },
    endDate() {
      this.tableFormed = false;
      this.generateDateRange();
      if (this.allFieldsFilled) {
        this.populateTable();
      }
    }
  }
};
</script>
<style>
/* Hide the spinner (arrow buttons) in number input fields */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
/* Customize mx-datepicker-main */
.mx-datepicker-content {
  color: black !important; /* Change color to black */
  background-color: #e9ecef;
  border-radius: 0.25rem;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.mx-calendar-content .cell.active {
  background-color: #007bff;
}
.mx-calendar-content .cell:hover {
  background-color: #007bff;
  color: white;
}
.mx-calendar-header-label,
.mx-btn {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: black;
}

.mx-icon-left:before,
.mx-icon-right:before,
.mx-icon-double-left:before,
.mx-icon-double-right:before,
.mx-icon-double-left:after,
.mx-icon-double-right:after {
  width: 14px;
  height: 14px;
}
</style>

<style scoped>
.button-container {
  display: flex;
  justify-content: flex-end;
  margin-left: 50px;
  color: #007bff;
}
.btn-secondary {
  background-color: #007bff;
  border-radius: 8px;
  padding: 8px 20px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.btn-secondary:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.interactive-dropdown {
  width: 350px; /* Fixed width for all dropdowns */
  border-radius: 8px;
  border: none;
  padding: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

::v-deep .mx-input {
  width: 200px; /* Fixed width for all dropdowns */
  border-radius: 8px;
  border: none;
  padding: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  height: 40px; /* Set the same fixed height */
}

::v-deep .mx-icon-calendar,
::v-deep .mx-icon-clear {
  right: 20px;
}

/* Dropdown hover and focus effects */
::v-deep .mx-input:hover,
::v-deep .mx-input:focus,
.interactive-dropdown:hover,
.interactive-dropdown:focus {
  background-color: #e9ecef;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Customize mx-datepicker-main */
::v-deep .mx-datepicker-content {
  color: black !important; /* Change color to black */
  background-color: white;
  border: 1px solid #e8e8e8;
}

/* Focus effects for mx-datepicker-main */
::v-deep .mx-datepicker-main:focus {
  background-color: #e9ecef;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Dropdown item style */
.interactive-dropdown .dropdown-item {
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

/* Dropdown item hover effect */
.interactive-dropdown .dropdown-item:hover {
  background-color: #dee2e6;
}

/* Table styles */
.tableDiv {
  overflow: auto;
  margin: 20px;
  min-width: 20%;
  max-width: 100%;
  max-height: 81vh;
  scrollbar-width: thin;
  scrollbar-color: #dddddd #f9f9f9;
}

table {
  min-width: 20%;
  max-width: 100%;
  border-collapse: collapse;
  border: 0;
  table-layout: fixed;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  /* 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.employee-name,
.project-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
tfoot,
thead {
  z-index: 100;
  background-color: #f2f2f2;
  cursor: default;
}
.employee-name {
  cursor: default;
}
.total-hours {
  font-weight: bold;
  text-align: center;
}
.total-hours-row {
  border-top: 2px solid; /* Adjust the thickness and color as needed */
}
th {
  background-color: #f2f2f2;
  position: sticky;
  left: 0;
  top: 0 !important;
  z-index: 100;
  border-left: 0;
}

td:first-child {
  position: sticky;
  left: 0;
  background-color: #f9f9f9;
  z-index: 99;
  border-left: 0;
}

/* Rotate text in table header */
.rotated-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  padding-right: 15px;
  padding-left: 15px;
}

.editable-cell input {
  cursor: text; /* Change cursor to text when editing */
}

.non-editable-cell {
  cursor: default; /* Change cursor to default when not editing */
  text-align: center;
}
.edit-column {
  text-align: center;
}

/* Maybe don't use pixels */
td.rotated-text.date-cell {
  width: 10px;
}
</style>

// state
const state = {
  loading: false,
  force_update: false
};

// getters
const getters = {
  loading: (state) => state.loading,
  force_update: (state) => state.force_update
};

// mutations
const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_FORCE_UPDATE(state, payload) {
    state.force_update = payload;
  },
  TOGGLE_FORCE_UPDATE(state) {
    state.force_update = !state.force_update;
  }
};

// actions
const actions = {
  // Setting loading state for globally initiating alert
  setLoading({ commit }, payload) {
    commit("SET_LOADING", payload);
  },
  setForceUpdate({ commit }, payload) {
    if (payload) {
      return commit("SET_FORCE_UPDATE", payload);
    }
    commit("TOGGLE_FORCE_UPDATE");
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

<template>
  <div>
    <b-container>
      <div class="d-flex justify-content-between">
        <h2 class="p-0 page_title">
          {{ userSelected ? `Editing: ${user.name}` : "Staff Review" }}
        </h2>
        <button
          v-if="userSelected"
          class="btn btn-danger"
          @click="resetStaff()"
        >
          Reset
        </button>
      </div>
      <hr class="my-2" />
      <div v-if="!userSelected" class="alert alert-warning">
        <p class="m-0">Please select at least one user</p>
      </div>
      <br />

      <Form
        v-if="userSelected"
        :user="user"
        @update="saveData"
        :is_admin="true"
      ></Form>

      <br />
      <b-row>
        <b-col cols="12">
          <Table v-if="users && users.length">
            <template #thead>
              <tr>
                <th scope="col" width="20%">Name</th>
                <th scope="col" width="40%">Email</th>
                <th scope="col" width="10%">Is Admin</th>
                <th scope="col" width="10%">Is Active</th>
                <th scope="col">Edit</th>
              </tr>
            </template>
            <template #tbody>
              <tr
                v-bind:key="user.id"
                :class="isSelectedUser(user) ? 'table-active' : ''"
                v-for="user in users"
              >
                <td width="20%">
                  {{
                    user.first_name
                      ? `${user.first_name} ${user.last_name}`
                      : user.name
                  }}
                </td>
                <td width="40%">{{ user.userName }}</td>
                <td width="10%">{{ user.isAdmin ? "Yes" : "No" }}</td>
                <td width="10%">{{ user.isActive ? "Yes" : "No" }}</td>
                <td>
                  <b-icon
                    :class="isSelectedUser(user) ? 'invisible' : ''"
                    role="button"
                    icon="pencil"
                    v-on:click="() => rowClicked(user)"
                  ></b-icon>
                </td>
              </tr>
            </template>
          </Table>
        </b-col>
      </b-row>
    </b-container>

    <PopupOverlay
      v-if="staffToDelete"
      :message="deleteMessage"
      v-on:close="closePopup"
    >
    </PopupOverlay>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import StaffData from "../core/StaffData";
import { extractError } from "../utilities/ErrorUtilities";
import FormatUtilities from "../utilities/FormatUtilities";
import PopupOverlay from "../components/PopupOverlay";
import Form from "@/components/User/Form.vue";

export default {
  components: {
    Table,
    PopupOverlay,
    Form
  },

  data() {
    return {
      users: [],
      user: new StaffData(),
      boolean: { Yes: true, No: false },
      staffToDelete: null,
      currencies: JSON.parse(process.env.VUE_APP_CURRENCIES)
    };
  },

  async created() {
    try {
      this.setLoading(true);
      await this.loadStaffs();
    } catch (error) {
      console.error(error);
    } finally {
      this.setLoading(false);
    }
  },

  computed: {
    userSelected() {
      return (this.user && this.user.id) || false;
    },

    deleteMessage() {
      if (this.staffToDelete !== null) {
        return `Are you Sure you want to delete the Staff: ${this.staffToDelete.make}`;
      }
      return "";
    }
  },

  methods: {
    formatDate: FormatUtilities.formatDate,
    addMonthsTo: FormatUtilities.addMonthsTo,

    closePopup(confirm) {
      if (!confirm) {
        this.staffToDelete = null;
      } else {
        this.deleteStaff(this.staffToDelete);
      }
    },

    showDeleteConfirmBox(user) {
      this.staffToDelete = user;
    },

    isSelectedUser(user) {
      return user.id === this.user.id;
    },

    rowClicked(user) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      this.user = this.$_.clone(user);
    },

    async deleteStaff(user) {
      this.staffToDelete = false;
      this.setLoading(true);
      try {
        var res = await this.$axios.delete(`/staffs/${user.id}`);
        if (res) {
          this.makeToast(
            "success",
            `Staff: ${user.name} has been deleted successfully`
          );
          this.removeStaff(updateRecords, true);
        }
      } catch (err) {
        console.error(err);
        this.makeToast("danger", extractError(err));
      } finally {
        this.setLoading(false);
        this.staffToDelete = null;
      }
    },

    loadStaffs() {
      return new Promise((resolve, reject) => {
        try {
          this.$axios.get(`/users`).then((res) => {
            if (!res || !res.data) {
              return reject();
            }
            this.users = res.data.map((p) => StaffData.fromApi(p));
            resolve();
          });
        } catch (err) {
          console.error(err);
          reject();
        }
      });
    },

    async saveData(data) {
      if (this.userSelected) {
        return this.update(data);
      }
      alert("Please select at least 1 user to continue.");
    },

    async update(data) {
      try {
        this.setLoading(true);

        await this.$axios.put(`/users`, data.toApi());
        this.makeToast("success", `${data.name}'s profile has been updated.`);
        this.updateRecords(data);
        this.resetStaff();
      } catch (err) {
        console.error(err);
        this.makeToast("danger", extractError(err));
      } finally {
        this.setLoading(false);
      }
    },

    resetStaff() {
      this.user = new StaffData();
    },
    updateRecords(user, is_removed = false) {
      let index = this.users.findIndex((c) => c.id === user.id);

      if (is_removed && index >= 0) {
        this.users.splice(index, 1);
      } else {
        this.$set(this.users, index, user);
      }
      this.resetStaff();
    }
  }
};
</script>

<style scoped>
label {
  font-size: 1.1rem !important;
}
h2.page_title {
  font-size: 1.5rem;
  color: #72a02e;
}
</style>

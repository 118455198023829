<template>
  <div class="wrapper" v-show="showWrapper">
    <div class="content-main" id="abcd">
      <div class="container mt-5">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <h1 class="text-center mb-4">Contact Us</h1>

            <form @submit.prevent="sendEmail">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Your Name"
                  v-model="name"
                  :disabled="nameDisabled"
                />
              </div>

              <div class="form-group">
                <label for="email">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Your Email"
                  v-model="email"
                  :disabled="emailDisabled"
                />
              </div>

              <div class="form-group">
                <label for="message">Message</label>
                <textarea
                  class="form-control"
                  id="message"
                  rows="5"
                  placeholder="Your Message"
                  v-model="message"
                  @keyup="checkMessage"
                ></textarea>
              </div>

              <button
                id="submitBtn"
                type="submit"
                class="btn btn-primary"
                :disabled="submitDisabled"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
      nameDisabled: true,
      emailDisabled: true,
      submitDisabled: true,
      showWrapper: false
    };
  },
  mounted() {
    this.initializePage();
  },
  methods: {
    initializePage() {
      const currentUser = JSON.parse(localStorage.getItem("user"));

      if (currentUser) {
        this.showWrapper = true;
        this.name = currentUser.name;
        this.email = currentUser.username;
        this.nameDisabled = true;
        this.emailDisabled = true;
      } else {
        window.location.href = "/";
      }
    },
    checkMessage() {
      this.submitDisabled = this.message.length === 0;
    },
    sendEmail() {
      const data = {
        name: this.name,
        email: this.email,
        message: this.message
      };

      this.$axios.post("/sendEmail", data).then(
        (response) => {
          console.log(response);
          this.makeToast("success", "Success", "Email sent successfully");
          this.message = "";
          this.submitDisabled = true;
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        },
        (error) => {
          console.log(error);
          this.makeToast("danger", "Error", "Error sending email");
        }
      );
    }
  }
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>

<template>
  <div v-if="!targetCurrency">
    <!-- <div class="form-group row">
      <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4">
        <p>Convert to:</p>
      </div>
      <div class="form-group col-12 col-sm-8 col-md-8 col-lg-8">
        <div :key="type" v-for="type in currencies" class="form-check-inline">
          <input
            type="radio"
            v-model="receiptCurrency"
            @change="loadRapidRates()"
            class="form-check-input"
            :value="type"
          />{{ type }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// External libraries and components imported
import axios from "axios";

export default {
  name: "CurrencyCalculator",
  // data initialization
  data() {
    return {
      oldValue: null
    };
  },
  props: [
    "targetCurrency",
    "expenseAmount",
    "receiptCurrency",
    "currencies",
    "expenseDate",
    "changeIndicator"
  ],

  /*
   * Following props are being watched and loadRapidRates() gets called when the value changes.
   */
  watch: {
    targetCurrency: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.loadRapidRates();
      }
    },

    expenseAmount: function (newValue, oldValue) {
      console.log("amount", newValue, oldValue);
      if (newValue != oldValue) {
        this.loadRapidRates();
      }
    },

    receiptCurrency: function (newValue, oldValue) {
      console.log("receiptCurrency", newValue, oldValue);
      if (newValue != oldValue) {
        this.loadRapidRates();
      }
    },

    changeIndicator: function (newValue, oldValue) {
      console.log("changeIndicator", newValue, oldValue);
      this.loadRapidRates();
    }
  },

  // All the methods defined for this component
  methods: {
    /*
     * This function makes api call to get the currency converion rates for the date supplied.
     */
    loadRapidRates() {
      console.log("loadRapidRates called");
      

      if (!this.receiptCurrency || !this.targetCurrency) {
        return;
      }
      const formattedReceiptCurrency = this.receiptCurrency.trim().toUpperCase();
      const formattedTargetCurrency = this.targetCurrency.trim().toUpperCase();


      //https://fixer-fixer-currency-v1.p.rapidapi.com/2013-12-24?base=USD&symbols=USD%252CCAD%252CEUR
      // base : currencyInvoice
      // symbols : ReceiptCurrency
      //let url = this.getUrl(this.receiptCurrency, this.targetCurrency);
      let url = this.getUrl(formattedReceiptCurrency, formattedTargetCurrency);

      console.log(url);
      this.$axios
        .post("/currencyCalculate", {
          url: url
        })
        .then((res) => {
          console.log(res);
          this.$emit("currency-converted", {
            amountRapid:
              res.data.rates[this.targetCurrency] * this.expenseAmount,
            currency: this.receiptCurrency,
            currencyRate: res.data.rates[this.targetCurrency]
          });
          this.ExpenseExRate = res.data.rates[this.targetCurrency];
        });
      // axios
      //   .get(url, {
      //     headers: {
      //       "x-rapidapi-host": "fixer-fixer-currency-v1.p.rapidapi.com",
      //       "x-rapidapi-key":
      //         "64d678ae8dmsh9c27a0037003177p1bce1ejsna183cf6fcfc9"
      //     }
      //   })
      //   .then((res) => {
      //     console.log("rapid", res.data);
      //     /*
      //      * "currency-converted" is emitted when the successful response is received
      //      * and the reponse data is passed to the parent component
      //      */
      // this.$emit("currency-converted", {
      //   amountRapid:
      //     res.data.rates[this.targetCurrency] * this.expenseAmount,
      //   currency: this.receiptCurrency,
      //   currencyRate: res.data.rates[this.targetCurrency]
      // });
      // this.ExpenseExRate = res.data.rates[this.targetCurrency];
      // })
      //   .catch((err) => console.log("errors:" + err));
    },
    // This function returns formatted date to be used by api
    getDate() {
      let date = "latest";
        
      if (this.expenseDate && new Date(this.expenseDate) < new Date()) {
      date = this.$moment(this.expenseDate).utcOffset(0).format("YYYY-MM-DD");
    }
      return date;
    },
    // This function prepares and returns api endpoint to loadRapidRates()
    getUrl(base, target) {
      return `https://data.fixer.io/api/${this.getDate()}?access_key=f209fd467f236c95eb3b1ea6012cc68c&base=${base}&symbols=${target}`;
    }
  }
};
</script>

<template>
  <div id="admin-window">
    <div class="container-sm" style="margin-top: -30px">
      <h1>How to add Expense</h1>
      <hr />
      <div class="p-2">
        Timesheets can easily be added by navigating to "Add Timesheet" and
        filling out the simple form.<br /><br />The form looks like the
        following:
        <div class="custom_card card-1">
          <img
            class="img-fluid border"
            src="@/assets/images/docs/admin/image2.png"
            alt="Add Timesheet Image"
          />
        </div>
        <br />
        <br />
        <ol>
          <li>
            <strong>Type of Expense:</strong><br />This field is mandatory and
            you must select the type of expense you wish to submit.<br />
            Different types of expenses have different ways of adding them.
          </li>
          <br />
          <li>
            <strong>Upload Receipt:</strong><br />This field is mandatory where
            it appears and you must upload the receipt for the expense you wish
            to submit.<br />
            <br />You can upload the receipt in the following ways:
            <ul>
              <li>
                <b>For Computers:</b>
                <ul>
                  <li>
                    Click on the <strong>Upload Receipt</strong> area and select
                    the file from your computer.
                  </li>
                  <li>
                    Drag and drop the file on the
                    <strong>Upload Receipt</strong>
                    button.
                  </li>
                </ul>
              </li>
              <li>
                <b>For Mobile Devices</b>:<br />
                You can still use the above methods to upload the receipt or you
                can also use the camera to take a picture of the receipt.
                <br />
                <br />
                For Information on how to use the camera to take a picture of
                the receipt, <a href="#mobileReceiptUpload">click here</a>.
              </li>
            </ul>
          </li>
          <br />
          <li>
            <strong>Date of Expense:</strong><br />This field is mandatory and
            you must select the date on which the expense was made.<br />
            <br />
            <b>Note:</b> For some types of expenses, you are able to select more
            than one date. These Expenses are <b>Accomodation</b> and
            <b>Per Diem</b>.
            <br />
            <br />
            <b>Tip:</b> Whenever there is an option to add multiple dates, you
            can see the color of the date picker change to green and if only one
            date is allowed, the color of the date picker will be blue.
          </li>
          <br />
          <li>
            <b>Expense To: </b><br />This field is mandatory and you must select
            the project to whom the expense will be billed.<br />
            <br />
            <b>Note:</b> The currency of your expense will be converted to the
            currency of the project you select.
          </li>
          <br />
          <li>
            <b>Receipt Currency:</b>
            <br />This field is mandatory and you must select the currency of
            which the receipt is in.
          </li>
          <br />
          <div class="custom_card card-1">
            <img
              class="img-fluid border"
              src="@/assets/images/docs/admin/image2_1.png"
              alt="Add Timesheet Image"
            />
          </div>
          <br />
          <br />
          <li>
            <b>Amount of Expense:</b>
            <br />This field is mandatory and you must enter the amount of the
            expense.
          </li>
          <br />
          <li>
            <b>Expense Claimed:</b>
            <br />This field will show the amount of expense you are claiming in
            the currency of the project you selected.
          </li>
          <br />
          <li>
            <b>Comments:</b> <br />This field is mandatory and you can enter the
            comments about the expense.
          </li>
          <br />
          <li>
            <b>Save:</b> <br />Click save once all the fields are filled
            correctly. It may throw validation error, if any field fails to
            satisfy the required input.
            <br />
            <br />
            <b>Note:</b> For most of the Expense types you have to upload a
            receipt before you can see the save button.
          </li>
          <br />
          <li>
            <b>Download Expenses Report</b>
            <br />You can download the expenses report by clicking on the
            <b>Download</b> button at the top right corner of the screen.<br />
            This will show you a popup window.
            <br />
            <div class="custom_card card-1">
              <img
                class="img-fluid border"
                src="@/assets/images/docs/admin/image2_2.png"
                alt="Add Timesheet Image"
              />
            </div>
            <br />
            <br />
            Just select the date range and the Project for which you want to
            download the expense report and click on the <b>Download</b> button.
          </li>
          <br />
          <li>
            <b>Additional Info:</b>
            <br />
            When submitting <b>Accomodation</b> and <b>Flight</b> expenses, you
            will see two Date fields. <br />
            <br />
            For Accomodation:
            <ul>
              <li>
                <b>Check In Date:</b> This field is mandatory and you must
                select the date on which you checked in to the hotel.
              </li>

              <li>
                <b>Date of Expense:</b> This field is mandatory and you must
                select the date on which you are submitting the expense.
              </li>

              <div class="custom_card card-1">
                <img
                  class="img-fluid border"
                  src="@/assets/images/docs/admin/image2_3.png"
                  alt="Add Timesheet Image"
                />
              </div>
            </ul>
            <br />
            For Flight:
            <ul>
              <li>
                <b>Date of Departure:</b> This field is mandatory and you must
                select the date on which you departed for the flight.
              </li>

              <li>
                <b>Date of Expense:</b> This field is mandatory and you must
                select the date on which you are submitting the expense.
              </li>
              <div class="custom_card card-1">
                <img
                  class="img-fluid border"
                  src="@/assets/images/docs/admin/image2_4.png"
                  alt="Add Timesheet Image"
                />
              </div>
            </ul>
            <br />
            <br />
            <br />
            When submitting <b>Accomodation</b> expense, you will see that you
            submit <b>Amount of Expense</b> for every <b>Date of Expense</b> you
            have selected <br /><br />

            <div class="custom_card card-1">
              <img
                class="img-fluid border"
                src="@/assets/images/docs/admin/image2_5.png"
                alt="Add Timesheet Image"
              />
            </div>
          </li>
        </ol>
        <br />
        <br />
        <h1>How to add Expense on Mobile Devices</h1>
        <hr />
        <div class="iframe-wrapper" id="mobileReceiptUpload">
          <iframe
            id="help-iframe"
            src="https://myerpfuncstorage.blob.core.windows.net/help-document/HelpReceiptScanning.pdf#toolbar=0&navpanes=0&scrollbar=0&zoom=125"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener("resize", this.resizeIframe);
    this.resizeIframe();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeIframe);
  },
  methods: {
    resizeIframe() {
      const iframeWrapper = document.querySelector(".iframe-wrapper");
      const windowHeight = window.innerHeight;
      const wrapperTopOffset = iframeWrapper.offsetTop;
      const newHeight = windowHeight - 80;
      iframeWrapper.style.height = `${newHeight}px`;
    }
  }
};
</script>

<style scoped>
/* body {
  overflow-y: hidden;
} */
.iframe-wrapper {
  position: relative;
  width: 95%;
}

.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>

<template>
  <div>
    <nav class="navbar navbar-expand-lg">
      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        v-if="currentUser && currentUser !== null"
      >
        <b-navbar-brand href="#">
          <img src="@/assets/images/newHome/NavLogo.png" alt="Nav Logo" />
        </b-navbar-brand>

        <b-navbar-nav>
          <!-- <b-nav-item class="navLink" v-scroll-to="'#trendingTopics'"
            >Trending Topics</b-nav-item
          > -->
          <b-nav-item-dropdown
            class="nav-link-custom"
            variant="none"
            text="Projects"
          >
            <b-dropdown-item
              href="https://insightawpcom.sharepoint.com/sites/Chemours-Opteon"
              target="_blank"
              >Chemours Opteon</b-dropdown-item
            >
            <b-dropdown-item
              href="https://insightawpcom.sharepoint.com/sites/LindeNederlands"
              target="_blank"
              >Linde Nederlands</b-dropdown-item
            >
            <b-dropdown-item
              href="https://insightawpcom.sharepoint.com/sites/Dematic"
              target="_blank"
              >Dematic</b-dropdown-item
            >
            <b-dropdown-item
              href="https://insightawpcom.sharepoint.com/sites/LABARGE_PROJECT_CLOUD"
              target="_blank"
              >LaBarge</b-dropdown-item
            >
            <b-dropdown-item
              href="https://insightawpcom.sharepoint.com/sites/SavannahRiverNuclearSolutions"
              target="_blank"
              >Savannah River</b-dropdown-item
            >
            <b-dropdown-item
              href="https://insightawpcom.sharepoint.com/sites/BCEP_PROJECT_CLOUD"
              target="_blank"
              >BCEP</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item
            class="navLink"
            href="https://insightawpcom.sharepoint.com/sites/Management"
            target="_blank"
            >Management</b-nav-item
          >
          <b-nav-item
            class="navLink"
            href="https://app.powerbi.com/Redirect?action=OpenApp&appId=59ac7481-4f3d-40f2-90bb-deaee9e11339&ctid=e8d9e721-ecf8-437f-8d55-af0467cec01a"
            target="_blank"
            >Time on Tools</b-nav-item
          >

          <b-nav-item-dropdown
            class="nav-link-custom"
            variant="none"
            text="Blogs"
          >
            <b-dropdown-item
              href="https://insightawpcom.sharepoint.com/sites/Insight-ERP"
              target="_blank"
              >Blog Home</b-dropdown-item
            >
            <b-dropdown-item
              href="https://insightawpcom.sharepoint.com/sites/Insight-ERP/_layouts/15/CreatePageFromTemplate.aspx?source=%2Fsites%2FInsight-ERP&promotedState=1"
              target="_blank"
              >Create a Blog</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item
            class="navLink"
            target="_blank"
            href=" 
              https://web.yammer.com/main/org/insight-awp.com/feed"
            >Social</b-nav-item
          >
          <b-nav-item class="navLink" href="/home">MY-ERP</b-nav-item>
          <b-nav-item class="navLink" href="/companyValues"
            >Company Values</b-nav-item
          >
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown no-caret right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b-avatar :text="userInitial"></b-avatar>
            </template>
            <b-dropdown-item href="/user-help">Need Help?</b-dropdown-item>
            <b-dropdown-item @click="$emit('logOut')">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </nav>
  </div>
</template>

<script>
import Vue from "vue";
var VueScrollTo = require("vue-scrollto");

Vue.use(VueScrollTo);

Vue.use(VueScrollTo, {
  container: "body",
  duration: 1000,
  easing: "ease",
  lazy: true,
  offset: 0,
  force: false,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

export default {
  props: ["currentUser"],
  // computed properties
  computed: {
    // Returns the Initials of the user
    userInitial() {
      let name = this.currentUser.name || null;
      return (name && name.substr(0, 1).toUpperCase()) || null;
    }
  }
};
</script>

<style scoped>
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  background-color: #ffffff00 !important;
}
.nav > .brand {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  color: #39b982;
  text-decoration: none;
}

.nav .nav-item {
  box-sizing: border-box;
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
}
.nav .nav-item.router-link-exact-active {
  color: #39b982;
  border-bottom: solid 2px #39b982;
}

.nav-link:hover {
  text-decoration: none !important;
}

.navLink a {
  color: #72a02e !important;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 19px;
  line-height: normal;
  letter-spacing: 0.015rem;

  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.navLink a:hover {
  text-decoration: none !important;
}

::v-deep .dropdown .nav-link {
  color: #72a02e !important;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 19px;
  line-height: normal;
  letter-spacing: 0.015rem;

  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

::v-deep .dropdown .nav-link:hover {
  color: #ff9933 !important;
  text-decoration: none !important;
}

::v-deep .dropdown-menu {
  background-color: #72a02e !important;
  border: 0px;
}

::v-deep .dropdown-menu .dropdown-item {
  color: #ffffff !important;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 17px;
  line-height: normal;
  letter-spacing: 0.015rem;
}

::v-deep .dropdown-menu .dropdown-item:hover {
  background-color: #ff9933 !important;
}

@media (max-width: 1520px) {
  .nav > .brand {
    font-size: 1.3em;
  }

  .nav .nav-item {
    margin: 0 3.57px;
  }

  .navLink a,
  .navLink .nav-link a {
    font-size: 17.5714px;
    padding-left: 1.4286rem !important;
    padding-right: 1.4286rem !important;
  }

  ::v-deep .dropdown .nav-link {
    font-size: 17.5714px !important;
    padding-left: 1.4286rem !important;
    padding-right: 1.4286rem !important;
  }
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// Load all the store modules dynamically within modules directory.
const requireContext = require.context('./modules', false, /.*\.js$/)

const modules = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
  )
  .reduce((modules, [name, module]) => {
    return { ...modules, [name]: module.default }
  }, {})

export default new Vuex.Store({
  modules
})

<template>
  <div>
    <div id="admin-window">
      <b-container fluid="sm">
        <div>
          <b-card header="">
            <div class="time-sheet-header">
              <span class="time-sheet-text">{{ expenseMessage }}</span>
              <b-button
                style="margin-top: -17px"
                @click="downloadExpensesByUserPopup"
                type="button"
                variant="primary"
                >Download</b-button
              >
            </div>
            <hr />
          </b-card>
        </div>
        <Expense2
          :expense="selectedExpense"
          :projects="projects"
          :expenseTypes="expenseTypes"
          v-on:onExpenseSave="saveData"
        >
        </Expense2>

        <div id="expTable">
          <Table
            class="custom-scrollbar"
            style="height: 70vh; font-size: 12px; overflow-x: hidden"
            filterable
            @apply-filter="applyFilter"
            :is_processing="loading"
            :showExportButton="true"
          >
            <template #thead>
              <tr>
                <th
                  scope="col"
                  class="text-center"
                  @click="sortBy('expenseDate')"
                >
                  Date
                  <i
                    v-if="sortKey === 'expenseDate'"
                    class="fas fa-arrow-up"
                    :class="{ 'fa-rotate-180': sortDirection === -1 }"
                  ></i>
                </th>
                <th scope="col" class="text-right">
                  <select
                    class="form-control bars disableClass"
                    ref="projectTypeFilter"
                    style="
                      width: 130px;
                      margin-left: 22%;
                      font-size: 12px;
                      font-weight: bold;
                    "
                    @change="applyFilterForProject"
                  >
                    <option :value="0">Project</option>
                    <option
                      :key="projects.projectId"
                      v-for="projects in uniqueProjectIds"
                      :value="JSON.stringify(projects)"
                    >
                      {{ getProject(projects.projectId) }}
                    </option>
                  </select>
                </th>
                <th
                  scope="col"
                  class="text-center"
                  @click="sortBy('categoryId')"
                >
                  Expense Type
                  <i
                    v-if="sortKey === 'categoryId'"
                    class="fas fa-arrow-up"
                    :class="{ 'fa-rotate-180': sortDirection === -1 }"
                  ></i>
                </th>
                <th scope="col" class="text-center">Currency</th>
                <th scope="col" class="text-center" width="5%">Claimed</th>
                <th scope="col" class="text-center">Attachment?</th>
                <th scope="col" class="text-center">Comments</th>
                <th scope="col" class="text-center">Delete</th>
              </tr>
            </template>
            <template #tbody>
              <tr v-bind:key="expense.id" v-for="expense in expenses">
                <td style="border: 1px solid black" class="text-center">
                  {{ formatDate(expense.expenseDate) }}
                </td>
                <td style="border: 1px solid black" class="text-center">
                  {{ getProject(expense.projectId) }}
                </td>
                <td style="border: 1px solid black" class="text-center">
                  {{ getCategory(expense.categoryId) }}
                </td>
                <td style="border: 1px solid black" class="text-center">
                  {{ expense.currency }}
                </td>
                <td
                  class="text-center"
                  style="border: 2px solid black; width: 10%"
                >
                  {{
                    `(${expense.projectCurrency}) ${formatPrice(
                      expense.amount * (expense.currencyRate || 1)
                    )}`
                  }}
                </td>
                <td class="text-center" style="border: 1px solid black">
                  <!-- <a
                    :href="expense.uploadedLink"
                    class="text-dark h4"
                    v-if="expense.uploadedLink"
                  > -->
                  <span class="text-dark" v-if="expense.uploadedLink">
                    <a
                      style="color: black"
                      :href="`https://myerpfuncstorage.blob.core.windows.net/attachment-files-prod/${expense.uploadedLink}`"
                      target="_blank"
                    >
                      <i
                        :id="`popover-1-${expense.id}-link`"
                        class="fa fa-file-pdf-o"
                      ></i
                    ></a>
                    <b-popover
                      :target="`popover-1-${expense.id}-link`"
                      title="File Name"
                      triggers="hover focus"
                      :content="`${extractFilename(expense.uploadedLink)}`"
                    ></b-popover>
                  </span>
                  <!-- </a> -->
                  <span v-else>-</span>
                </td>
                <td class="text-center" style="border: 1px solid black">
                  <div v-if="expense.comment">
                    <i
                      :id="`popover-1-${expense.id}`"
                      class="fa fa-comment-alt"
                    ></i>

                    <b-popover
                      :target="`popover-1-${expense.id}`"
                      title="Comment"
                      triggers="hover focus"
                      :content="`${expense.comment}`"
                    ></b-popover>
                  </div>
                  <span v-else> - </span>
                </td>
                <td style="border: 1px solid black" class="text-center">
                  <b-icon
                    icon="pencil-fill"
                    v-on:click="() => editExpenses(expense)"
                  ></b-icon>
                  &nbsp;
                  <b-icon
                    role="button"
                    icon="trash-fill"
                    aria-hidden="true"
                    v-on:click="() => showDeleteConfirmBox(expense)"
                  ></b-icon>
                </td>
              </tr>
            </template>
            <template #tfoot>
              <tr>
                <th scope="col">Total</th>
                <td colspan="3"></td>
                <th scope="col" class="text-center">
                  {{ formatPrice(expenseTotal) }}
                </th>
                <td colspan="2"></td>
                <th scope="col" class="text-center"></th>
              </tr>
            </template>
          </Table>
        </div>
      </b-container>
    </div>
    <PopupOverlay
      v-if="expenseToDelete"
      :message="deleteMessage"
      v-on:close="closePopup"
    >
    </PopupOverlay>
    <b-modal
      hide-footer
      centered
      title="Edit Expense"
      id="edit-expense"
      body-class="p-0"
    >
      <div class="d-block text-center p-3">
        <!-- <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left">Project:</span>
              <select class="form-control" v-model="selectedRecord.projectId">
                <option
                  :key="project.ProjectID"
                  v-for="project in projects"
                  :value="project.ProjectID"
                >
                  {{ project.ProjectName }}
                </option>
              </select>
            </div>
          </div> -->
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Expense Type</span>
            <select class="form-control" v-model="selectedRecord.categoryId">
              <option disabled selected style="display: none"></option>
              <option
                :key="index"
                v-for="(project, index) in expenseTypes"
                :value="project.Id"
              >
                {{ project.Name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 text-left">
            <label for="billableCheck">Not Billable</label>

            <input
              type="checkbox"
              value="1"
              id="billableCheck"
              ref="billableCheck"
              :checked="selectedRecord.isBillable"
              style="width: 16px; height: 16px; margin-left: 10px"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12" ref="expenseDateLabel">
            <span class="float-left">Date of Expense</span>
          </div>

          <div class="form-group col-12 d-flex justify-content-center">
            <div style="width: 75%">
              <vc-date-picker
                class="calendar"
                is-expanded
                :attributes="attributes"
                mode="date"
                timezone="UTC"
                :min-date="min_date"
                :max-date="max_date"
                v-model="selectedRecord.expenseDate"
              />
            </div>
            <CurrencyCalculator
              ref="currencyCalculator"
              :targetCurrency="selectedRecord.projectCurrency"
              :expenseAmount="selectedRecord.amount"
              :receiptCurrency="selectedRecord.currency"
              :expenseDate="selectedRecord.expenseDate"
              :expense-date="selectedRecord.expenseDate"
              @currency-converted="updateCurrencyRate"
            />
          </div>
        </div>

        <!-- <div class="form-row">
            <div v-if="selectedRecord.categoryId === 3" class="form-group col-12">
              <span class="float-left"
                >Departure Date</span
              >
              <b-form-input
                v-model="selectedRecord.expenseDate"
                type="date"
              ></b-form-input>
            </div>
            <div v-else-if="selectedRecord.categoryId === 1" class="form-group col-12">
              <span class="float-left"
                >Check-In Date</span
              >
              <b-form-input
                v-model="selectedRecord.expenseDate"
                type="date"
              ></b-form-input>
            </div>
            <div v-else class="form-group col-12">
              <span class="float-left"
                >Expense Date</span
              >
              <b-form-input
                v-model="selectedRecord.expenseDate"
                type="date"
              ></b-form-input>
            </div>
          </div> -->
        <!-- <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left"
                >Amount ({{ (selectedRecord.currency || "").trim() }})</span
              >
              <b-form-input
                v-model="selectedRecord.amount"
                type="number"
                step="0.01"
                placeholder="Add Hours"
              ></b-form-input>
            </div>
          </div> -->
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left"
              >Amount ({{ (selectedRecord.currency || "").trim() }})</span
            >
            <b-form-input
              v-model="selectedRecord.amount"
              type="number"
              step="0.01"
              placeholder="Add Hours"
            ></b-form-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left"
              >Currency rate: {{ selectedRecord.currencyRate }}</span
            >
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left"
              >Expense Claimed:
              {{
                formatPrice(selectedRecord.amount * selectedRecord.currencyRate)
              }}
              ({{ selectedRecord.projectCurrency }})</span
            >
          </div>
        </div>
        <span class="float-left">Comments (Required):</span>
        <b-textarea
          rows="4"
          v-model="selectedRecord.comment"
          placeholder="Edit your comments here..."
        ></b-textarea>
      </div>
      <hr class="m-0" />
      <div class="p-3">
        <b-button
          variant="outline-danger"
          @click="$bvModal.hide('edit-expense')"
          >Close</b-button
        >
        <b-button class="ml-3" variant="outline-primary" @click="updateExpense"
          >Save Changes</b-button
        >
      </div>
    </b-modal>

    <b-modal
      hide-footer
      centered
      title="Download Expenses"
      id="expense-download"
      body-class="p-0"
    >
      <div class="d-block text-center p-3">
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Date:</span>
            <select class="form-control" v-model="dateChange">
              <option :value="null" selected>Please choose...</option>
              <option
                :key="index"
                v-for="(period, index) in periods"
                :value="period.key"
              >
                {{ period.value }}
              </option>
            </select>
            <span v-if="validationByUser2" class="float-left" style="color: red"
              >Date is Required</span
            >
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Project:</span>
            <select class="form-control" v-model="projectChange">
              <option :value="null" selected>Please choose...</option>
              <option
                :key="index"
                v-for="(project, index) in projects2"
                :value="project.ProjectID"
              >
                {{ project.projectName }}
              </option>
            </select>
            <span v-if="validationByUser" class="float-left" style="color: red"
              >Project is Required</span
            >
          </div>
        </div>
      </div>
      <hr class="m-0" />
      <div class="p-3">
        <b-button
          variant="outline-danger"
          @click="$bvModal.hide('expense-download')"
          >Close</b-button
        >
        <b-button
          class="ml-3"
          variant="outline-primary"
          @click="downloadExpensesByUser"
          :disabled="loading"
          >Download</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
//import { mapGetters } from 'vuex'

import PopupOverlay from "@/components/PopupOverlay.vue";
import Expense2 from "@/components/Expense2.vue";
import ExpenseData from "../core/ExpenseData";
import FormatUtilities from "../utilities/FormatUtilities";
import { extractError } from "../utilities/ErrorUtilities";
import Table from "@/components/Table.vue";
import CurrencyCalculator from "../components/CurrencyCalculator.vue";

export default {
  // imported components registered
  components: {
    Expense2,
    PopupOverlay,
    Table,
    CurrencyCalculator
  },

  props: {
    loading: {
      default: false
    }
  },

  //
  // DATA initialization
  //

  // data initialization
  data() {
    return {
      currencyRates: [],
      selectedExpense: new ExpenseData(),
      expenseTypes: [],
      periods: [],
      projects: [],
      projects2: [],
      filterProjects: [],
      selectedRecord: {
        // Other properties
      },
      projectChange: null,
      dateChange: null,
      expenseToDelete: null,
      expenses: [],
      showSpinner: true,
      loadFailed: false,
      submitted: false,
      range: null,
      sortKey: "",
      sortDirection: 1,
      validationByUser: false,
      validationByUser2: false, // 1 for ascending, -1 for descending,
      attributes: [],
      dateColumnValidation: false
    };
  },

  //
  // CREATED
  //

  created() {
    this.loadExpenseTypes();
  },

  // computed properties
  computed: {
    dates() {
      return this.selectedDates.map((day) => day);
    },
    min_date() {
      return this.$moment()
        .utc()
        .subtract(6, "months")
        .startOf("month")
        .format("YYYY/MM/DD");
    },
    max_date() {
      return this.$moment()
        .utc()
        .add(2, "months")
        .endOf("month")
        .format("YYYY/MM/DD");
    },

    uniqueProjectIds() {
      const projects = new Set();
      this.filterProjects.forEach((project) => {
        // Assuming each project has a unique identifier, e.g., projectId
        projects.add(project);
      });

      // Converting the set back to an array
      const uniqueProjectsArray = Array.from(projects);
      return uniqueProjectsArray;
    },
    deleteMessage() {
      if (!this.expenseToDelete) {
        return "";
      }
      console.log(this.expenseToDelete);
      let date = this.expenseToDelete.expenseDate.substring(
        0,
        this.expenseToDelete.expenseDate.indexOf("T")
      );
      return `Are you sure you want to delete expense from  ${this.expenseToDelete.projectName} on ${date}.`;
    },

    expenseMessage() {
      if (this.selectedExpense === null || this.selectedExpense.id === -1) {
        return "New Expense";
      }
      if (this.selectedExpense !== null) {
        return `Editing Expense ${this.selectedExpense.id}`;
      }

      return "New Expense";
    },

    expenseTotal() {
      let expenses = this.expenses
        .map((e) => {
          let amount = e.amount * (e.currencyRate || 1);
          return Math.trunc(amount * 100) / 100;
        })
        .reduce((a, b) => (a += b), 0);
      return expenses;
    }
  },

  mounted() {
    this.resetPeriods();
    this.getFilteredProjects();
  },

  //
  // METHODS
  //

  // All the methods defined for this component
  methods: {
    updateCurrencyRate({ currencyRate }) {
      this.selectedRecord.currencyRate = currencyRate;
    },

    async handleExpenseDateChange(newDate, oldDate) {
      await this.$nextTick();
      if (this.$refs.currencyCalculator) {
        await this.$refs.currencyCalculator.loadRapidRates();
      } else {
        console.error("CurrencyCalculator component is not loaded.");
      }
    },

    formatDate: FormatUtilities.formatDate,
    formatPrice: FormatUtilities.formatPrice,

    /**
     * Called when a popup is closed
     * @param confirm true, when 'yes' was clicked, false otherwise.
     */

    closePopup(confirm) {
      if (!confirm) {
        this.expenseToDelete = null;
      } else {
        this.deleteExpense(this.expenseToDelete);
      }
    },

    downloadExpensesByUserPopup() {
      this.projectChange = null;
      this.dateChange = null;
      this.$bvModal.show("expense-download");
    },

    async getProjectsByUser() {
      try {
        const res = await this.$axios.get(
          `/projectsByUserExpenses?sort_by=ProjectName`,
          {
            params: {
              period: this.dateChange
            }
          }
        );
        if (res && res.data.length > 0) {
          this.projects2 = res.data;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async getFilteredProjects() {
      try {
        const res = await this.$axios.get(`/getFilteredProjectsExpenses`, {
          params: { ...this.range }
        });
        if (res && res.data.length > 0) {
          this.filterProjects = res.data;
          console.log(this.filterProjects);
        }
      } catch (err) {
        console.error(err);
      }
    },

    async downloadExpensesByUser() {
      if (this.dateChange === "" || this.dateChange === null) {
        this.validationByUser2 = true;
      } else if (this.projectChange === "" || this.projectChange === null) {
        this.validationByUser = true;
      } else {
        try {
          this.setLoading(true);
          const periodData = await this.$axios.get("/expenses/download", {
            responseType: "blob",
            params: {
              period: this.dateChange,
              project: this.projectChange
            }
          });

          let user = localStorage.getItem("user");
          user = JSON.parse(user);
          const [year, month] = this.dateChange.split("-");
          const dateObj = new Date(year, month);
          const options = { month: "long", year: "numeric" };
          //  console.log(dateObj.toLocaleDateString('en-US', options))
          // this.setLoading(false)
          const data = [this.projects];
          const projectID = this.projectChange;
          let projectName = null;

          for (const obj of data) {
            for (const [key, value] of Object.entries(obj)) {
              if (value.ProjectID === projectID) {
                projectName = value.ProjectName;
                break;
              }
            }
            if (projectName !== null) {
              break;
            }
          }
          this.$bvModal.hide("expense-download");
          this.downloadFile(
            periodData.data || null,
            `${user.name}-${projectName}-${dateObj.toLocaleDateString(
              "en-US",
              options
            )}.zip`
          );
        } catch (err) {
          console.error(err);
          if (err.status === 404) {
            this.$bvModal.hide("expense-download");
            this.makeToast(
              "danger",
              "Files do not exist for current selection, please try modifying the filters."
            );
          }
        } finally {
          this.setLoading(false);
        }
      }
    },

    downloadFile(blob, filename = "file") {
      if (!blob) {
        return;
      }
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.download = filename;
      link.click();
    },

    resetPeriods() {
      this.periods = [];

      try {
        let max_months = 6; // Display previous 2 months

        let current_month = this.$moment().startOf("month");
        this.periods.push({
          key: `${current_month.year()}-${current_month.month()}`,
          value: current_month.format("MMMM Y")
        });

        for (let month = 1; month <= max_months; month++) {
          let _month_year = this.$moment().subtract(month, "M");

          this.periods.push({
            key: `${_month_year.year()}-${_month_year.month()}`,
            value: _month_year.format("MMMM Y")
          });
        }
      } catch (err) {
        this.makeToast("danger", extractError(err));
      }
    },

    editExpenses(record) {
      const expenseDate = record.expenseDate.substring(
        0,
        record.expenseDate.indexOf("T")
      ); // Extract the characters before the "T" character
      this.selectedRecord = Object.assign({}, record, { expenseDate }); // Assign the new expenseDate property to the selectedRecord object
      // console.log(this.selectedRecord.isBillable)
      this.$bvModal.show("edit-expense");
      // if (this.selectedRecord.isBillable){
      //   console.log(document.getElementById("billableCheck"))
      // }else{
      //   console.log(document.getElementById("billableCheck"))
      // }
    },

    updateExpense() {
      let data = this.$_.pick(this.selectedRecord, [
        "amount",
        "comment",
        "categoryId",
        "expenseDate",
        "currencyRate"
      ]);
      data["billable"] = 0;
      let billable = 0;
      if (this.$refs.billableCheck.checked) {
        data["billable"] = 1;
      }
      this.setLoading(true);

      this.$bvModal.hide("edit-expense");
      this.$axios
        .put(`/expenses/${this.selectedRecord.id}`, data)
        .then(() => this.getExpenses())
        .catch((err) => console.error(err))
        .finally(() => {
          this.setLoading(false);
          this.makeToast("success", "Expense has been updated!");
        });
    },

    // async updateExpense() {
    //   let data = this.$_.pick(this.selectedRecord, ["amount", "comment", "expenseDate", "categoryId"]);
    //   data["billable"] = 0
    //   let billable = 0
    //   if(this.$refs.billableCheck.checked){
    //     data["billable"] = 1
    //   }
    //   this.setLoading(true);
    //   try {
    //     await this.$axios.put(
    //       `/expenses/${this.selectedRecord.id}`,
    //       data
    //     );
    //     const index = this.expenses.findIndex(
    //       (t) => t.id === this.selectedRecord.id
    //     );
    //     if (index >= 0) {
    //       this.$set(this.expenses, index, {
    //         ...this.expenses[index],
    //         ...this.selectedRecord
    //       });
    //     }
    //     this.makeToast("success", "Expense has been updated!");
    //     this.$refs.observer.reset();
    //     this.$bvModal.hide("edit-expense");
    //   } catch (err) {
    //     this.displayError(
    //       err?.data?.message ||
    //         "Failed to update expense record, please try later."
    //     );
    //   } finally {
    //     this.setLoading(false);
    //   }
    // },

    sortBy(key) {
      if (key === this.sortKey) {
        this.sortDirection = -this.sortDirection; // toggle direction
      } else {
        this.sortKey = key;
        this.sortDirection = 1; // set default direction
      }

      // sort the data by the selected key and direction
      this.expenses.sort((a, b) => {
        console.log(a + " " + b);
        let x = a[key];
        let y = b[key];

        if (typeof x === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        return this.sortDirection * ((x > y) - (y > x));
      });
    },

    extractFilename(url) {
      const lastPart = url.split("-").pop();
      return lastPart;
    },

    /**
     * Attempts to grab the category by it's id.
     *
     * If the category is not in the expenseTypes array,
     * returns 'N/A'
     * @param categoryId - the unique id of the expense category
     * we are looking for.
     */
    getCategory(categoryId) {
      const categoryIndex = this.expenseTypes.findIndex(
        (t) => t.Id === categoryId
      );
      if (categoryIndex >= 0) {
        return this.expenseTypes[categoryIndex].Name;
      }
      return "N/A";
    },

    getProject(projectId) {
      const projectIndex = this.projects.findIndex(
        (t) => t.ProjectID === projectId
      );
      if (projectIndex >= 0) {
        return this.projects[projectIndex].ProjectName;
      } else {
        return "N/A";
      }
    },

    showDeleteConfirmBox(expense) {
      this.expenseToDelete = expense;
    },

    displayError(err) {
      this.makeToast("danger", extractError(err));
      this.loadFailed = true;
    },

    async deleteExpense(expense) {
      try {
        this.$axios.delete(`/expenses/${expense.id}`).then(() => {
          this.makeToast(
            "success",
            "The expense has been deleted successfully."
          );
          this.updateExpensesList(expense.id);
        });
        this.expenseToDelete = null;
      } catch (err) {
        console.error(err);
      }
    },
    updateExpensesList(id) {
      let index = this.expenses.findIndex((e) => e.id == id);
      if (index >= 0) {
        this.expenses.splice(index, 1);
      }
    },

    /**
     * Called when a row on the
     * expense table is clicked.
     */
    rowClicked(expense) {
      this.selectedExpense = expense;
    },

    // Load the data from Apis
    getExpenses() {
      let projectID = this.$refs.projectTypeFilter.value;
      let projectInfo = this.$refs.projectTypeFilter.value;
      // projectInfo = JSON.parse(projectInfo);
      projectID = JSON.parse(projectID);

      if (projectID === 0) {
        projectID = 0;
      } else {
        projectID = projectID.projectId;
      }
      return new Promise((resolve, reject) => {
        try {
          this.setLoading(true);
          this.$axios
            .get(`/expenses`, {
              params: {
                ...this.range,
                projectID: projectID,
                projectInfo: projectInfo
              }
            })
            .then((res) => {
              if (res && res.data) {
                this.expenses = res.data.map((j) => ExpenseData.fromApi(j));
                resolve();
              }
              this.setLoading(false);
            });
        } catch (err) {
          console.error(err);
          reject();
        }
      });
    },

    // async exportTableData() {
    //   console.log("Exporting data...");
    //   try {
    //     this.setLoading(true);
    //     const response = await this.$axios.get("/expenses/export", {
    //       responseType: "blob",
    //       params: {
    //         // Add any necessary parameters here
    //       }
    //     });
    //     this.downloadFile(response.data, "expenses-data.csv");
    //   } catch (err) {
    //     console.error(err);
    //     this.makeToast("danger", "Failed to export data. Please try again.");
    //   } finally {
    //     this.setLoading(false);
    //   }
    // },

    /**
     * Returns true if the given expense,
     * has the same id as the selected expense.
     * False otherwise.
     */
    isExpenseSelected(expense) {
      //return this.selectedExpense.id === expense.id;
      return this.selectedExpense.id === expense.ExpenseID;
    },

    async loadExpenseTypes() {
      try {
        const expenseTypes = await this.$axios.get(`/expenses/types`);
        if (expenseTypes) {
          this.expenseTypes = expenseTypes.data;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async getProjects() {
      try {
        const res = await this.$axios.get(`/projects?sort_by=ProjectName`);
        if (res && res.data.length > 0) {
          this.projects = res.data;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async saveData(expense, Isbillable, convertedResult) {
      console.log(expense.categoryId === 2);
      console.log(convertedResult);
      try {
        this.setLoading(true);
        let expensesData = null;
        if (expense.categoryId === 2) {
          expensesData = await this.$axios
            .post(`/expensesWithoutReceipt`, {
              expenses: expense.toApi(),
              bill: Isbillable
            })
            .then(() => {
              location.reload();
            });
        } else if (
          expense.expenseDateActivity === undefined ||
          expense.expenseDateActivity === null
        ) {
          expensesData = await this.$axios
            .post(`/expenses`, {
              expenses: expense.toApi(),
              bill: Isbillable,
              convertedResult: convertedResult
            })
            .then(() => {
              location.reload();
            });
        } else {
          expensesData = await this.$axios
            .post(`/expensesByActivity`, {
              expenses: expense.toApi(),
              bill: Isbillable,
              dateActivity: expense.expenseDateActivity,
              convertedResult: convertedResult
            })
            .then(() => {
              location.reload();
            });
        }
        if (expensesData !== null) {
          this.getExpenses();
          this.selectedExpense = new ExpenseData();
        }
        if (expensesData.data.message === "Failed") {
          this.makeToast(
            "danger",
            "There was an error while saving the expense."
          );
        } else {
          this.makeToast("success", "Expense has been saved.");
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.setLoading(false);
      }
    },
    async applyFilter(range) {
      this.range = range;
      await this.getExpenses();
      await this.getFilteredProjects();
      if (!this.projects.length) {
        this.getProjects();
      }
    },
    async applyFilterForProject() {
      // console.log(this.$refs.projectType.value)
      await this.getExpenses();
      if (!this.projects.length) {
        this.getProjects();
      }
    }
  },
  watch: {
    projectChange: {
      handler: function (updatedProject) {
        this.validationByUser = false;
      }
    },
    "selectedRecord.expenseDate": "handleExpenseDateChange"
  }
};
</script>

<style scoped>
@media only screen and (max-width: 800px) {
  #expTable {
    display: none;
  }
}
</style>

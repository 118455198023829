<template>
  <div>
    <div id="admin-window">
      <b-container fluid="sm">
        <div>
          <b-card header="">
            <div class="time-sheet-header">
              <span class="time-sheet-text">{{ hiringMessage }}</span>
            </div>
            <hr />
          </b-card>
        </div>
        <Hiring :profile="profile" v-on:onExpenseSave="saveData"> </Hiring>
      </b-container>
      <div>
        <b-card header="">
          <div class="time-sheet-header">
            <span class="time-sheet-text mt-5">View Candidates</span>
          </div>
          <hr />
        </b-card>
        <b-table id="Dtable" striped hover :items="items" :fields="fields">
          <template v-slot:cell(Resume)="data">
            <a style="color: black" :href="data.item.Resume" target="_blank">
              <i
                :id="`popover-1-${data.item.Name}-link`"
                class="fa fa-file-pdf-o fa-2x"
              ></i
            ></a>
            <b-popover
              :target="`popover-1-${data.item.Name}-link`"
              title="File Name"
              triggers="hover focus"
              :content="`${extractFilename(data.item.Resume)}`"
            ></b-popover>
          </template>
          <template v-slot:cell(actions)="data">
            <i
              type="button"
              class="fa fa-pencil fa-lg"
              @click="onProfileEdit(data.item)"
            ></i>
            <i
              type="button"
              class="fa fa-trash fa-lg pl-2"
              @click="profileToDelete = data.item"
            ></i>
          </template>
        </b-table>
      </div>
    </div>
    <PopupOverlay
      v-if="profileToDelete"
      :message="deleteMessage"
      v-on:close="closePopup"
    >
    </PopupOverlay>
    <b-modal
      hide-footer
      centered
      title="Edit Candidate Profile"
      id="edit-profile"
      body-class="p-0"
    >
      <div class="d-block text-center p-3">
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Discipline</span>
            <b-form-input
              v-model="profileToEdit.Discipline"
              type="text"
              placeholder="Add Discipline"
            ></b-form-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">First Name</span>
            <b-form-input
              v-model="profileToEdit.FirstName"
              type="text"
              placeholder="Add First Name"
            ></b-form-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Last Name</span>
            <b-form-input
              v-model="profileToEdit.LastName"
              type="text"
              placeholder="Add Last Name"
            ></b-form-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Email</span>
            <b-form-input
              v-model="profileToEdit.Email"
              type="email"
              placeholder="Add Email"
            ></b-form-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 text-left">
            <label for="localCheck">Local to Calgary</label>

            <input
              type="checkbox"
              value="1"
              id="localCheck"
              ref="localCheck"
              :checked="profileToEdit.LocalToCalgary"
              style="width: 16px; height: 16px; margin-left: 10px"
            />
          </div>
        </div>
        <!-- Application Date -->
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Application Date</span>
            <b-form-datepicker
              v-model="profileToEdit.ApplicationDate"
              placeholder="Select a date"
              class="mb-2"
            ></b-form-datepicker>
          </div>
        </div>
        <!-- Interview Date -->
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Interview Date</span>
            <b-form-datepicker
              v-model="profileToEdit.InterviewDate"
              placeholder="Select a date"
              class="mb-2"
            ></b-form-datepicker>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Interview Notes:</span>
            <b-textarea
              rows="4"
              v-model="profileToEdit.InterviewNotes"
              placeholder="Edit your comments here..."
            ></b-textarea>
          </div>
        </div>
        <!-- Status -->
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Status:</span>
            <b-form-select
              v-model="profileToEdit.Status"
              :options="[
                'Rejected',
                'Shortlisted',
                'Offer Pending',
                'Onboarding'
              ]"
            ></b-form-select>
          </div>
        </div>
      </div>
      <hr class="m-0" />
      <div class="p-3">
        <b-button
          variant="outline-danger"
          @click="$bvModal.hide('edit-profile')"
          >Close</b-button
        >
        <b-button class="ml-3" variant="outline-primary" @click="updateProfile"
          >Save Changes</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
//import { mapGetters } from 'vuex'

import PopupOverlay from "@/components/PopupOverlay.vue";
import Hiring from "@/components/Hiring.vue";
import HiringProfileData from "../core/hiringProfileData";
import FormatUtilities from "../utilities/FormatUtilities";
import { extractError } from "../utilities/ErrorUtilities";
import Table from "@/components/Table.vue";

export default {
  // imported components registered
  components: {
    Hiring,
    PopupOverlay
  },

  props: {
    loading: {
      default: false
    }
  },

  //
  // DATA initialization
  //

  // data initialization
  data() {
    return {
      profiles: [],
      profile: new HiringProfileData(),
      showSpinner: true,
      loadFailed: false,
      submitted: false,
      sortKey: "",
      sortDirection: 1,
      validationByUser: false,
      validationByUser2: false,
      profileToEdit: {},
      profileToDelete: null,
      fields: [
        // a hidden ID column

        {
          key: "ID",
          hidden: true
        },
        {
          key: "Discipline",
          sortable: true
        },
        {
          key: "Name",
          sortable: true
        },
        {
          key: "Email",
          sortable: true
        },
        // {
        //   key: "Local to Calgary",
        //   sortable: true
        // },
        {
          key: "Application Date",
          sortable: true
        },
        {
          key: "Interview Date",
          sortable: true
        },
        {
          key: "Discipline",
          sortable: true
        },
        // {
        //   key: "Interview Notes",
        //   sortable: true
        // },
        {
          key: "Status",
          sortable: true
        },
        {
          key: "Resume",
          sortable: true
        },
        {
          key: "actions",
          label: "Actions"
        }
      ],
      items: [] // 1 for ascending, -1 for descending
    };
  },

  //
  // CREATED
  //

  created() {
    // this.loadProfiles();
  },

  // computed properties
  computed: {
    hiringMessage() {
      if (this.profile === null || this.profile.id === -1) {
        return "Add Candidiate";
      }
      console.log(this.profile);
      if (this.profile !== null) {
        return `Editing Candidate ${this.profile.id}`;
      }

      return "Add Candidiate";
    },
    deleteMessage() {
      if (!this.profileToDelete) {
        return "";
      }
      console.log(this.profileToDelete);
      return `Are you sure you want to delete candidate profile of ${this.profileToDelete.Name}.`;
    }
  },

  mounted() {
    this.getProfiles();
  },

  //
  // METHODS
  //

  // All the methods defined for this component
  methods: {
    extractFilename(url) {
      if (!url) return null;
      const parts = url.split("/");
      if (parts.length > 1) {
        return parts[parts.length - 1];
      } else {
        return null;
      }
    },

    closePopup(confirm) {
      if (!confirm) {
        console.log("cancel");
        this.profileToDelete = null;
      } else {
        this.deleteProfile(this.profileToDelete);
      }
    },

    deleteProfile(profile) {
      this.$axios.delete(`deleteCandidate/${profile.ID}`).then((response) => {
        console.log(response);
        if (response.status == 200) {
          this.profileToDelete = null;
          this.getProfiles();
          this.makeToast(
            "success",
            "The candidate has been deleted successfully!"
          );
        }
      });
    },

    onProfileEdit(profile) {
      this.profileToEdit = this.profiles.find((p) => p.ID === profile.ID);
      this.$bvModal.show("edit-profile");
    },

    updateProfile() {
      this.$axios
        .put(`updateCandidate/${this.profileToEdit.ID}`, this.profileToEdit)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.profileToEdit = {};
            this.getProfiles();
            this.makeToast(
              "success",
              "The candidate has been updated successfully!"
            );
            this.$bvModal.hide("edit-profile");
          }
        });
    },

    getProfiles() {
      this.$axios.get("candidates").then((response) => {
        // console.log(response);
        if (response.status == 200) {
          //   console.log(response.data);
          this.profiles = response.data;
          let items = response.data.map((item) => {
            console.log(item);
            return {
              ID: item.ID,
              Discipline: item.Discipline,
              Name: item.FirstName + " " + item.LastName,
              Email: item.Email,
              "Local to Calgary": item.LocalToCalgary ? "Yes" : "No",
              "Application Date": this.$moment(item.ApplicationDate)
                .utc()
                .format("ddd MMM DD"),
              "Interview Date": this.$moment(item.InterviewDate)
                .utc()
                .format("ddd MMM DD"),
              "Interview Notes": item.InterviewNotes,
              Status: item.Status,
              Resume: item.UploadLink
            };
          });
          console.log(items);
          this.updateItems(items);
        }
      });
    },

    saveData(formData) {
      console.log(formData);
      this.profile = formData;
      //now do an axios post request to save the data to the database
      this.$axios.post("hiring", formData).then((response) => {
        console.log(response);
        if (response.status == 200) {
          this.profile = null;
          this.submitted = true;
          this.getProfiles();
          this.makeToast(
            "success",
            "The candidate has been added successfully!"
          );
        }
      });
    },
    onExpenseSave() {
      this.loadProfiles();
    },
    updateItems(v) {
      this.items = v;
    }
  },
  watch: {}
};
</script>

<style scoped>
#Dtable {
  font-size: 12px;
}
@media only screen and (max-width: 800px) {
  #expTable {
    display: none;
  }
}
</style>

<template>
  <div id="page-content-wrapper">
    <div class="container-fluid">
      <h1>Edit Expense</h1>
    </div>
  </div>
</template>

<script>
export default {}
</script>
<template>
  <b-form @submit.prevent="$emit('update', user)">
    <div class="d-flex flex-wrap flex-wrapper">
      <div style="width: 31.4%">
        <label>First Name: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.first_name"
            placeholder="First Name"
          ></b-form-input>
        </div>
      </div>
      <div style="width: 31.4%">
        <label>Middle Name: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.middle_name"
            placeholder="Middle Name"
          ></b-form-input>
        </div>
      </div>
      <div style="width: 31.4%">
        <label>Last Name: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.last_name"
            placeholder="Last Name"
          ></b-form-input>
        </div>
      </div>
      <div style="width: 31.4%">
        <label>Email: </label>
        <div class="form-group">
          <b-form-input
            :value="user.userName"
            type="email"
            placeholder="Email"
            disabled
          ></b-form-input>
        </div>
      </div>
      <div style="width: 31.4%">
        <label>Home Phone: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.home_phone"
            inputmode="tel"
            placeholder="Home Phone"
          ></b-form-input>
        </div>
      </div>
      <div style="width: 31.4%">
        <label>Mobile Phone: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.mobile_phone"
            inputmode="tel"
            placeholder="Mobile Phone"
          ></b-form-input>
        </div>
      </div>
      <div style="width: 31.4%">
        <label>Personal Email: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.personal_email"
            inputmode="email"
            placeholder="Personal Email"
          ></b-form-input>
        </div>
      </div>
      <!-- <div>
        <label>SIN/Social Security Number: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.social_number"
            inputmode="numeric"
            placeholder="Social Number"
          ></b-form-input>
        </div>
      </div> -->
      <div style="width: 31.4%">
        <label>Business Name: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.business_name"
            placeholder="Business Name"
          ></b-form-input>
        </div>
      </div>
      <div style="width: 31.4%">
        <label>Address: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.address"
            placeholder="Address"
          ></b-form-input>
        </div>
      </div>
      <div style="width: 31.4%">
        <label>City: </label>
        <div class="form-group">
          <b-form-input v-model="user.city" placeholder="City"></b-form-input>
        </div>
      </div>
      <div style="width: 31.4%">
        <label>State/Province: </label>
        <div class="form-group">
          <b-form-input v-model="user.state" placeholder="State"></b-form-input>
        </div>
      </div>
      <div style="width: 31.4%">
        <label>Postal Code: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.postal_code"
            placeholder="Postal Code"
          ></b-form-input>
        </div>
      </div>
      <div style="width: 31.4%">
        <label>Date of birth: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.dob"
            type="date"
            placeholder="Dob"
          ></b-form-input>
        </div>
      </div>
      <div style="width: 31.4%">
        <label>Driver's License Number: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.driving_license_number"
            placeholder="Driving License Number"
          ></b-form-input>
        </div>
      </div>
      <div style="width: 31.4%">
        <label>Issuing State/Province: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.driving_license_state"
            placeholder="Driving License State"
          ></b-form-input>
        </div>
      </div>
      <!-- <div>
        <label>Bank Name: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.bank_name"
            placeholder="Bank Name"
          ></b-form-input>
        </div>
      </div> -->
      <!-- <div>
        <label>Routing Number: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.routing_number"
            inputmode="numeric"
            placeholder="Routing Number"
          ></b-form-input>
        </div>
      </div> -->
      <!-- <div>
        <label>Account Number: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.account_number"
            inputmode="numeric"
            placeholder="Account Number"
          ></b-form-input>
        </div>
      </div> -->
      <!-- <div>
        <label>Swift Code: </label>
        <div class="form-group">
          <b-form-input
            v-model="user.swift_code"
            placeholder="Swift Code"
          ></b-form-input>
        </div>
      </div> -->
      <div v-if="is_admin" style="width: 31.4%">
        <label>Is Admin: </label>
        <div class="form-group">
          <select v-model="user.isAdmin" class="form-control">
            <option
              :key="key"
              v-for="(key, value) in { Yes: true, No: false }"
              :value="key"
            >
              {{ value }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="is_admin" style="width: 31.4%">
        <label>Daily accommodation requirement:</label>
        <div class="form-group">
          <select v-model="user.needReceipt" class="form-control">
            <option
              :key="key"
              v-for="(key, value) in { No: true, Yes: false }"
              :value="key"
            >
              {{ value }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="is_admin" style="width: 31.4%">
        <label>Is Active: </label>
        <div class="form-group">
          <select v-model="user.isActive" class="form-control">
            <option
              :key="key"
              v-for="(key, value) in { Yes: true, No: false }"
              :value="key"
            >
              {{ value }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <br />
    <b-row>
      <div v-for="field in fields" :key="field.label" class="col-3">
        <b-card
          :header="field.label"
          style="max-width: 20rem"
          class="mb-2"
          border-variant="success"
          header-border-variant="success"
          align="center"
        >
          <!-- create a two radio buttons for yes and no under the same radio group -->

          <b-form-radio-group
            v-model="user[field.value]"
            :options="[
              { text: 'Yes', value: true },
              { text: 'No', value: false }
            ]"
            name="radio-stacked"
            class="text-center"
            :button-variant="
              user[field.value] ? 'outline-success' : 'outline-danger'
            "
            buttons
          ></b-form-radio-group>
        </b-card>
      </div>
    </b-row>

    <b-row>
      <div class="col-sm-12">
        <button :disabled="!user.id" type="submit" class="btn btn-primary">
          Update
        </button>
      </div>
    </b-row>
  </b-form>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    is_admin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: [
        /* 
         emailCreated = false,
    personalFolderCreated = false,
    onboardingFormReceived = false,
    welcomeSession = false,
    bankingInfoCreated = false,
    test100Sent = false,
    businessCardsOrdered = false,
    BookGiven = false,
    cordOrganizerGiven = false,
    journalGiven = false,
    bioPicUploaded = false,
    visaInfoUploaded = false,
    erpTrainingCompleted = false
         */
        {
          label: "Email Created",
          value: "emailCreated"
        },
        {
          label: "Personal Folder Created",
          value: "personalFolderCreated"
        },
        {
          label: "Onboarding Form Received",
          value: "onboardingFormReceived"
        },
        {
          label: "Welcome Session",
          value: "welcomeSession"
        },
        {
          label: "Banking Info Created",
          value: "bankingInfoCreated"
        },
        {
          label: "Test $100 Sent",
          value: "test100Sent"
        },
        {
          label: "Business Cards Ordered",
          value: "businessCardsOrdered"
        },
        {
          label: "Book Given",
          value: "BookGiven"
        },
        {
          label: "Cord Organizer Given",
          value: "cordOrganizerGiven"
        },
        {
          label: "Journal Given",
          value: "journalGiven"
        },
        {
          label: "Bio Uploaded",
          value: "bioPicUploaded"
        },
        {
          label: "Visa Info Uploaded",
          value: "visaInfoUploaded"
        },
        {
          label: "ERP Training Completed",
          value: "erpTrainingCompleted"
        },
        {
          label: "Company Shirts",
          value: "companyShirts"
        },
        {
          label: "ESET Installed",
          value: "esetInstalled"
        },
        {
          label: "Insight-AWP Staff Inclusion",
          value: "includedInInsightAwpStaffGroup"
        }
      ]
    };
  }
};
</script>

<style scoped>
.flex-wrapper {
  gap: 0 2rem;
}
</style>

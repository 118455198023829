export default class HiringProfileData {
  constructor(
    id = -1,
    firstName = "",
    lastName = "",
    email = "",
    discipline = "",
    resume = "",
    local = false,
    applicationDate = null,
    interviewDate = null,
    interviewNotes = "",
    status = "",
    created_by = "",
    created_at = null
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.discipline = discipline;
    this.resume = resume;
    this.local = local;
    this.applicationDate = applicationDate;
    this.interviewDate = interviewDate;
    this.interviewNotes = interviewNotes;
    this.status = status;
    this.created_by = created_by;
    this.created_at = created_at;
  }

  /**
   * Changes the object into an object ready to be consumed by the api
   */
  toApi() {
    return {
      ID: this.id,
      Discipline: this.discipline,
      FirstName: this.firstName,
      LastName: this.lastName,
      Email: this.email,
      LocalToCalgary: this.local || false,
      UploadLink: this.resume || "",
      ApplicationDate: this.applicationDate || null,
      InterviewDate: this.interviewDate || null,
      InterviewNotes: this.interviewNotes || "",
      Status: this.status || "",
      CreatedBy: this.created_by || "",
      createdAt: this.created_at || null
    };
  }

  /**
   * handles transformation from the api,
   * into the data class properties.
   * @param {object} HiringProfileData
   */
  static fromApi(data) {
    return new HiringProfileData(
      data.ID,
      data.FirstName,
      data.LastName,
      data.Email,
      data.Discipline,
      data.UploadLink,
      data.LocalToCalgary,
      data.ApplicationDate,
      data.InterviewDate,
      data.InterviewNotes,
      data.Status,
      data.CreatedBy,
      data.createdAt
    );
  }
}

<template>
  <div>
    <b-container>
      <div class="d-flex justify-content-between">
        <h2 class="p-0 page_title">
          {{ isNew ? "Add New Project" : `Editing: ${project.projectName}` }}
        </h2>
        <button v-if="!isNew" class="btn btn-danger" @click="resetProject()">
          Cancel Editing
        </button>
        <button v-else class="btn btn-primary" @click="openAddClient">
          + Add Client
        </button>
      </div>
      <hr class="my-2" />
      <b-form @submit.prevent="saveData">
        <b-row>
          <b-col cols="6">
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectName">Client Name: </label>
              </div>
              <div class="form-label form-group col-md-8">
                <select
                  style="width: 100%"
                  required
                  v-model="project.clientId"
                  class="form-control add_client"
                  placeholder="Choose Client"
                >
                  <option disabled selected style="display: none"></option>
                  <option
                    :key="client.id"
                    v-for="client in clients"
                    :value="client.id"
                  >
                    {{ client.clientName }}
                  </option>
                </select>
                <!-- <b-button @click="openAddClient">+ Add Client</b-button> -->
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectName">Owner Name: </label>
              </div>
              <div class="form-label form-group col-md-8">
                <select
                  style="width: 100%"
                  required
                  v-model="project.ownerId"
                  class="form-control add_client"
                  placeholder="Choose Owner"
                >
                <option disabled selected style="display: none"></option>
                <option
                  :key="owner.ProjectOwnerID"
                  v-for="owner in owners"
                  :value="owner.ProjectOwnerID"
                >
                  {{ owner.ProjectOwnerName }}
                </option>
                </select>
                <!-- <b-button @click="openAddClient">+ Add Client</b-button> -->
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectName">Project Name: </label>
              </div>
              <div class="form-label form-group col-md-8">
                <b-form-input
                  id="ProjectName"
                  v-model="project.projectName"
                  type="text"
                  required
                  class="form-control"
                  name="ProjectName"
                  placeholder="Name"
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectAddressLine01" class="form-label"
                  >Project Address 1:
                </label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectAddressLine01"
                  v-model="project.addressLine1"
                  type="text"
                  class="form-control"
                  name="ProjectAddressLine01"
                  placeholder="Address 1"
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectAddressLine02">Project Address 2: </label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectAddressLine02"
                  v-model="project.addressLine2"
                  type="text"
                  class="form-control"
                  name="ProjectAddressLine02"
                  placeholder="Address 2"
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectCity">Project City:</label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectCity"
                  v-model="project.city"
                  type="text"
                  class="form-control"
                  name="ProjectCity"
                  placeholder="Project City"
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectState">Project State/Province:</label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectState"
                  v-model="project.state"
                  type="text"
                  class="form-control"
                  name="ProjectState"
                  placeholder="TX"
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectZip">Project Zip:</label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectZip"
                  v-model="project.zipPostalCode"
                  type="text"
                  class="form-control"
                  name="ProjectZip"
                  placeholder="90210"
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectCountry">Project Country:</label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectCountry"
                  v-model="project.countryRegion"
                  type="text"
                  class="form-control"
                  name="ProjectCountry"
                  placeholder="United States"
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectContactName">Main Contact Name:</label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectContactName"
                  v-model="project.contactName1"
                  type="text"
                  class="form-control"
                  name="ProjectContactName"
                  placeholder="john@example.com"
                >
                </b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectContactEmail">Project Contact Email:</label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="ContactEmail"
                  v-model="project.contactEmail1"
                  type="text"
                  class="form-control"
                  name="ProjectContactEmail"
                  placeholder="john@example.com"
                >
                </b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectPhone">Project Phone:</label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="ContactPhone"
                  v-model="project.phone"
                  type="text"
                  class="form-control"
                  name="ProjectPhone"
                  placeholder="780 111 1111"
                >
                </b-form-input>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectStartDate">Project Start Date: </label>
              </div>

              <div class="form-group col-md-8">
                <b-form-datepicker
                  style="border: 1px solid #ced4da"
                  v-model="project.projectStart"
                  placeholder="Project Start Date"
                  class="admin-calendar"
                >
                </b-form-datepicker>
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectContractLength"
                  >Project Length (Months):
                </label>
              </div>

              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectContractLength"
                  type="tel"
                  class="form-control"
                  v-model="project.projectEnd"
                  name="ProjectContractLength"
                  placeholder=""
                ></b-form-input>
              </div>
              <!-- <label class="form-group col-md-3">Months</label> -->
            </div>
            <div class="form-row">
              <label class="form-label form-group col-md-4">
                Project Invoice Currency:
              </label>
              <div class="form-group col-md-8">
                <b-select required v-model="project.invoiceCurrency">
                  <option disabled selected style="display: none"></option>
                  <option :key="currency" v-for="currency in currencies">
                    {{ currency }}
                  </option>
                </b-select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectBillingCode">Project Billing Code: </label>
              </div>

              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectBillingCode"
                  v-model="project.internalBillingCode"
                  type="tel"
                  class="form-control"
                  name="ProjectBillingCode"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectPOno">Project PO#: </label>
              </div>

              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectPOno"
                  v-model="project.projectPO"
                  type="tel"
                  class="form-control"
                  name="ProjectPOno"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectPOValue">Project PO Value:</label>
              </div>

              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectPOValue"
                  v-model="project.projectPOValue"
                  type="tel"
                  class="form-control"
                  name="ProjectPOValue"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectVendorNo">Project Vendor #: </label>
              </div>

              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectVendorNo"
                  v-model="project.vendorNumber"
                  type="tel"
                  class="form-control"
                  name="ProjectVendorNo"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectNetwork">Project Network: </label>
              </div>

              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectNetwork"
                  v-model="project.projectNetwork"
                  type="tel"
                  class="form-control"
                  name="ProjectNetwork"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="ProjectCostObject">Cost Object: </label>
              </div>

              <div class="form-group col-md-8">
                <b-form-input
                  id="ProjectCostObject"
                  type="tel"
                  class="form-control"
                  v-model="project.poHourlyRate"
                  name="ProjectCostObject"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="billingCycle">Billing Cycle: </label>
              </div>

              <div class="form-group col-md-8">
                <b-select required v-model="project.billingCycle">
                  <option disabled selected style="display: none"></option>
                  <option>Standard Month (First to last)</option>
                  <option>Fixed Offset (26 to 25)</option>
                  <option>Floating Offset (Last Friday of month)</option>
                </b-select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="isActive">Active: </label>
              </div>

              <div class="form-group col-md-8">
                <b-select required v-model="project.isActive">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </b-select>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <div class="col-sm-12">
            <button type="submit" class="btn btn-primary float-right">
              <span v-if="isNew">Save Project</span>
              <span v-else>Update Project</span>
            </button>
          </div>
        </b-row>
      </b-form>
      <br />
      <b-row>
        <b-col cols="12">
          <Table>
            <template #thead>
              <tr>
                <th
                  style="
                    border: 1px solid black;
                    width: 10%;
                    text-align: center;
                  "
                  scope="col"
                >
                  #
                </th>
                <th
                  style="border: 1px solid black; text-align: center"
                  scope="col"
                  @click="sortBy('projectName')"
                >
                  Project Name
                  <i
                    v-if="sortKey === 'projectName'"
                    class="fas fa-arrow-up"
                    :class="{ 'fa-rotate-180': sortDirection === -1 }"
                  ></i>
                </th>
                <th
                  style="border: 1px solid black; text-align: center"
                  scope="col"
                  @click="sortBy('clientId')"
                >
                  Client
                  <i
                    v-if="sortKey === 'clientId'"
                    class="fas fa-arrow-up"
                    :class="{ 'fa-rotate-180': sortDirection === -1 }"
                  ></i>
                </th>
                <th
                  style="
                    border: 1px solid black;
                    width: 15%;
                    text-align: center;
                  "
                  scope="col"
                  @click="sortBy('projectStart')"
                >
                  Start Date
                  <i
                    v-if="sortKey === 'projectStart'"
                    class="fas fa-arrow-up"
                    :class="{ 'fa-rotate-180': sortDirection === -1 }"
                  ></i>
                </th>
                <th
                  style="
                    border: 1px solid black;
                    width: 15%;
                    text-align: center;
                  "
                  scope="col"
                  @click="sortBy('projectEnd')"
                >
                  End Date
                  <i
                    v-if="sortKey === 'projectEnd'"
                    class="fas fa-arrow-up"
                    :class="{ 'fa-rotate-180': sortDirection === -1 }"
                  ></i>
                </th>
                <th
                  style="
                    border: 1px solid black;
                    width: 15%;
                    text-align: center;
                  "
                  scope="col"
                  @click="sortBy('isActive')"
                >
                  Is Active
                  <i
                    v-if="sortKey === 'is_active'"
                    class="fas fa-arrow-up"
                    :class="{ 'fa-rotate-180': sortDirection === -1 }"
                  ></i>
                </th>
                <th
                  style="
                    border: 1px solid black;
                    width: 10%;
                    text-align: center;
                  "
                  scope="col"
                >
                  Actions
                </th>
              </tr>
            </template>
            <template #tbody>
              <tr
                v-bind:key="project.id"
                :class="isProjectSelected(project) ? 'table-active' : ''"
                v-for="(project, index) in projects"
              >
                <td style="border: 1px solid black; text-align: center">
                  {{ index + 1 }}
                </td>
                <td style="border: 1px solid black; text-align: center">
                  {{ project.projectName }}
                </td>
                <td style="border: 1px solid black; text-align: center">
                  {{ getClientName(project.clientId) }}
                </td>
                <td style="border: 1px solid black; text-align: center">
                  {{ formatDate(project.projectStart) || "-" }}
                </td>

                <td style="border: 1px solid black; text-align: center">
                  {{ getProjectEndDate(project) }}
                </td>                
                <td style="border: 1px solid black; text-align: center">
                  {{ project.isActive ? "Yes" : "No" }}
                </td>
                <td style="border: 1px solid black; text-align: center">
                  <b-icon
                    :class="isProjectSelected(project) ? 'invisible' : ''"
                    role="button"
                    icon="pencil-fill"
                    v-on:click="() => rowClicked(project)"
                  ></b-icon>
                  &nbsp;
                  <b-icon
                    role="button"
                    icon="trash-fill"
                    aria-hidden="true"
                    v-on:click="() => showDeleteConfirmBox(project)"
                  ></b-icon>
                </td>
              </tr>
            </template>
          </Table>
        </b-col>
      </b-row>
    </b-container>

    <PopupOverlay
      v-if="projectToDelete"
      :message="deleteMessage"
      v-on:close="closePopup"
    >
    </PopupOverlay>
    <b-modal
      hide-footer
      centered
      title="Add Client"
      size="xl"
      id="add-client"
      body-class="p-0"
    >
      <div class="d-block text-center p-3">
        <div class="form-row">
          <div class="form-label form-group col-md-4">
            <label for="ClientName">Client Name: </label>
          </div>
          <div class="form-label form-group col-md-8">
            <b-form-input
              id="ClientName"
              v-model="client.ClientName"
              type="text"
              class="form-control"
              placeholder="Name"
            ></b-form-input>
            <span class="text-danger" v-if="clientNameValidation"
              >Client Name must have a value</span
            >
          </div>
        </div>
        <div class="form-row">
          <div class="form-label form-group col-md-4">
            <label for="ClientAddressLine01" class="form-label"
              >Client Address 1:
            </label>
          </div>
          <div class="form-group col-md-8">
            <b-form-input
              id="ClientAddressLine01"
              v-model="client.addressLine1"
              type="text"
              class="form-control"
              placeholder="Address 1"
            ></b-form-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-label form-group col-md-4">
            <label for="ClientAddressLine02">Client Address 2: </label>
          </div>
          <div class="form-group col-md-8">
            <b-form-input
              id="ClientAddressLine02"
              v-model="client.addressLine2"
              type="text"
              class="form-control"
              placeholder="Address 2"
            ></b-form-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-label form-group col-md-4">
            <label for="ClientCity">Client City:</label>
          </div>
          <div class="form-group col-md-8">
            <b-form-input
              id="ClientCity"
              v-model="client.city"
              type="text"
              class="form-control"
              placeholder="City"
            ></b-form-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-label form-group col-md-4">
            <label for="ClientState">Client State/Province:</label>
          </div>
          <div class="form-group col-md-8">
            <b-form-input
              id="ClientState"
              v-model="client.state"
              type="text"
              class="form-control"
              placeholder="TX"
            ></b-form-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-label form-group col-md-4">
            <label for="ClientZip">Client Zip:</label>
          </div>
          <div class="form-group col-md-8">
            <b-form-input
              id="ClientZip"
              v-model="client.zipPostalCode"
              type="text"
              class="form-control"
              placeholder="90210"
            ></b-form-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-label form-group col-md-4">
            <label for="ClientCountry">Client Country:</label>
          </div>
          <div class="form-group col-md-8">
            <b-form-input
              id="ClientCountry"
              v-model="client.countryRegion"
              type="text"
              class="form-control"
              placeholder="United States"
            ></b-form-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-label form-group col-md-4">
            <label for="ClientPhone">Phone:</label>
          </div>
          <div class="form-group col-md-8">
            <b-form-input
              id="ClientPhone"
              v-model="client.phone"
              type="text"
              class="form-control"
              placeholder="(999) 999-9999"
            ></b-form-input>
          </div>
        </div>
      </div>
      <hr class="m-0" />
      <div class="p-3">
        <b-button variant="outline-danger" @click="$bvModal.hide('add-client')"
          >Close</b-button
        >
        <b-button
          class="ml-3"
          variant="outline-primary"
          @click="saveClient"
          :disabled="loading"
          >Add</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
// External libraries and components imported
import ProjectData from "../core/ProjectData";
import ClientData from "../core/ClientData";
import PopupOverlay from "../components/PopupOverlay";
import FormatUtilities from "../utilities/FormatUtilities";
import { extractError } from "../utilities/ErrorUtilities";
import Table from "@/components/Table.vue";

export default {
  // imported components registered
  components: {
    PopupOverlay,
    Table
  },

  props: {
    loading: {
      default: false
    }
  },

  // data initialization
  data() {
    return {
      clients: [],
      client: {},
      projectToDelete: null,
      projects: [],
      owners: [],
      currencies: JSON.parse(process.env.VUE_APP_CURRENCIES),
      project: new ProjectData(),
      sortKey: "",
      clientNameValidation: false
    };
  },

  created: async function () {
    try {
      // Loading projects and clients once the component is created
      this.setLoading(true);
      await this.loadClients();
      await this.loadOwners();
      this.setLoading(false);

      await this.loadProjects();
    } catch (error) {
      console.error(error);
    }
  },

  // Computed properties
  computed: {
    // Checks if the project object holds new information, or an existing one being edited
    isNew() {
      if (this.project && this.project.id) {
        return false;
      }
      return true;
    },

    // Prompts delete message if the object is set.
    deleteMessage() {
      if (this.projectToDelete !== null) {
        return `Are you Sure you want to delete the project: ${this.projectToDelete.projectName}`;
      }
      return "";
    }
  },

  // All the methods defined for this component
  methods: {
    formatDate: FormatUtilities.formatDate,
    addMonthsTo: FormatUtilities.addMonthsTo,

    // This method opens the dialog for client creation
    openAddClient() {
      this.clientNameValidation = false;
      this.client.ClientName = null;
      this.client.addressLine1 = null;
      this.client.addressLine2 = null;
      this.client.city = null;
      this.client.state = null;
      this.client.zipPostalCode = null;
      this.client.countryRegion = null;
      this.client.phone = null;
      this.$bvModal.show("add-client");
    },

    // This method closes the client creation dialog
    closePopup(confirm) {
      if (!confirm) {
        this.projectToDelete = null;
      } else {
        this.deleteProject(this.projectToDelete);
      }
    },

    sortBy(key) {
      if (key === this.sortKey) {
        this.sortDirection = -this.sortDirection; // toggle direction
      } else {
        this.sortKey = key;
        this.sortDirection = 1; // set default direction
      }

      // sort the data by the selected key and direction
      this.projects.sort((a, b) => {
        console.log(a + " " + b);
        let x = a[key];
        let y = b[key];

        if (typeof x === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        return this.sortDirection * ((x > y) - (y > x));
      });
    },

    // This method open the delete confirmation dialog
    showDeleteConfirmBox(project) {
      this.projectToDelete = project;
    },

    // This method checks if the project is selected to be edited.
    isProjectSelected(project) {
      return project.id === this.project.id;
    },

    // This method fires when row is clicked to be edited
    rowClicked(project) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      this.project = this.$_.clone(project);
    },

    // This method returns client name by id passed as param
    getClientName(id) {
      const clientIndex = this.clients.findIndex((c) => c.id === id);
      if (clientIndex >= 0) {
        return this.clients[clientIndex].clientName;
      } else {
        return "";
      }
    },

    // This method calls the api to delete the project in the DB
    // and removes the item from table upon success.
    // Here, deletion is soft delete.
    // projectToDelete object is set to null once api is being called.
    async deleteProject(project) {
      this.projectToDelete = null;
      this.setLoading(true);
      try {
        var res = await this.$axios.delete(`/projects/${project.id}`);
        if (res) {
          this.makeToast(
            "success",
            `Project: ${project.projectName} has been deleted successfully`
          );
          this.removeProject(project);
        }
      } catch (err) {
        console.error(err);
        this.makeToast("danger", extractError(err));
      } finally {
        this.setLoading(false);
      }
    },

    // This function removes the project from table once called from deleteProject()
    removeProject(project) {
      const index = this.projects.findIndex((p) => p.id === project.id);
      if (index >= 0) {
        this.projects.splice(index, 1);
      }
    },

    // This function loads Projects into projects object.
    async loadProjects() {
      return new Promise((resolve, reject) => {
        try {
          this.$axios.get(`/allProjects?sort_by=ProjectName`).then((res) => {
            if (!res.data) {
              return reject();
            }
            this.projects = res.data.map((p) => ProjectData.fromApi(p));
            console.log(this.projects);
            resolve();
          });
        } catch (err) {
          console.error(err);
          reject();
        }
      });
    },

    // This function loads Clients into clients object.
    async loadClients() {
      try {
        const res = await this.$axios.get(`/clients`);
        if (res.data && res.data.length) {
          this.clients = res.data.map((c) => ClientData.fromApi(c));
        }
      } catch (err) {
        console.error(err);
      }
    },
    //loads the project owners, right now it is only Inisght-AWP or Sightsream
    async loadOwners() {
    return new Promise((resolve, reject) => {
      try {
        this.$axios.get('/projectOwners').then((res) => {
          if (!res.data) {
            return reject();
          }
          this.owners = res.data;
          console.log("Owners data fetched:", this.owners); // Log the fetched data
          resolve();
        });
      } catch (err) {
        console.error("There was an error fetching the owners!", err);
        reject();
      }
    });
  },

    // This functions saves or updates the information based on isNew property.
    // save() is called for new project, update() for existing.
    async saveData() {
      if (this.isNew) {
        return this.save();
      }
      return this.update();
    },

    // This function calls the api to store the new project into DB.
    async save() {
      try {
        this.setLoading(true);
        // console.log(this.project.toApi());
        let response = await this.$axios.post(
          `/projects`,
          this.project.toApi()
        );
        if (response.data && response.data.rows) {
          this.projects = response.data.rows.map((p) => ProjectData.fromApi(p));
          this.makeToast(
            "success",
            `Project: ${this.project.projectName} has been saved successfully`,
          );
        }
        this.resetProject();
      } catch (err) {
        console.error(err);
        this.makeToast("danger", extractError(err));
      } finally {
        this.setLoading(false);
      }
    },

    // This function calls the api to update the existing project into DB.
    update() {
      try {
        this.setLoading(true);
        console.log(this.project.toApi());
        this.$axios.put(`/projects`, this.project.toApi()).then(() => {
          this.makeToast(
            "success",
            `Project: ${this.project.projectName} has been update successfully.`
          );
          this.updateRecords(this.project);
          this.resetProject();
        });
      } catch (err) {
        console.error(err);
        this.makeToast("danger", extractError(err));
      } finally {
        this.setLoading(false);
      }
    },

    // This function calls the api to save the new client into DB.
    async saveClient() {
      try {
        if (this.client.ClientName) {
          this.setLoading(true);

          const res = await this.$axios.post(`/clients`, this.client);
          if (res.data) {
            this.$bvModal.hide("add-client");
            this.clients.push(ClientData.fromApi(res.data));
            this.makeToast("success", `Client has been added successfully`);
          }
        } else {
          this.clientNameValidation = true;
        }
      } catch (err) {
        console.error(err);
        this.makeToast("danger", extractError(err));
      } finally {
        this.setLoading(false);
        this.client = {};
      }
    },

    // This function clears the form once project is saved.
    resetProject() {
      this.project = new ProjectData();
    },

    // This function updates the table informaiton once the project is updated.
    updateRecords(project) {
      let index = this.projects.findIndex((c) => c.id === project.id);
      this.$set(this.projects, index, project);
      this.resetProject();
    },

    // This function returns the endDate of the project if set.
    getProjectEndDate(project) {
      return project.projectStart && project.projectEnd
        ? this.addMonthsTo(project.projectStart, project.projectEnd)
        : "-";
    }
  },
  watch: {
    "client.ClientName": {
      handler(updatedProject) {
        // console.log(this.client.ClientName);
        if (this.client.ClientName) {
          this.clientNameValidation = false;
        }
      },
      deep: true // Enable deep watching if necessary
    }
  }
};
</script>

<style scoped>
label {
  font-size: 0.98rem !important;
}
h2.page_title {
  font-size: 1.5rem;
  color: #72a02e;
}
.add_client {
  width: calc(100% - 120px);
  float: left;
  margin-right: 5px;
}

td,
tr {
  font-size: 0.8rem !important;
}
</style>

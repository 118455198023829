<template>
  <div>
    <div id="admin-window">
      <b-container fluid="sm">
        <div>
          <b-card header="">
            <div class="time-sheet-header">
              <span class="time-sheet-text">{{ hiringMessage }}</span>
            </div>
            <hr />
          </b-card>
        </div>
        <RequestForm :profile="profile" v-on:onExpenseSave="saveData">
        </RequestForm>
      </b-container>
      <div>
        <b-card header="">
          <div class="time-sheet-header">
            <span class="time-sheet-text mt-5">Leaves</span>
          </div>
          <hr />
        </b-card>
        <b-table id="Dtable" striped hover :items="items" :fields="fields">
          <template v-slot:cell(actions)="data">
            <!-- <i
              type="button"
              class="fa fa-pencil fa-lg"
              @click="onProfileEdit(data.item)"
            ></i> -->
            <i
              type="button"
              class="fa fa-trash fa-lg pl-2"
              @click="profileToDelete = data.item"
            ></i>
          </template>
        </b-table>
      </div>
    </div>
    <PopupOverlay
      v-if="profileToDelete"
      :message="deleteMessage"
      v-on:close="closePopup"
    >
    </PopupOverlay>
    <b-modal
      hide-footer
      centered
      title="Edit Candidate Profile"
      id="edit-profile"
      body-class="p-0"
    >
      <div class="d-block text-center p-3">
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Leave Type:</span>
            <b-form-select
              v-model="profileToEdit.LeaveType"
              :options="['Sick', 'Vacation', 'Personal', 'Work From Home']"
            ></b-form-select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">From</span>
            <b-form-datepicker
              v-model="profileToEdit.FromDate"
              placeholder="Select a date"
              class="mb-2"
            ></b-form-datepicker>
          </div>
        </div>
        <!-- Interview Date -->
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">To</span>
            <b-form-datepicker
              v-model="profileToEdit.ToDate"
              placeholder="Select a date"
              class="mb-2"
            ></b-form-datepicker>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Notes:</span>
            <b-textarea
              rows="4"
              v-model="profileToEdit.Notes"
              placeholder="Edit your comments here..."
            ></b-textarea>
          </div>
        </div>
        <!-- Status -->
      </div>
      <hr class="m-0" />
      <div class="p-3">
        <b-button
          variant="outline-danger"
          @click="$bvModal.hide('edit-profile')"
          >Close</b-button
        >
        <b-button class="ml-3" variant="outline-primary" @click="updateProfile"
          >Save Changes</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
//import { mapGetters } from 'vuex'

import PopupOverlay from "@/components/PopupOverlay.vue";
import RequestForm from "@/components/RequestForm.vue";
import LeaveData from "../core/hiringProfileData";
import FormatUtilities from "../utilities/FormatUtilities";
import { extractError } from "../utilities/ErrorUtilities";
import Table from "@/components/Table.vue";

export default {
  // imported components registered
  components: {
    RequestForm,
    PopupOverlay
  },

  props: {
    loading: {
      default: false
    }
  },

  //
  // DATA initialization
  //

  // data initialization
  data() {
    return {
      profiles: [],
      profile: new LeaveData(),
      showSpinner: true,
      loadFailed: false,
      submitted: false,
      sortKey: "",
      sortDirection: 1,
      validationByUser: false,
      validationByUser2: false,
      profileToEdit: {},
      profileToDelete: null,
      fields: [
        // a hidden ID column

        // {
        //   key: "ID",
        //   hidden: true
        // },
        {
          key: "Leave Type",
          sortable: true
        },
        {
          key: "From Date",
          sortable: true
        },
        {
          key: "To Date",
          sortable: true
        },
        {
          key: "Notes",
          sortable: true
        },

        {
          key: "actions",
          label: "Actions"
        }
      ],
      items: [] // 1 for ascending, -1 for descending
    };
  },

  //
  // CREATED
  //

  created() {
    // this.loadProfiles();
  },

  // computed properties
  computed: {
    hiringMessage() {
      if (this.profile === null || this.profile.id === -1) {
        return "Add Leave";
      }
      // console.log(this.profile);
      // if (this.profile !== null) {
      //   return `Editing Candidate ${this.profile.id}`;
      // }

      return "Add Leave";
    },
    deleteMessage() {
      if (!this.profileToDelete) {
        return "";
      }
      console.log(this.profileToDelete);
      return `Are you sure you want to delete ${this.profileToDelete["Leave Type"]} leave.`;
    }
  },

  mounted() {
    this.getProfiles();
  },

  //
  // METHODS
  //

  // All the methods defined for this component
  methods: {
    closePopup(confirm) {
      if (!confirm) {
        console.log("cancel");
        this.profileToDelete = null;
      } else {
        this.deleteProfile(this.profileToDelete);
      }
    },

    deleteProfile(profile) {
      this.setLoading(true);
      this.$axios.delete(`deleteLeave/${profile.ID}`).then((response) => {
        console.log(response);
        if (response.status == 200) {
          this.profileToDelete = null;
          this.getProfiles();
          this.setLoading(false);
          this.makeToast(
            "success",
            "The candidate has been deleted successfully!"
          );
        }
      });
    },

    onProfileEdit(profile) {
      this.profileToEdit = this.profiles.find((p) => p.ID === profile.ID);
      this.$bvModal.show("edit-profile");
    },

    updateProfile() {
      this.$axios
        .put(`updateLeave/${this.profileToEdit.ID}`, this.profileToEdit)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.profileToEdit = {};
            this.getProfiles();
            this.makeToast(
              "success",
              "The candidate has been updated successfully!"
            );
            this.$bvModal.hide("edit-profile");
          }
        });
    },

    getProfiles() {
      this.setLoading(true);
      this.$axios.get("leaves").then((response) => {
        // console.log(response);
        if (response.status == 200) {
          //   console.log(response.data);
          this.profiles = response.data;
          let items = response.data.map((item) => {
            console.log(item);
            return {
              ID: item.ID,
              "Leave Type": item.LeaveType,
              "From Date": this.$moment(item.FromDate)
                .utc()
                .format("ddd MMM DD"),
              "To Date": this.$moment(item.ToDate).utc().format("ddd MMM DD"),
              Notes: item.Notes
            };
          });
          // console.log(items);
          this.updateItems(items);
          this.setLoading(false);
        }
      });
    },

    saveData(formData) {
      this.setLoading(true);
      this.profile = formData;
      let user = JSON.parse(localStorage.getItem("user"));
      formData.user = user;
      //now do an axios post request to save the data to the database
      this.$axios.post("leaves", formData).then((response) => {
        console.log(response);
        if (response.status == 200) {
          this.profile = null;
          this.submitted = true;
          this.getProfiles();
          this.setLoading(false);
          this.makeToast(
            "success",
            "The candidate has been added successfully!"
          );
        }
      });
    },
    onExpenseSave() {
      this.loadProfiles();
    },
    updateItems(v) {
      this.items = v;
    }
  },
  watch: {}
};
</script>

<style scoped>
#Dtable {
  font-size: 12px;
  /* width: 70vw; */
  /* display: flex;
  justify-content: center; */
}
@media only screen and (max-width: 800px) {
  #expTable {
    display: none;
  }
}
</style>

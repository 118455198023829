export default class VehicleData{

    constructor(
      //id = -1,
      id = null,
      year = "",
      make = "",
      model = "",
      vin = "",
      project = "",
      issuedTo = 0,
      registryExpireDate = null,
      plateNumber = "",
      driveLicense = "",
      insuranceCompany = "",
      financialInstitution = "",
      easyTagNumber = "",
      gasCardNumber = "",
      comment = ""
      )
    {
      this.id = id;
      this.year = year;
      this.make = make;
      this.model = model;
      this.vin = vin;
      this.project = project;
      this.issuedTo = issuedTo;
      this.registryExpireDate = registryExpireDate;
      this.plateNumber = plateNumber;
      this.driveLicense = driveLicense;
      this.insuranceCompany = insuranceCompany;
      this.financialInstitution = financialInstitution;
      this.easyTagNumber = easyTagNumber;
      this.gasCardNumber = gasCardNumber;
      this.comment = comment;
    }
  
    static fromApi(data){
        return new VehicleData(
          data.VehicleId,
          data.Year,
          data.Make,
          data.Model,
          data.VIN,
          data.Project,
          data.IssuedTo,
          data.RegistryExpireDate,
          data.PlateNumber,
          data.DriveLicense,
          data.InsuranceCompany,
          data.FinancialInstitution,
          data.EasyTagNumber,
          data.GasCardNumber,
          data.Comment)
  
    }
  
    toApi(){
      
      return{
        VehicleId: this.id,
        Year: this.year,
        Make: this.make,
        Model: this.model,
        VIN: this.vin,
        Project: this.project,
        IssuedTo: this.issuedTo,
        RegistryExpireDate: this.registryExpireDate,
        PlateNumber: this.plateNumber,
        DriveLicense: this.DriveLicense,
        InsuranceCompany: this.insuranceCompany,
        FinancialInstitution: this.financialInstitution,
        EasyTagNumber: this.easyTagNumber,
        GasCardNumber: this.gasCardNumber,
        Comment: this.comment
      }
  
    }
  
  }
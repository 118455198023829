<template>
  <div id="admin-window">
    <div class="container-sm">
      <h1>How to Install ESET Enpoint Security</h1>
      <hr />

      <p>
        Visit the ESET download website by clicking
        <a href="https://www.eset.com/int/business/download/" target="_blank"
          >here</a
        >
      </p>

      <div class="custom_card card-1 mt-3 mb-5">
        <img
          class="img-fluid border"
          src="https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/ERP_SECURITY_POLICY/1.png"
          alt="Installing ESET Enpoint Security"
        />
      </div>

      <div class="p-2">
        <ol>
          <li>
            Select "ESET Endpoint Security" suitable for your operating system.
            For Windows users, choose the Windows Installer.
            <div class="custom_card card-1 mt-3 mb-2">
              <img
                class="img-fluid border"
                src="https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/ERP_SECURITY_POLICY/2.png"
                alt="Installing ESET Enpoint Security"
              />
            </div>
            <div class="custom_card card-1 mb-5">
              <img
                class="img-fluid border"
                src="https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/ERP_SECURITY_POLICY/2_1.png"
                alt="Installing ESET Enpoint Security"
              />
            </div>
          </li>
          <br />
          <li>
            Follow the on-screen instructions to install. Click "Continue" to
            proceed.
            <div class="custom_card card-1 mt-3 mb-5">
              <img
                class="img-fluid border"
                src="https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/ERP_SECURITY_POLICY/3.png"
                alt="Installing ESET Enpoint Security"
              />
            </div>
          </li>
          <br />
          <li>
            If other security products are detected, try to uninstall them to
            avoid conflicts with ESET Endpoint Security. If you can't, select
            "Ignore."
            <div class="custom_card card-1 mt-3 mb-5">
              <img
                class="img-fluid border"
                src="https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/ERP_SECURITY_POLICY/4.png"
                alt="Installing ESET Enpoint Security"
              />
            </div>
          </li>
          <br />
          <li>
            Choose both features, click Allow all and Continue
            <div class="custom_card card-1 mt-3 mb-5">
              <img
                class="img-fluid border"
                src="https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/ERP_SECURITY_POLICY/5.png"
                alt="Installing ESET Enpoint Security"
              />
            </div>
          </li>
          <br />
          <li>
            After the installation completes, you'll be prompted to activate the
            product. Use the ESET HUB account option.
            <div class="custom_card card-1 mt-3 mb-5">
              <img
                class="img-fluid border"
                src="https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/ERP_SECURITY_POLICY/6.png"
                alt="Installing ESET Enpoint Security"
              />
            </div>
          </li>
          <br />
          <li>
            Log in with your ESET credentials
            <div class="custom_card card-1 mt-3 mb-5">
              <img
                class="img-fluid border"
                src="https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/ERP_SECURITY_POLICY/7.png"
                alt="Installing ESET Enpoint Security"
              />
            </div>
          </li>
          <br />
          <li>
            Select the Insight licence and click Continue.
            <div class="custom_card card-1 mt-3 mb-5">
              <img
                class="img-fluid border"
                src="https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/ERP_SECURITY_POLICY/8.png"
                alt="Installing ESET Enpoint Security"
              />
            </div>
          </li>
          <br />
          <li>
            Take note of your computer's name and let us know what that is.
            Done.
            <div class="custom_card card-1 mt-3 mb-5">
              <img
                class="img-fluid border"
                src="https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/ERP_SECURITY_POLICY/9.png"
                alt="Installing ESET Enpoint Security"
              />
            </div>
          </li>
          <br />
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>

<template>
  <div id="admin-window">
    <div class="container-sm">
      <h1>Using Timesheet Review</h1>
      <hr />

      Timesheet Review can easily be accessed by navigating to "Timesheet
      Review" under the Admin tab <br />The initial layout looks like the
      following:

      <div class="custom_card card-1">
        <img
          class="img-fluid border"
          src="@/assets/images/docs/admin/image3.png"
          alt="Add Timesheet Image"
        />
      </div>
      <div class="p-2">
        <ol>
          <li>
            <strong>Select Employees to Review:</strong><br />You must select
            the employee(s) whose timesheet you wish to review. <br />
          </li>
          <br />
          <li>
            <strong>Select Date Range:</strong><br />You must select the date
            range for which you wish to review the timesheet.
            <br />
            <br />
            <b>Note:</b> <i>Step 1</i> is mandatory to proceed with this step.
          </li>
          <br />
          <li>
            <strong>Select Project:</strong><br />You must select the project
            for which you wish to review the timesheet. <br /><br />
            <b>Note:</b> <i>Step 1</i> and <i>Step 1</i> is mandatory to proceed
            with this step.
            <br />
            <br />
            <b>Note:</b> The Billing period for all projects can be different,
            so the timesheet review will be displayed according to the billing
            period of the project.
            <br />
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>

<!-- 
          <li>
            <strong>Task Performed:</strong><br />This field is mandatory and
            should contain the brief discription about the task performed.
            However, It has 50 characters limit. This can be modified later if
            need be.
          </li>
          <br />

           <li>
            <strong>Save:</strong><br />Click save once all the fields are
            filled correctly. It may throw validation error, if any field fails
            to satisfy the required input.
          </li>

        -->

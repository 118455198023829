<template>
  <div>
    <div class="d-flex align-items-center">
      <h1 class="mt-3 ml-3">Employee - Project Roster</h1>
      <b-button
        v-if="canEdit"
        @click="saveData"
        variant="primary"
        class="ml-auto mr-3 mt-3"
        :disabled="loading"
      >
        Save
      </b-button>
    </div>

    <div class="filters mt-3 ml-3 d-flex align-items-end">
      <b-form-group label="Filter by Project" class="mb-0">
        <multiselect
          v-model="selectedProjects"
          :options="projectOptions"
          :multiple="true"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          :taggable="false"
          placeholder="Select Project"
          label="text"
          track-by="value"
          @input="filterRoster"
          class="interactive-dropdown"
        >
        </multiselect>
      </b-form-group>

      <b-form-group label="Filter by Employee" class="ml-3 mb-0">
        <multiselect
          v-model="selectedEmployee"
          :options="employeeOptions"
          :multiple="true"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          :taggable="false"
          placeholder="Select Employee"
          label="text"
          track-by="value"
          @input="filterRoster"
          class="interactive-dropdown"
        >
        </multiselect>
      </b-form-group>

      <b-button
        variant="secondary"
        class="ml-3 reset-button"
        @click="resetFilters"
      >
        Reset Filters
      </b-button>
    </div>

    <div class="tableDiv">
      <table>
        <thead>
          <tr>
            <th class="employee-name sticky-col hoverable"
              @click="sortBy('name')" >Employee Name
            </th>

            <td
              v-for="project in filteredProjects"
              :key="project.ProjectID"
               :class="{'project-name hoverable': true, 'sorted': sortKeys.includes(project.ProjectID)}"
              @click="sortByProject(project.ProjectID)"
            >
              {{ project.ProjectName }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="employee in filteredEmployees" :key="employee.id">
            <td class="employee-name sticky-col">{{ employee.Name }}</td>
            <td
              v-for="project in filteredProjects"
              :key="project.ProjectID"
              class="checkbox-cell"
            >
              <input
                type="checkbox"
                :checked="isAllowedToBill(employee.id, project.ProjectID)"
                v-if="canEdit"
                @change="updateIsAllowedToBill(employee.id, project.ProjectID)"
              />
              <span v-else>
                {{
                  isAllowedToBill(employee.id, project.ProjectID) ? "Yes" : ""
                }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { sortBy } from "lodash";
import { mapGetters } from "vuex";
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
export default {
  components: {
    Multiselect
  },
  props: {
    loading: {
      default: false
    }
  },
  data() {
    return {
      employees: [],
      projects: [],
      filteredEmployees: [],
      filteredProjects: [],
      selectedProjects: [],
      selectedEmployee: [],
      isAllowedToBillData: [],
      updatedIsAllowedToBillData: [],
      sortKeys: [], // Store multiple selected project IDs
      sortOrder: 1,

      tablekey: 0   // Key to force re-render
    };
  },
  mounted() {
    this.getEmployees();
    this.getProjects();
    this.getRoaster();
  },
  computed: {
    projectOptions() {
      return this.projects.map((project) => ({
        value: project.ProjectID,
        text: project.ProjectName
      }));
    },
    employeeOptions() {
      return this.employees.map((employee) => ({
          value: employee.id,
          text: employee.Name
      }));
    },
    canEdit() {
      return (
        this.currentUser.username === "brian.kean@insight-awp.com" ||
        this.currentUser.username === "gregorio.labbozzetta@insight-awp.com" ||
        this.currentUser.username === "navjot.randhawa@insight-awp.com" ||
        this.currentUser.username ==="kenia.benitez@insight-awp.com"
      );
    },
    ...mapGetters({
      currentUser: "getLoggedInUser"
    })
  },
  methods: {
    sortBy(key) {
      if (key === 'name') {
        this.sortKeys = []; // Clear project-based sorting
      }
      this.sortKey = key;
      this.sortOrder = this.sortOrder * -1;
      this.sortEmployees();
    },
    sortByProject(projectID) {
      if (this.sortKeys.includes(projectID)) {
        this.sortKeys = this.sortKeys.filter(id => id !== projectID);
      } else {
        this.sortKeys.push(projectID);
      }
      this.sortEmployees();
    },
    sortEmployees() {
      this.filteredEmployees.sort((a, b) => {
        for (let key of this.sortKeys) {
          const aAssigned = this.isAllowedToBill(a.id, key);
          const bAssigned = this.isAllowedToBill(b.id, key);

          if (aAssigned && !bAssigned) {
            return -1;
          } else if (!aAssigned && bAssigned) {
            return 1;
          }
        }
        return a.Name.localeCompare(b.Name);
      });

      // Force re-render
      this.tableKey += 1;

      // Add a slight delay to ensure DOM updates are complete
      setTimeout(() => {
        this.forceReflow();
      }, 100);
      },
    forceReflow() {
      const table = this.$el.querySelector('table');
      if (table) {
        console.log("Forcing reflow");
        table.style.display = 'none';
        table.offsetHeight; // Trigger reflow
        table.style.display = '';
      }
    },
    async getEmployees() {
      this.setLoading(true);
      try {
        const res = await this.$axios.get(`/activeStaffs`);
        if (res && res.data.length > 0) {
          this.employees = res.data;
        }
        this.filteredEmployees = this.employees;
        this.setLoading(false);
      } catch (err) {
        console.error(err);
      }
    },
    async getProjects() {
      this.setLoading(true);
      try {
        const res = await this.$axios.get(`/projects?sort_by=ProjectName`);
        if (res && res.data.length > 0) {
          this.projects = res.data;
        }
        this.filteredProjects = this.projects;
        this.setLoading(false);
      } catch (err) {
        console.error(err);
      }
    },
    resetFilters() {
      this.selectedProjects = [];
      this.selectedEmployee = "";
      this.filterRoster();
      this.sortKeys = [];
      this.sortEmployees();
    },

filterRoster() {
  // Include all employees in the filtered list
  this.filteredEmployees = this.employees;

  // Further filter employees based on selected employees
  if (this.selectedEmployee.length > 0) {
    this.filteredEmployees = this.filteredEmployees.filter(employee => 
      this.selectedEmployee.some(selected => selected.value === employee.id)
    );
  }

  // Filter projects based on selected projects
  this.filteredProjects = this.selectedProjects.length > 0
    ? this.projects.filter(project => 
        this.selectedProjects.some(selectedProject => selectedProject.value === project.ProjectID)
      )
    : this.projects;

  // Force re-render
  this.tableKey += 1;

  // Add a slight delay to ensure DOM updates are complete
  setTimeout(() => {
    this.forceReflow();
  }, 100);
},
    async getRoaster() {
      try {
        const res = await this.$axios.get(`/peRoaster`);
        if (res && res.data.length > 0) {
          const data = res.data;
          let newData = [];
          for (let i = 0; i < data.length; i++) {
            newData.push({
              employeeId: data[i].empId,
              projectId: data[i].projectId,
              isAllowedToBill: data[i].canBillHours
            });
          }
          this.isAllowedToBillData = newData;
        }
      } catch (err) {
        console.error(err);
      }
    },
    isAllowedToBill(employeeId, projectId) {
      const record = this.isAllowedToBillData.find(
        (entry) =>
          entry.employeeId === employeeId && entry.projectId === projectId
      );
      return record ? record.isAllowedToBill : false;
    },
    updateIsAllowedToBill(employeeId, projectId) {
      const record = this.isAllowedToBillData.find(
        (ent) => ent.employeeId === employeeId && ent.projectId === projectId
      );
      const wasUpdated = this.updatedIsAllowedToBillData.find(
        (ent) => ent.employeeId === employeeId && ent.projectId === projectId
      );
      if (wasUpdated) {
        //remove the record from updatedIsAllowedToBillData
        this.updatedIsAllowedToBillData =
          this.updatedIsAllowedToBillData.filter(
            (ent) =>
              ent.employeeId !== employeeId || ent.projectId !== projectId
          );
      } else {
        if (record) {
          this.updatedIsAllowedToBillData.push({
            employeeId,
            projectId,
            isAllowedToBill: !record.isAllowedToBill
          });
        } else {
          this.updatedIsAllowedToBillData.push({
            employeeId,
            projectId,
            isAllowedToBill: true
          });
        }
      }

      console.log("updated Data", this.updatedIsAllowedToBillData);
    },
    mergeUpdatedData() {
      this.updatedIsAllowedToBillData.forEach((updatedRecord) => {
        const index = this.isAllowedToBillData.findIndex(
          (record) =>
            record.employeeId === updatedRecord.employeeId &&
            record.projectId === updatedRecord.projectId
        );
        if (index !== -1) {
          this.isAllowedToBillData[index] = updatedRecord;
        } else {
          this.isAllowedToBillData.push(updatedRecord);
        }
      });
      // Clear the updatedIsAllowedToBillData array after merging
      this.updatedIsAllowedToBillData = [];
    },
    async saveData() {
      this.setLoading(true);
      // Merge updatedIsAllowedToBillData into isAllowedToBillData
      try {
        await this.$axios.post(`/peRoaster`, this.updatedIsAllowedToBillData);
      } catch (err) {
        let data = err.response && err.response.data;
        if (data && data.message) {
          return this.displayError(data.message);
        }
        this.displayError("Failed to save Roaster record, please try later.");
      } finally {
        this.setLoading(false);
      }
      this.makeToast("success", "Roaster has been saved!");
      //window.location.reload();
      this.mergeUpdatedData();

      this.sortEmployees();
    }
  }
};
</script>

<style scoped>
/* Custom styles for multiselect component */
::v-deep .multiselect__placeholder,
::v-deep .multiselect__input {
  color: #495057 !important;
  font-size: 1rem !important;
}

::v-deep .multiselect__option--selected {
  background: #e9ecef !important;
  color: black !important;
}

::v-deep .multiselect__tag {
  background: #007bff !important;
  color: #fff !important;
}

::v-deep .multiselect__tag-icon,
::v-deep .multiselect__tag-icon::after {
  color: #fff !important; /* White color for the icon */
}

::v-deep .multiselect__tag-icon:hover {
  background-color: #0056b3 !important; /* Background color on hover */
}

::v-deep .multiselect .multiselect__content-wrapper .multiselect__option--highlight,
::v-deep span.multiselect__option.multiselect__option--highlight.multiselect__option--selected {
  background: #007bff !important;
  color: #fff !important;
}

::v-deep span.multiselect__option.multiselect__option--highlight::after,
::v-deep span.multiselect__option.multiselect__option--highlight.multiselect__option--selected::after {
  background: #007bff !important;
  color: #007bff !important;
}

::v-deep .multiselect__tags:hover,
::v-deep .multiselect__tags:focus,
::v-deep .multiselect__content-wrapper:hover,
::v-deep .multiselect__content-wrapper:focus {
  background-color: #e9ecef !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2) !important;
}
/* Container for dropdowns and buttons */
.filters {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;

  position: relative; /* Ensure the container has a relative position */
  z-index: 10; /* Set a higher z-index for the container */
}

/* Updated dropdown style with consistent size */
.interactive-dropdown {
  width: 400px; /* Fixed width for all dropdowns */
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  z-index: 20; /* Ensure the dropdown has a higher z-index */

}
/* Reset button style */
.reset-button {
  background-color: #007bff;
  color: white;
  border-radius: 8px;
  padding: 8px 20px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reset-button:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Table styles */
.tableDiv {
  overflow: auto;
  margin: 20px;
  min-width: 20%;
  max-width: 100%;
  max-height: 81vh;
  scrollbar-width: thin;
  scrollbar-color: #dddddd #f9f9f9;
  position: relative; /* Ensure the table container has a relative position */
  z-index: 1; /* Set a lower z-index for the table container */
}

table {
  min-width: 20%;
  max-width: 100%;
  border-collapse: collapse;
  border: 0;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

.employee-name,
.project-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .employee-name {
  width: 10px !important;
} */

/* .checkbox-cell {
  width: 10px !important;
} */

.checkbox-cell input[type="checkbox"] {
  margin: auto;
  display: block;
  transition: transform 0.2s ease-in-out;
}

.checkbox-cell input[type="checkbox"]:checked {
  transform: scale(1.2);
}

thead {
  z-index: 100;
  background-color: #f2f2f2;
  cursor: pointer;
}


th {
  background-color: #f2f2f2;
  position: sticky;
  left: 0;
  top: 0 !important;
  z-index: 100;
  border-left: 0;
}

/* Add hover effect for all th and td elements with hoverable class */
th.hoverable,
td.hoverable {
  cursor: pointer;
}
th.hoverable:hover,
td.hoverable:hover {
  text-decoration: underline;
}

/* Add underline for sorted project names */
td.sorted {
  text-decoration: underline;
}

td:first-child {
  position: sticky;
  left: 0;
  background-color: #f9f9f9;
  z-index: 99;
  border-left: 0;
}
</style>
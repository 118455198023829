<template>
  <div>
    <section id="container" style="height: 90.5vh; width: 100%"></section>
  </div>
</template>

<script>
import * as pbi from "powerbi-client";
import Vue from "vue";
//import pdf from 'vue-pdf'
import CurrencyCalculator from "@/components/CurrencyCalculator.vue";
import FormatUtilities from "../utilities/FormatUtilities";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";

export default {
  data() {
    return {
      embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${process.env.VUE_APP_ERPTIME_REPORTID}&groupId=f3a21ed2-f377-4e4c-9c9f-30822b18936c&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d`,
      accessToken: ""
    };
  },
  methods: {
    // async initializePowerBI() {
    //     const sampleReportUrl = this.sampleReportUrl;
    //     const reportConfigResponse = await fetch(sampleReportUrl);
    //     if (!reportConfigResponse.ok) {
    //         console.error('Failed to fetch config for report.');
    //         console.error('Status:', reportConfigResponse.status, reportConfigResponse.statusText)
    //         return;
    //     }
    //     const reportConfig = await reportConfigResponse.json();
    //     console.log(reportConfig)
    //     console.log('The access token is set. Loading the Power BI report');
    //     console.log(reportConfig.EmbedUrl)
    //     this.embedUrl = reportConfig.EmbedUrl;
    //     this.accessToken = reportConfig.EmbedToken.Token;
    // }
  },
  async mounted() {
    // this.initializePowerBI().then(() => {
    //console.log(process.env)
    this.setLoading(true);
    await this.$axios
      .post("/getPBIEmbed", { reportId: process.env.VUE_APP_ERPTIME_REPORTID })
      .then((response) => {
        //console.log(response.data.body.token)
        this.setLoading(false);
        this.accessToken = response.data.body.token;
        const permissions = pbi.models.Permissions.All;

        const config = {
          type: "report",
          tokenType: pbi.models.TokenType.Embed,
          accessToken: this.accessToken,
          embedUrl: this.embedUrl,
          settings: {
            panes: {
              filters: {
                visible: false
              },
              pageNavigation: {
                visible: false
              }
            }
          },
          permissions: permissions
        };

        let powerbi = new pbi.service.Service(
          pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory,
          pbi.factories.routerFactory
        );

        const dashboardContainer = document.getElementById("container");
        const dashboard = powerbi.embed(dashboardContainer, config);

        dashboard.off("loaded");
        dashboard.off("rendered");
        dashboard.on("error", function () {
          this.dashboard.off("error");
        });
      });
    // })
  }
};
</script>

/**
 * Creates a new date object that is the given date + the given
 * number of days
 * @param {Date} date the date to add to
 * @param {number} days the number of days to add
 */
export function addDays(date, days){
  let retDate = new Date(date);
  retDate.setDate(retDate.getDate() + days);
  return retDate;
}

/**
 * maps the given range into the corresponding set
 * of dates, all 1 day apart.
 * @param {object} range an object of the form {start, end}
 */
export function mapRangeToDates(range){

  const startDay = range.start;
  const endDay = range.end;
  let currentDay = startDay;
  let retList = [];

  while(currentDay <= endDay){
    retList.push(currentDay);
    currentDay = addDays(currentDay, 1);
  }

  return retList;
}
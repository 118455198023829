<template>
  <b-modal
    hide-footer
    hide-header
    centered
    title="Edit Timesheet"
    id="edit-time"
    body-class="p-0"
  >
    <div>
      <section id="container"></section>
    </div>
    <!-- <hr class="m-0" />
        <div class="p-3">
          <b-button variant="outline-danger" @click="$bvModal.hide('edit-time')"
            >Close</b-button
          >
          <b-button class="ml-3" variant="outline-primary"
            >Save Changes</b-button
          >
        </div> -->
  </b-modal>
</template>

<script>
import * as pbi from "powerbi-client";
import Vue from "vue";
//import pdf from 'vue-pdf'
import CurrencyCalculator from "@/components/CurrencyCalculator.vue";
import FormatUtilities from "../utilities/FormatUtilities";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";

export default {
  data() {
    return {
      embedUrl: `https://app.powerbi.com/rdlEmbed?reportId=07ab9297-8d98-47f3-a8bf-e7372b119bb5&rp:parmProjects=BCEP&rp:parmDate=April 2023&rp:parmUser=Brent Beach&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d`,
      accessToken: ""
    };
  },
  methods: {
    // async initializePowerBI() {
    //     const sampleReportUrl = this.sampleReportUrl;
    //     const reportConfigResponse = await fetch(sampleReportUrl);
    //     if (!reportConfigResponse.ok) {
    //         console.error('Failed to fetch config for report.');
    //         console.error('Status:', reportConfigResponse.status, reportConfigResponse.statusText)
    //         return;
    //     }
    //     const reportConfig = await reportConfigResponse.json();
    //     console.log(reportConfig)
    //     console.log('The access token is set. Loading the Power BI report');
    //     console.log(reportConfig.EmbedUrl)
    //     this.embedUrl = reportConfig.EmbedUrl;
    //     this.accessToken = reportConfig.EmbedToken.Token;
    // }
  },
  async mounted() {
    // this.initializePowerBI().then(() => {
    //console.log(process.env)
    this.$bvModal.show("edit-time");
    this.setLoading(true);
    await this.$axios
      .post("/getPBIEmbed", {
        reportId: "07ab9297-8d98-47f3-a8bf-e7372b119bb5"
      })
      .then((response) => {
        //console.log(response.data.body.token)
        this.setLoading(false);
        this.accessToken = response.data.body.token;
        const permissions = pbi.models.Permissions.All;

        const config = {
          type: "report",
          tokenType: pbi.models.TokenType.Embed,
          accessToken: this.accessToken,
          embedUrl: this.embedUrl,
          settings: {
            panes: {
              filters: {
                visible: false
              },
              pageNavigation: {
                visible: false
              }
            }
          },
          permissions: permissions
        };

        console.log(config);

        let powerbi = new pbi.service.Service(
          pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory,
          pbi.factories.routerFactory
        );

        const dashboardContainer = document.getElementById("container");
        const dashboard = powerbi.embed(dashboardContainer, config);

        dashboard.off("loaded");
        dashboard.off("rendered");
        dashboard.on("error", function () {
          this.dashboard.off("error");
        });
      });
    // })
  }
};
</script>
<style>
/* #edit-time{
    width: 1100px !important;
} */
/* 
#container {
  width: 30vw;
  height: 42vw;
  border: 2px solid black;
}

@media only screen and (max-width: 1700px) {
  #container {
    width: 50vw;
    height: 42vw;
  }
} */
</style>

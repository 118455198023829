<template>
  <div>
    <b-container>
      <div class="d-flex justify-content-between">
        <h2 class="p-0 page_title">Profile</h2>
      </div>
      <hr class="my-2" />
      <br />
      <Form :user="profile" @update="update"></Form>
    </b-container>
  </div>
</template>

<script>
import StaffData from "@/core/StaffData";
import { extractError } from "@/utilities/ErrorUtilities";
import FormatUtilities from "@/utilities/FormatUtilities";
import Form from "@/components/User/Form.vue";

export default {
  components: { Form },
  data() {
    return {
      profile: new StaffData()
    };
  },

  async created() {
    try {
      this.setLoading(true);
      await this.loadProfile();
    } catch (error) {
      console.error(error);
    } finally {
      this.setLoading(false);
    }
  },

  methods: {
    addMonthsTo: FormatUtilities.addMonthsTo,

    loadProfile() {
      return new Promise((resolve, reject) => {
        try {
          this.$axios.get(`/profile`).then((res) => {
            if (!res.data) {
              return reject();
            }
            this.profile = StaffData.fromApi(res.data);
            resolve();
          });
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    },

    async update(data) {
      this.setLoading(true);
      try {
        await this.$axios.put(`/profile`, data.toApi());
        this.makeToast(
          "success",
          `Your profile has been updated successfully.`
        );
      } catch (err) {
        console.error(err);
        this.makeToast("danger", extractError(err));
      } finally {
        this.setLoading(false);
      }
    }
  }
};
</script>

<style scoped>
label {
  font-size: 1.1rem !important;
}
h2.page_title {
  font-size: 1.5rem;
  color: #72a02e;
}
</style>

<template>
  <div id="admin-window">
    <div class="container-sm">
      <h1>Documentation</h1>
      <hr />

      <p>
        The purpose of this documentation is to provide useful information regarding
        the different modules of InsightAWP.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
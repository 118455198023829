export default class ClientData {
  constructor(
    id = -1,
    clientName = "",
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    state = "",
    country = "",
    postalCode = "",
    phone = "",
    contacts = [],
    user = ""
  ) {
    this.id = id;
    this.clientName = clientName;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.city = city;
    this.state = state;
    this.country = country;
    this.postalCode = postalCode;
    this.phone = phone;
    this.contacts = contacts;
    this.user = user;
  }

  /**
   * Changes the object into an object ready to be consumed by the api
   */
  toApi() {
    return {
      Id: this.id,
      ClientName: this.clientName,
      AddressLine1: this.addressLine1,
      AddressLine2: this.addressLine2,
      City: this.city,
      StateProvince: this.state,
      Country: this.country,
      ZipPostalCode: this.postalCode,
      Phone: this.phone,
      Contacts: this.contacts.map(c => c.toApi()),
      User: this.user
    };
  }

  /**
   * handles transformation from the api,
   * into the data class properties.
   * @param {object} ClientData
   */
  static fromApi(data) {
    return new ClientData(
      data.ClientID,
      data.ClientName,
      data.AddressLine1,
      data.AddressLine2,
      data.City,
      data.StateProvince,
      data.CountryRegion,
      data.ZipPostalCode,
      data.Phone,
      [],
      null
    );
  }
}

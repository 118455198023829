export const min_number ={
  validate(value, args){
    const number = parseFloat(value);
    if(number !== NaN){
      return number > args.min;
    }
    return true;
  },
  params: ['min'],
  message: '{_field_} must be greater than 0'
}
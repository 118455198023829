<template>
  <div>
    <b-container>
      <div class="d-flex justify-content-between">
        <h2 class="p-0 page_title">
          {{ isNew ? "Add New Vehicle" : `Editing: ${vehicle.make}` }}
        </h2>
        <button v-if="!isNew" class="btn btn-danger" @click="resetVehicle()">
          Cancel Editing
        </button>
      </div>
      <hr class="my-2" />
      <b-form @submit.prevent="saveData">
        <b-row>
          <b-col cols="6">
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="Year">Year: </label>
              </div>
              <div class="form-group col-md-8">
                <select
                  required
                  v-model="vehicle.year"
                  class="form-control add_vehicle"
                  placeholder="Choose Year"
                >
                  <option disabled selected style="display: none"></option>
                  <option
                    :key="codeYear.subId"
                    v-for="codeYear in codeYears"
                    :value="codeYear.subId"
                  >
                    {{ codeYear.subId }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="Make">Make: </label>
              </div>
              <div class="form-label form-group col-md-8">
                <select
                  required
                  v-model="vehicle.make"
                  class="form-control add_vehicle"
                  placeholder="Choose Year"
                >
                  <option disabled selected style="display: none"></option>
                  <option
                    :key="codeMake.subId"
                    v-for="codeMake in codeMakes"
                    :value="codeMake.subId"
                  >
                    {{ codeMake.title }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="Model" class="form-label">Model: </label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="Model"
                  v-model="vehicle.model"
                  type="text"
                  class="form-control"
                  name="Model"
                  placeholder="Model"
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="VIN" class="form-label">VIN: </label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="VIN"
                  v-model="vehicle.vin"
                  type="text"
                  class="form-control"
                  name="VIN"
                  placeholder="VIN"
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="RegistrarExpires">Registry Expire Date:</label>
              </div>
              <div class="form-group col-md-8">
                <b-form-datepicker
                  v-model="vehicle.registryExpireDate"
                  placeholder="Registry Expire Date"
                  class="admin-calendar"
                >
                </b-form-datepicker>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="PlateNumber">Plate Number:</label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="PlateNumber"
                  v-model="vehicle.plateNumber"
                  type="text"
                  class="form-control"
                  name="PlateNumber"
                  placeholder="Plate Number"
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="PlateNumber">Drive License:</label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="DriveLicense"
                  v-model="vehicle.DriveLicense"
                  type="text"
                  class="form-control"
                  name="DriveLicense"
                  placeholder="Drive License"
                ></b-form-input>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="project">Project: </label>
              </div>
              <div class="form-group col-md-8">
                <select
                  required
                  v-model="vehicle.project"
                  class="form-control add_vehicle"
                  placeholder="Choose Name"
                >
                  <option disabled selected style="display: none"></option>
                  <option
                    :key="project.id"
                    v-for="project in projects"
                    :value="project.id"
                  >
                    {{ project.projectName }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="issuedTo">Issued To: </label>
              </div>
              <div class="form-group col-md-8">
                <select
                  required
                  v-model="vehicle.issuedTo"
                  class="form-control add_vehicle"
                  placeholder="Choose Name"
                >
                  <option
                    :key="userName.id"
                    v-for="userName in userNames"
                    :value="userName.id"
                  >
                    {{ userName.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="InsuranceCompany">Insurance Company: </label>
              </div>
              <div class="form-label form-group col-md-8">
                <select
                  required
                  v-model="vehicle.insuranceCompany"
                  class="form-control add_vehicle"
                  placeholder="Choose Insurance"
                >
                  <option disabled selected style="display: none"></option>
                  <option
                    :key="codeInsurance.subId"
                    v-for="codeInsurance in codeInsurances"
                    :value="codeInsurance.subId"
                  >
                    {{ codeInsurance.title }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="InsuranceAmount">Financial Institution: </label>
              </div>
              <div class="form-group col-md-8">
                <b-form-input
                  id="InsuranceAmount"
                  v-model="vehicle.financialInstitution"
                  type="text"
                  class="form-control"
                  name="FinancialInstitution"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>

            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="EasyTagNumber">Easy Tag Number: </label>
              </div>

              <div class="form-group col-md-8">
                <b-form-input
                  id="EasyTagNumber"
                  v-model="vehicle.easyTagNumber"
                  type="text"
                  class="form-control"
                  name="EasyTagNumber"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label form-group col-md-4">
                <label for="GasCardNumber">Gas Card Number: </label>
              </div>
              <div class="form-label form-group col-md-8">
                <select
                  required
                  v-model="vehicle.gasCardNumber"
                  class="form-control add_vehicle"
                  placeholder="Choose Gas Card"
                >
                  <option disabled selected style="display: none"></option>
                  <option
                    :key="codeGasCard.subId"
                    v-for="codeGasCard in codeGasCards"
                    :value="codeGasCard.subId"
                  >
                    {{ codeGasCard.title }}
                  </option>
                </select>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="form-row">
          <div class="form-group col-12 col-sm-3 col-md-2 col-lg-2">
            <label for="GasCardNumber">Comment: </label>
          </div>
          <div class="form-group col-12 col-sm-9 col-md-10 col-lg-10">
            <b-form-textarea
              id="Comment"
              v-model="vehicle.comment"
              type="text"
              class="form-control"
              name="Comment"
              placeholder="Enter something"
              rows="3"
              max-rows="4"
            ></b-form-textarea>
          </div>
        </div>

        <b-row>
          <div class="col-sm-12">
            <button type="submit" class="btn btn-primary float-right">
              <span v-if="isNew">Save Vehicle</span>
              <span v-else>Update Vehicle</span>
            </button>
          </div>
        </b-row>
      </b-form>
      <br />
      <b-row>
        <b-col cols="12">
          <Table v-if="vehicles && vehicles.length">
            <template #thead>
              <tr>
                <th scope="col" width="5%">#</th>
                <th scope="col" width="8%">Year</th>
                <th scope="col" width="12%">Make</th>
                <th scope="col">VIN</th>
                <th scope="col">Project</th>
                <th scope="col">Issued To</th>
                <th scope="col">Actions</th>
              </tr>
            </template>
            <template #tbody>
              <tr
                v-bind:key="vehicle.id"
                :class="isVehicleSelected(vehicle) ? 'table-active' : ''"
                v-for="(vehicle, index) in vehicles"
              >
                <td width="5%">{{ index + 1 }}</td>
                <td width="8%">{{ vehicle.year }}</td>
                <td width="12%">{{ vehicle.make }}</td>
                <td>{{ vehicle.vin }}</td>
                <!-- <td>{{ vehicle.issuedTo }}</td> -->
                <td>{{ getProjectName(vehicle.project) }}</td>
                <td>{{ getIssuedName(vehicle.issuedTo) }}</td>
                <!-- <td>{{ formatDate(vehicle.vehicleStart) }}</td> -->
                <td>
                  <b-icon
                    :class="isVehicleSelected(vehicle) ? 'invisible' : ''"
                    role="button"
                    icon="pencil-fill"
                    v-on:click="() => rowClicked(vehicle)"
                  ></b-icon>
                  &nbsp;
                  <b-icon
                    role="button"
                    icon="trash-fill"
                    aria-hidden="true"
                    v-on:click="() => showDeleteConfirmBox(vehicle)"
                  ></b-icon>
                </td>
              </tr>
            </template>
          </Table>
        </b-col>
      </b-row>
    </b-container>

    <PopupOverlay
      v-if="vehicleToDelete"
      :message="deleteMessage"
      v-on:close="closePopup"
    >
    </PopupOverlay>
  </div>
</template>

<script>
import VehicleData from "../core/VehicleData";
import CodeYearData from "../core/CodeCommonData";
import CodeMakeData from "../core/CodeCommonData";
import CodeInsuranceData from "../core/CodeCommonData";
import CodeGasCardData from "../core/CodeCommonData";
import ProjectData from "../core/ProjectData";
import UserNameData from "../core/UserNameData";
import FormatUtilities from "../utilities/FormatUtilities";
import { extractError } from "../utilities/ErrorUtilities";
import Table from "@/components/Table.vue";
import PopupOverlay from "../components/PopupOverlay";

export default {
  components: {
    Table,
    PopupOverlay
  },

  data() {
    return {
      vehicles: [],
      vehicle: new VehicleData(),
      codeYears: [],
      codeYear: {},
      codeMakes: [],
      codeMake: {},
      codeInsurances: [],
      codeInsurance: {},
      codeGasCards: [],
      codeGasCard: {},
      projects: [],
      project: {},
      userNames: [],
      userName: {},
      vehicleToDelete: null,
      currencies: JSON.parse(process.env.VUE_APP_CURRENCIES)
    };
  },

  created: function () {
    this.setLoading(true);
    this.loadVehicleMetaData()
      .then(() => {
        this.loadUserNames();
        this.loadProjects();
        this.loadVehicles();
      })
      .finally(() => this.setLoading(false));
  },

  computed: {
    isNew() {
      if (this.vehicle && this.vehicle.id) {
        return false;
      }
      return true;
    },

    deleteMessage() {
      if (this.vehicleToDelete !== null) {
        return `Are you Sure you want to delete the vehicle: ${this.vehicleToDelete.make}`;
      }
      return "";
    }
  },

  methods: {
    formatDate: FormatUtilities.formatDate,
    addMonthsTo: FormatUtilities.addMonthsTo,

    closePopup(confirm) {
      if (!confirm) {
        this.vehicleToDelete = null;
      } else {
        this.deleteVehicle(this.vehicleToDelete);
      }
    },

    showDeleteConfirmBox(vehicle) {
      this.vehicleToDelete = vehicle;
    },

    isVehicleSelected(vehicle) {
      return vehicle.id === this.vehicle.id;
    },

    rowClicked(vehicle) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      this.vehicle = this.$_.clone(vehicle);
    },

    getIssuedName(id) {
      const userNameIndex = this.userNames.findIndex(
        (c) => c.id === parseInt(id)
      );
      if (userNameIndex >= 0) {
        return this.userNames[userNameIndex].name;
      }
      return "";
    },

    getProjectName(id) {
      const projectIndex = this.projects.findIndex(
        (c) => c.id === parseInt(id)
      );
      if (projectIndex >= 0) {
        return this.projects[projectIndex].projectName;
      }
      return "";
    },

    async deleteVehicle(vehicle) {
      this.vehicleToDelete = false;
      this.setLoading(true);
      try {
        var res = await this.$axios.delete(`/vehicles/${vehicle.id}`);
        if (res) {
          this.makeToast(
            "success",
            `Vehicle: ${vehicle.make} has been deleted successfully`
          );
          this.removeVehicle(vehicle);
        }
      } catch (err) {
        console.error(err);
        this.makeToast("danger", extractError(err));
      } finally {
        this.setLoading(false);
        this.vehicleToDelete = null;
      }
    },

    removeVehicle(vehicle) {
      const index = this.vehicles.findIndex((p) => p.id === vehicle.id);
      if (index >= 0) {
        this.vehicles.splice(index, 1);
      }
    },

    async loadVehicles() {
      return new Promise((resolve, reject) => {
        try {
          this.$axios.get(`/vehicles`).then((res) => {
            if (!res.data) {
              return reject();
            }
            this.vehicles = res.data.map((p) => VehicleData.fromApi(p));
            resolve();
          });
        } catch (err) {
          console.error(err);
          reject();
        }
      });
    },

    async loadVehicleMetaData() {
      try {
        const res = await this.$axios.get(`/code-lists?ids=1,2,6,10`);
        var data = res.data;
        if (data && res.data.length) {
          let codeYears = data.filter((d) => d.Id === 1);
          let codeMakes = data.filter((d) => d.Id === 2);
          let codeInsurances = data.filter((d) => d.Id === 6);
          let codeGasCards = data.filter((d) => d.Id === 10);

          this.codeYears = codeYears.map((c) => CodeYearData.fromApi(c));
          this.codeMakes = codeMakes.map((c) => CodeMakeData.fromApi(c));
          this.codeInsurances = codeInsurances.map((c) =>
            CodeInsuranceData.fromApi(c)
          );
          this.codeGasCards = codeGasCards.map((c) =>
            CodeGasCardData.fromApi(c)
          );
        }
      } catch (err) {
        console.error(err);
      }
    },

    async loadProjects() {
      try {
        const res = await this.$axios.get(`/projects?sort_by=ProjectName`);
        if (res.data && res.data.length) {
          this.projects = res.data.map((c) => ProjectData.fromApi(c));
        }
      } catch (err) {
        console.error(err);
      }
    },

    async loadUserNames() {
      try {
        const res = await this.$axios.get(`/users`);
        if (res.data && res.data.length) {
          this.userNames = res.data.map((c) => UserNameData.fromApi(c));
          this.userNames.unshift({ id: 0, name: "(none)" });
        }
      } catch (err) {
        console.error(err);
      }
    },

    async saveData() {
      if (this.isNew) {
        return this.save();
      }
      return this.update();
    },

    async save() {
      try {
        this.setLoading(true);
        let response = await this.$axios.post(
          `/vehicles`,
          this.vehicle.toApi()
        );
        if (response.data && response.data.rows) {
          this.vehicles = response.data.rows.map((p) => VehicleData.fromApi(p));
          this.makeToast(
            "success",
            `Vehicle: ${this.vehicle.make} has been saved successfully`
          );
        }
        this.resetVehicle();

        await this.loadVehicles();
      } catch (err) {
        console.error(err);
        this.makeToast("danger", extractError(err));
      } finally {
        this.setLoading(false);
      }
    },
    async update() {
      try {
        this.setLoading(true);
        await this.$axios.put(`/vehicles`, this.vehicle.toApi());
        this.makeToast(
          "success",
          `Vehicle: ${this.vehicle.make} has been updated successfully.`
        );
        this.updateRecords(this.vehicle);
        this.resetVehicle();
      } catch (err) {
        console.error(err);
        this.makeToast("danger", extractError(err));
      } finally {
        this.setLoading(false);
      }
    },
    resetVehicle() {
      this.vehicle = new VehicleData();
    },
    updateRecords(vehicle) {
      let index = this.vehicles.findIndex((c) => c.id === vehicle.id);
      this.$set(this.vehicles, index, vehicle);
      this.resetVehicle();
    }
  }
};
</script>

<style scoped>
label {
  font-size: 1.1rem !important;
}
h2.page_title {
  font-size: 1.5rem;
  color: #72a02e;
}
</style>

export default class ContactData {
  constructor(clientId = 1, name = "", email = "") {
    this.clientId = clientId;
    this.name = name;
    this.email = email;
  }

  /**
   * Changes the object into an object ready to be consumed by the api
   */
  toApi() {
    return {
      Name: this.name,
      Email: this.email,
      ClientId: this.clientId
    };
  }

  /**
   * handles transformation from the api,
   * into the data class properties.
   * @param {object} ContactData
   */

  static fromApi(data) {
    return new ContactData(data.ClientId, data.Name, data.Email);
  }
}

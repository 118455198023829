<template>
  <div id="admin-window">
    <div class="container-sm">
      <h1>How to Edit Timesheet</h1>
      <hr />
      <div class="p-2">
        Once the timesheet is submitted, it can be modified by navigating just
        below the <b>Save</b> button. <br />
        <br />There will be a table looking like the following:
        <div class="custom_card card-1">
          <img
            class="img-fluid border"
            src="@/assets/images/docs/admin/image1_4.png"
            alt="Add Timesheet Image"
          />
        </div>
        <br />
        <br />
        <ol>
          <li>
            <strong>Quick Filter :</strong><br />You can filter the timesheet by
            using some convinent filters options avaliable already.
            <br />
            <div class="custom_card card-1">
              <img
                class="img-fluid border"
                src="@/assets/images/docs/admin/image1_4_1.png"
                alt="Add Timesheet Image"
              />
            </div>
          </li>
          <br />
          <li>
            <strong>Time Interval Filter :</strong><br />You can filter by
            selecting a time interval.
            <br />
            <div class="custom_card card-1">
              <img
                class="img-fluid border"
                src="@/assets/images/docs/admin/image1_4_2.png"
                alt="Add Timesheet Image"
              />
            </div>
            <br />
            Click on the <b>Apply</b> button to apply the filter.
          </li>
          <br />
          <li>
            <strong>Project Filter :</strong><br />
            You can filter by any Project by selecting a Project from the
            dropdown.
            <br />
            <div class="custom_card card-1">
              <img
                class="img-fluid border"
                src="@/assets/images/docs/admin/image1_4_3.png"
                alt="Add Timesheet Image"
              />
            </div>
            <br />
            <!-- <b>Tip:</b> Selecting a project for Project filter that has sub
            projects avaliable, you will be able to see the sub projects in
            Project Column.
            <br />
            <div class="custom_card card-1">
              <img
                class="img-fluid border"
                src="@/assets/images/docs/admin/image1_4_3.png"
                alt="Add Timesheet Image"
              />
            </div> -->
          </li>
          <br />
          <li>
            <strong>Editing a Timesheet entry :</strong><br />
            You can edit a timesheet entry by clicking on the
            <b>Pen Icon</b> in the <b>Actions</b> column.
            <br />
            <br />
            After clicking on the <b>Pen Icon</b>, you will be able to see a
            popup dialog box.
            <div class="custom_card card-1">
              <img
                class="img-fluid border"
                src="@/assets/images/docs/admin/image1_4_4.png"
                alt="Add Timesheet Image"
              />
            </div>
            <br />
            <br />
            Here you can make your changes and click on the <b>Save</b> button
            once you are done.
          </li>
          <br />
          <br />
          <li>
            <strong>Deleting a Timesheet entry :</strong><br />
            You can delete a timesheet entry by clicking on the
            <b>Trash Icon</b> in the <b>Actions</b> column.
            <br />
            <br />
            After clicking on the <b>Trash Icon</b>, you will be able to see a
            popup dialog box asking for confirmation.
            <div class="custom_card card-1">
              <img
                class="img-fluid border"
                src="@/assets/images/docs/admin/image1_4_5.png"
                alt="Add Timesheet Image"
              />
            </div>
            <br />
            <br />
            Once you click on the <b>Yes</b> button the timesheet entry will be
            deleted.
          </li>
          <br />
          <br />
          <li>
            <strong>Downloading Timesheet :</strong><br />
            You can export the timesheet by clicking on the
            <b>Download</b> button on the top right corner of the table.
            <br />
            <br />
            After clicking on the <b>Download</b> button, you will be able to
            see a popup dialog box.
            <div class="custom_card card-1">
              <img
                class="img-fluid border"
                src="@/assets/images/docs/admin/image1_4_6.png"
                alt="Add Timesheet Image"
              />
            </div>
            <br />
            <br />
            Once you fill in all the details for the report you need, just click
            on the <b>Download</b> button the timesheet will be downloaded.
          </li>
          <br />
          <br />
          <li>
            <b>Total Hours: </b> You can see the total hours for the timesheet
            at the end of the table. These hours are calculated by adding all
            the hours for each day that is in the filter you selected.
            <br />
            <div class="custom_card card-1">
              <img
                class="img-fluid border"
                src="@/assets/images/docs/admin/image1_4.png"
                alt="Add Timesheet Image"
              />
            </div>
            <br />
            <br />
            <b>Tip:</b> Not able See all of your timesheet hours? You might Have
            a filter on. Both Time and Project filter will affect the total
            hours.
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>

export default class ExpenseData {
  constructor(
    expenseId = -1,
    createdBy = "",
    projectId = "",
    ProjectName = "",
    expenseDate = null,
    currency = null,
    currencyRate = null,
    amount = 0,
    TtlAmount = 0,
    description = "",
    uploadedLink = null,
    comment = "",
    user = null,
    categoryId = 0,
    isApproved = false,
    ProjectCurrency = "",
    name = "",
    verified_at = null,
    isBillable = null
  ) {
    this.id = expenseId;
    this.createdBy = createdBy;
    this.expenseDate = expenseDate;
    this.projectId = projectId;
    this.projectName = ProjectName;
    this.projectCurrency = ProjectCurrency || "-";
    this.currency = currency;
    this.currencyRate = currencyRate;
    this.amount = amount;
    this.TtlAmount = TtlAmount || amount;
    this.description = description;
    this.uploadedLink = uploadedLink;
    this.comment = comment;
    this.user = user;
    this.categoryId = categoryId;
    this.isApproved = isApproved;
    this.name = name;
    this.verified_at = verified_at;
    this.isBillable = isBillable;
  }

  /**
   * Changes the object into an object ready to be consumed by the api
   */
  toApi() {
    return {
      ExpenseID: this.id,
      CreatedBy: this.createdBy,
      ProjectID: this.projectId || null,
      ProjectName: this.projectName || "-",
      ProjectCurrency: this.projectCurrency || "-",
      Category: this.categoryId,
      ExpenseDate: this.expenseDate,
      Currency: this.currency,
      CurrencyRate: this.currencyRate,
      Amount: this.amount,
      TtlAmount: this.TtlAmount || this.amount,
      Comment: this.comment || "",
      ExpenseReceiptUrl: this.uploadedLink,
      IsApproved: this.isApproved,
      name: this.name,
      verified_at: this.verified_at,
      IsBillable: this.isBillable
    };
  }

  /**
   * handles translation from the api,
   * into the data class.
   *  Id, ProjectId, ExpenseDate, Currency, CurrencyRate, Amount, Description,
                             UploadLink, Comment, CreatedUser, ModifiedDate, ModifiedUser, CategoryId As Category
   * @param {object} apiData the data object, from the api
   */
  static fromApi(data) {
    return new ExpenseData(
      data.ExpenseID,
      data.CreatedBy,
      data.ProjectID || data.ProjectId,
      data.ProjectName || "-",
      data.ExpenseDate,
      data.Currency,
      data.CurrencyRate,
      data.Amount,
      data.TtlAmount,
      data.Description,
      data.UploadLink,
      data.Comment,
      data.CreatedUser,
      data.Category,
      data.IsApproved,
      data.ProjectCurrency,
      data.name,
      data.verified_at,
      data.IsBillable
    );
  }
}

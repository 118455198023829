<template>
  <b-form @submit.prevent="saveData">
    <b-row>
      <b-col cols="12" id="formColumn">
        <b-card>
          <form ref="exp_form" class="inp_form">
            <div id="formId" class="form-row" ref="exp_form">
              <div v-if="loading" class="loading-spinner"></div>
              <div class="form-group col-12 col-sm-4 col-md-4 col-lg-2 phone">
                <label for="LeaveType">Leave Type:</label>
              </div>
              <div class="form-group col-12 col-sm-8 col-md-8 col-lg-10 phone">
                <b-form-select
                  class="bars"
                  @input="updateLeaveType"
                  id="LeaveType"
                  name="LeaveType"
                  v-model.lazy="formData.LeaveType"
                >
                  <option value="">Please Select...</option>
                  <option value="Sick">Sick</option>
                  <option value="Vacation">Vacation</option>
                  <option value="Personal">Personal</option>
                  <option value="Work From Home">Work From Home</option>
                </b-form-select>
                <div v-if="LeaveTypeColumnValidation" style="color: red">
                  Leave Type is required.
                </div>
              </div>
              <div class="form-group col-12 col-sm-4 col-md-4 col-lg-2 phone">
                <label for="FromDate">From:</label>
              </div>
              <div class="form-group col-12 col-sm-8 col-md-8 col-lg-10 phone">
                <b-form-input
                  class="bars"
                  @input="updateFromDate"
                  id="FromDate"
                  name="FromDate"
                  type="date"
                  v-model.lazy="formData.FromDate"
                ></b-form-input>
                <div v-if="FromDateColumnValidation" style="color: red">
                  From is required.
                </div>
              </div>

              <div class="form-group col-12 col-sm-4 col-md-4 col-lg-2 phone">
                <label for="ToDate">To:</label>
              </div>
              <div class="form-group col-12 col-sm-8 col-md-8 col-lg-10 phone">
                <b-form-input
                  class="bars"
                  v-model.lazy="formData.ToDate"
                  id="ToDate"
                  name="ToDate"
                  type="date"
                  @input="updateToDate"
                ></b-form-input>
                <div v-if="ToDateColumnValidation" style="color: red">
                  To is required.
                </div>
              </div>

              <div class="form-group col-12 col-sm-4 col-md-4 col-lg-2 phone">
                <label for="Notes">Notes:</label>
              </div>
              <div class="form-group col-12 col-sm-8 col-md-8 col-lg-10 phone">
                <b-form-textarea
                  style="height: 200px"
                  class="bars"
                  v-debounce:700ms="updateNotes"
                  id="Notes"
                  name="Notes"
                  v-model="formData.Notes"
                ></b-form-textarea>
                <!-- <div v-if="NotesColumnValidation" style="color: red">
                   is required.
                </div> -->
                <div v-if="NotesColumnValidation" style="color: red">
                  Notes is required.
                </div>
              </div>
            </div>
          </form>
          <div class="d-flex justify-content-end pt-4 mr-5">
            <span>
              <b-button
                type="submit"
                :class="{ 'disabled-button': saveDisabled }"
                class="admin btn btn-primary"
              >
                Submit
              </b-button>
            </span>
          </div>
        </b-card>
      </b-col>

      <!-- <div id="submitExpense">
        <b-col cols="15">
          <b-card ref="bCard">
            
          </b-card>
        </b-col>
      </div> -->
    </b-row>
  </b-form>
</template>

<script>
import Vue from "vue";
//import pdf from 'vue-pdf'
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";

// import Vuetify from 'vuetify'
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

extend("required", {
  ...required,
  message: "{_field_} must have a value"
});

// Vue.use(VCalendar, {
//   componentPrefix: "vc"
// });

export default {
  // imported components registered
  components: {
    // vImg: Vuetify.VImg,
    // CurrencyCalculator
  },

  props: {
    profile: Object
  },

  // data initialization
  data() {
    return {
      formData: {
        FromDate: null,
        ToDate: null,
        Notes: "",
        LeaveType: ""
        // ... other form data properties
      },
      FromDateColumnValidation: false,
      ToDateColumnValidation: false,
      NotesColumnValidation: false,
      LeaveTypeColumnValidation: false,
      loading: false,
      cropper: null
    };
  },

  mounted() {
    // this.getUserInfo();
    // this.getFilteredProjects();
  },

  // computed properties
  computed: {
    // Returns boolean to conditionally disable button
    saveDisabled() {
      return "";
    }
  },

  // All the methods defined for this component
  methods: {
    saveData() {
      // console.log("saveData");
      let FromDate = this.formData.FromDate;
      if (FromDate == null) {
        document.getElementById("FromDate").style.borderColor = "red";
        this.FromDateColumnValidation = true;
      }
      let ToDate = this.formData.ToDate;
      if (ToDate == null) {
        document.getElementById("ToDate").style.borderColor = "red";
        this.ToDateColumnValidation = true;
      }
      let Notes = this.formData.Notes;
      if (Notes.length == 0) {
        document.getElementById("Notes").style.borderColor = "red";
        this.NotesColumnValidation = true;
      }
      let LeaveType = this.formData.LeaveType;
      if (LeaveType.length == 0) {
        document.getElementById("LeaveType").style.borderColor = "red";
        this.LeaveTypeColumnValidation = true;
      }

      if (
        this.LeaveTypeColumnValidation == false &&
        this.NotesColumnValidation == false &&
        this.ToDateColumnValidation == false &&
        this.FromDateColumnValidation == false
      ) {
        this.$emit("onExpenseSave", this.formData);
      }
      //clear all fields
      this.formData.FromDate = null;
      this.formData.ToDate = null;
      this.formData.Notes = "";
      this.formData.LeaveType = "";
    },

    /**
     * Fires when a file is selected.
     * Selects the appropriate file and adds it to the expense form.
     * @param e the event data when a file is selected.
     */

    updateFromDate(value) {
      console.log("FromDate", value);
      this.formData.FromDate = value;
      if (value.length > 0) {
        document.getElementById("FromDate").style.borderColor = "grey";
        this.FromDateColumnValidation = false;
      }
    },

    updateToDate(value) {
      console.log("ToDate", value);
      this.formData.ToDate = value;
      if (value.length > 0) {
        document.getElementById("ToDate").style.borderColor = "grey";
        this.ToDateColumnValidation = false;
      }
    },

    updateNotes(value) {
      console.log("Notes", value);
      this.formData.Notes = value;
      if (value.length > 0) {
        document.getElementById("Notes").style.borderColor = "grey";
        this.NotesColumnValidation = false;
      }
    },

    updateLeaveType(value) {
      console.log("LeaveType", value);
      this.formData.LeaveType = value;
      if (value.length > 0) {
        document.getElementById("LeaveType").style.borderColor = "grey";
        this.LeaveTypeColumnValidation = false;
      }
    },
    updateLocal(value) {
      this.formData.localToCalgary = value;
    }
  },

  watch: {}
};
</script>

<style>
.filepond--panel[data-scalable="true"] {
  box-shadow: 0px 0px 0px 1px #495057 !important;
}
.filepond--wrapper *:hover {
  cursor: pointer;
}

.video-container {
  position: relative;
}

.video-overlay {
  position: absolute;
  bottom: -10%;
  left: 64%;
  transform: translateX(-50%);
}

#click {
  display: none;
}

.video {
  display: none;
}

#retake {
  display: none;
}

.video-container {
  display: none;
}

.canvas {
  display: none;
}

#summary {
  display: none;
}

#filepond {
  height: 80px;
  width: 200px;
  float: left;
}

#start {
  height: 70px;
  width: 100px;
}

#divStart {
  height: 70px;
  width: 100px;
  margin-left: 20px;
  border-color: #d3d3d3;
  border: none;
  background-color: white;
}

.canvas {
  border-radius: 20px;
  text-align: center;
  margin-left: 250px;
}

.video {
  border-radius: 20px;
  width: 400px;
  height: 300px;
  margin-left: 250px;
}

#manually {
  display: none;
}

/* #projectType{
    border-color: red;
  } */

#validationProject {
  display: none;
  color: red;
}
#validationCurrency {
  display: none;
  color: red;
}

#validationExpenseType {
  display: none;
  color: red;
}
.loading-spinner {
  /* styles for the loading spinner */
  display: none;
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 70%;
  left: 65%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* #buttonForSubmit {
  display: none;
}

#buttonForSubmit2 {
  display: none;
} */
#formColumn {
  margin-left: -40px;
}

.img {
  width: 380px;
  height: 580px;
  border-radius: 20px;
  margin-top: 40px;
}

#cancelCamera {
  display: none;
}

#helpImage {
  height: 30px;
  width: 30px;
  margin-left: 10%;
  margin-top: 2%;
}

#submitExpense {
  margin-left: 20%;
}

@media only screen and (max-width: 800px) {
  .phone {
    margin-left: 10vw;
    display: none;
    float: left;
  }

  #phoneUpload {
    margin-left: 22%;
  }

  .phone2 {
    margin-left: 10vw;
    float: left;
  }

  .img {
    display: none;
  }
  #formColumn {
    margin-left: none;
  }

  #cancelCamera {
    display: none;
  }

  .bars {
    width: 170%;
  }

  .expenseDate {
    margin-top: 20%;
  }

  .calendar {
    width: 170%;
  }

  #manually {
    display: block;
    margin-top: 80px;
    margin-left: 31vw;
  }

  .label {
    display: none;
  }

  .short {
    height: 50px;
    width: 5px;
  }

  #divStart {
    display: none;
  }

  #submitExpense {
    /* display: none;
    height: 400px; */
    width: 1000px;
    height: 0;
  }

  #filepond {
    height: 80px;
    width: 80vw;
    margin-left: 7.5vw;
    float: left;
  }

  #helpImage {
    height: 1vh;
    width: 10vw;
    margin-left: 0;
    margin-top: 0;
    display: none;
  }

  #summary {
    display: block;
  }

  .loading-spinner {
    /* styles for the loading spinner */
    display: block;
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 220%;
    left: 85%;
    transform: translate(-50%, -50%);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

/* #formId{
    display: none;
  } */
</style>

<template>
  <div id="admin-window">
    <b-container>

      <div>
        <b-card header='' title="Add New Expense">
          Coming Soon
        </b-card>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
</style>

<template>
  <div>
    <div class="spinner-wrapper position-fixed" v-if="loading">
      <b-card>
        <div class="spinner-border spinner-circle" role="status"></div>
      </b-card>
    </div>

    <div id="app wrapper" class="d-flex" v-if="currentRouteName !== 'home'">
      <!-- Sidebar component -->
      <SideBar />
      <div id="page-content-wrapper" :key="shouldRefresh">
        <!-- Navbar component -->
        <NavBar :current-user="currentUser" @logOut="_logOut" />
        <!-- Fading effect when router-view changes -->
        <!-- <transition name="slide-fade" mode="out-in" v-if="!loading"> -->
        <router-view @fetchUser="logIn" :loading="loading" />
        <!-- </transition> -->
      </div>
    </div>

    <div id="app wrapper" class="d-flex" v-else>
      <div id="page-content-wrapper" :key="shouldRefresh">
        <NewNavBar :currentUser="currentUser" @logOut="_logOut" />
        <router-view @fetchUser="logIn" :loading="loading" />
      </div>
    </div>
  </div>
</template>

<script>
// External libraries and components imported
import SideBar from "@/components/SideBar.vue";
import NavBar from "@/components/NavBar.vue";
import NewNavBar from "@/components/NewNavBar.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  // imported components registered
  components: {
    SideBar,
    NavBar,
    NewNavBar
  },
  // data initialization
  data() {
    return {
      loginObj: null,
      shouldRefresh: false
    };
  },
  // computed properties
  computed: {
    ...mapGetters({
      currentUser: "getLoggedInUser",
      scopes: "getScopes",
      loading: "loading",
      force_update: "force_update"
    }),

    currentRouteName() {
      return this.$route.name;
    }
  },
  // All the methods defined for this component
  methods: {
    // Logging out through vuex
    ...mapMutations(["logOut"]),
    ...mapActions({
      storeCredentials: "storeCredentials",
      setMessage: "setMessage"
    }),

    // Clicking on logout will trigger following function
    _logOut() {
      this.setLoading(true);
      this.$axios.get("/logout");
      this.logOut();
    },

    // This function gets called for showing alert through out the project
    makeToast(variant = null, message = null, title = "Notification") {
      this.$bvToast.toast(message, {
        title,
        variant: variant,
        solid: true
      });
    },

    /*  This watcher function watches for message, type properties of vuex
      and gets called when their value get change.
      This will further call makeToast to show alert.
    */
    watchForMessages() {
      this.$store.watch((state) => {
        const msg = this.$store.getters["message"];
        const type = this.$store.getters["type"];
        if (msg && msg.length) {
          this.makeToast(type, msg);
          this.setMessage(null);
        }
      });
    },

    /*  This function will trigger when user clicks Login button */
    async logIn() {
      this.setLoading(true);
      try {
        const response = await this.loginObj.loginPopup({
          scopes: this.scopes
        });
        // Once msal logs user in, we need to store the account info. in the localStorage.
        // storeCredentials() will also check the user permission through api
        await this.storeCredentials(response);
      } catch (err) {
        console.error(err);
      } finally {
        this.setLoading(false);
      }
    },
    initObject() {
      // loginObj gets configured from auth.js
      this.loginObj = this.$store.state.auth.msalConfig;
      //   Clearing intraction in progress
      sessionStorage.removeItem("msal.interaction.status");
      document.cookie = `msal.interaction.status=; Max-Age=0`;
    }
  },
  created() {
    this.initObject();
  },
  mounted() {
    this.watchForMessages();
  },
  watch: {
    force_update(_new, _old) {
      this.shouldRefresh = _new;
    }
  }
};
</script>

<style>
.slide-fade-enter {
  transform: translateX(30px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-to {
  transform: translateX(-30px);
  opacity: 0;
}
.spinner-wrapper .card {
  background-color: rgba(0, 0, 0, 0.25);
}
</style>

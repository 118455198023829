export default class CodeCommonData{

    constructor(
      id = null,
      subId = "",
      title = "",
      desc = ""
    )
    {
      this.id = id;
      this.subId = subId;
      this.title = title;
      this.desc = desc;
    }
  
    static fromApi(data){
        return new CodeCommonData(
          data.Id,
          data.SubId,
          data.Title,
          data.Desc
        )
    }
  
    toApi(){
      return{
        Id: this.id,
        SubId: this.subId,
        Title: this.title,
        Desc: this.desc
      }
    }
  
  }
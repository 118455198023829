export default class TimeSheetData {
  constructor(
    id = -1,
    activityDate = null,
    activity = null,
    activityTime = null,
    projectId = "",
    subProjectId = "",
    isApproved = false,
    user = null,
    projectName = "",
    activityComment = "",
    createdBy = "",
    name = "",
    verified_at = null
  ) {
    this.id = id;
    this.activityDate = activityDate;
    this.activity = activity;
    this.activityTime = activityTime;
    this.projectId = projectId;
    this.subProjectId = subProjectId;
    this.isApproved = isApproved;
    this.user = user;
    this.projectName = projectName || "";
    this.activityComment = activityComment;
    this.createdBy = createdBy;
    this.name = name;
    this.verified_at = verified_at;
  }

  /**
   * Changes the object into an object ready to be consumed by the api
   */
  toApi() {
    if (typeof this.activityDate === "string") {
      const dateTokens = this.activityDate.split("-");
      this.activityDate = new Date(
        parseInt(dateTokens[0]),
        parseInt(dateTokens[1]) - 1,
        parseInt(dateTokens[2])
      );
    }

    return {
      Id: this.id,
      InvoiceID: 0,
      ActivityDate: this.activityDate,
      Activity: this.activity,
      ActivityTime: parseInt(this.activityTime),
      ProjectID: this.projectId,
      SubProjectID: this.subProjectId,
      IsApproved: this.isApproved,
      ActivityComment: this.activityComment,
      ModifiedDate: null,
      User: this.user,
      ProjectName: this.projectName,
      createdBy: this.createdBy,
      name: this.name,
      verified_at: this.verified_at
    };
  }

  /**
   * handles transformation from the api,
   * into the data class properties.
   * @param {object} TimeSheetData
   */
  static fromApi(data) {
    return new TimeSheetData(
      data.id,
      data.ActivityDate,
      data.Activity.trim(),
      data.ActivityTime,
      data.ProjectID,
      data.SubProjectID,
      data.IsApproved,
      data.CreatedUser,
      data.ProjectName || "",
      data.ActivityComment,
      data.createdBy,
      data.name,
      data.verified_at
    );
  }
}

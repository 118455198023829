// state
const state = {
  message: null,
  type: "red"
};

// getters
const getters = {
  message: state => state.message,
  type: state => state.type
};

// mutations
const mutations = {
  SET_MESSAGE(state, payload) {
    state.message = payload.message;
    state.type = payload.type;
  }
};

// actions
const actions = {
  // Setting message state for globally initiating alert
  setMessage({ commit }, payload) {
    let type = "danger";
    let message = payload;
    if (payload && typeof payload === "object") {
      message = payload.message;
      type = payload.type;
    }
    commit("SET_MESSAGE", {
      message,
      type
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

export default class StaffData {
  constructor(
    id = null,
    userName = "",
    isAdmin = "",
    isActive = "",
    needReceipt = "",
    name = "",
    toaValue = "",
    toa = "",
    swo = "",
    stateOfWork = "",
    jobTitle = "",
    invoiceNo = "",
    invoiceId = "",
    first_name = null,
    middle_name = null,
    last_name = null,
    designation = null,
    home_phone = null,
    mobile_phone = null,
    personal_email = null,
    social_number = null,
    business_name = null,
    address = null,
    city = null,
    state = null,
    postal_code = null,
    dob = null,
    driving_license_number = null,
    driving_license_state = null,
    bank_name = null,
    routing_number = null,
    account_number = null,
    swift_code = null,
    hotel = null,
    emailCreated = false,
    personalFolderCreated = false,
    onboardingFormReceived = false,
    welcomeSession = false,
    bankingInfoCreated = false,
    test100Sent = false,
    businessCardsOrdered = false,
    BookGiven = false,
    cordOrganizerGiven = false,
    journalGiven = false,
    bioPicUploaded = false,
    visaInfoUploaded = false,
    erpTrainingCompleted = false,
    companyShirts = false,
    esetInstalled = false,
    includedInInsightAwpStaffGroup = false
  ) {
    this.id = id;
    this.userName = userName;
    this.isAdmin = isAdmin;
    this.isActive = isActive;
    this.needReceipt = needReceipt;
    this.name = name;
    this.toaValue = toaValue;
    this.toa = toa;
    this.swo = swo;
    this.stateOfWork = stateOfWork;
    this.jobTitle = jobTitle;
    this.invoiceNo = invoiceNo;
    this.invoiceId = invoiceId;
    this.first_name = first_name;
    this.middle_name = middle_name;
    this.last_name = last_name;
    this.designation = designation;
    this.home_phone = home_phone;
    this.mobile_phone = mobile_phone;
    this.personal_email = personal_email;
    this.social_number = social_number;
    this.business_name = business_name;
    this.address = address;
    this.city = city;
    this.state = state;
    this.postal_code = postal_code;
    this.dob = dob && dob.includes("T") ? dob.split("T")[0] : null;
    this.driving_license_number = driving_license_number;
    this.driving_license_state = driving_license_state;
    this.bank_name = bank_name;
    this.routing_number = routing_number;
    this.account_number = account_number;
    this.swift_code = swift_code;
    this.hotel = hotel;
    this.emailCreated = emailCreated;
    this.personalFolderCreated = personalFolderCreated;
    this.onboardingFormReceived = onboardingFormReceived;
    this.welcomeSession = welcomeSession;
    this.bankingInfoCreated = bankingInfoCreated;
    this.test100Sent = test100Sent;
    this.businessCardsOrdered = businessCardsOrdered;
    this.BookGiven = BookGiven;
    this.cordOrganizerGiven = cordOrganizerGiven;
    this.journalGiven = journalGiven;
    this.bioPicUploaded = bioPicUploaded;
    this.visaInfoUploaded = visaInfoUploaded;
    this.erpTrainingCompleted = erpTrainingCompleted;
    this.companyShirts = companyShirts;
    this.esetInstalled = esetInstalled;
    this.includedInInsightAwpStaffGroup = includedInInsightAwpStaffGroup;
  }

  static fromApi(data) {
    return new StaffData(
      data.id,
      data.UserName,
      data.IsAdmin,
      data.IsActive,
      data.NeedReceipt,
      data.Name,
      data.ToaValue,
      data.Toa,
      data.Swo,
      data.StateOfWork,
      data.JobTitle,
      data.InvoiceNo,
      data.InvoiceId,
      data.first_name,
      data.middle_name,
      data.last_name,
      data.designation,
      data.home_phone,
      data.mobile_phone,
      data.personal_email,
      data.social_number,
      data.business_name,
      data.address,
      data.city,
      data.state,
      data.postal_code,
      data.dob,
      data.driving_license_number,
      data.driving_license_state,
      data.bank_name,
      data.routing_number,
      data.account_number,
      data.swift_code,
      data.hotel,
      data.emailCreated,
      data.personalFolderCreated,
      data.onboardingFormReceived,
      data.welcomeSession,
      data.bankingInfoCreated,
      data.test100Sent,
      data.businessCardsOrdered,
      data.BookGiven,
      data.cordOrganizerGiven,
      data.journalGiven,
      data.bioPicUploaded,
      data.visaInfoUploaded,
      data.erpTrainingCompleted,
      data.companyShirts,
      data.esetInstalled,
      data.includedInInsightAwpStaffGroup
    );
  }

  toApi() {
    return {
      id: this.id,
      UserName: this.userName,
      IsAdmin: this.isAdmin,
      IsActive: this.isActive,
      NeedReceipt: this.needReceipt,
      Name: this.name,
      ToaValue: this.toaValue,
      Toa: this.toa,
      Swo: this.swo,
      StateOfWork: this.stateOfWork,
      JobTitle: this.jobTitle,
      InvoiceNo: this.invoiceNo,
      InvoiceId: this.invoiceId,
      first_name: this.first_name,
      middle_name: this.middle_name,
      last_name: this.last_name,
      designation: this.designation,
      home_phone: this.home_phone,
      mobile_phone: this.mobile_phone,
      personal_email: this.personal_email,
      social_number: this.social_number,
      business_name: this.business_name,
      address: this.address,
      city: this.city,
      state: this.state,
      postal_code: this.postal_code,
      dob: this.dob,
      driving_license_number: this.driving_license_number,
      driving_license_state: this.driving_license_state,
      bank_name: this.bank_name,
      routing_number: this.routing_number,
      account_number: this.account_number,
      swift_code: this.swift_code,
      hotel: this.hotel,
      emailCreated: this.emailCreated,
      personalFolderCreated: this.personalFolderCreated,
      onboardingFormReceived: this.onboardingFormReceived,
      welcomeSession: this.welcomeSession,
      bankingInfoCreated: this.bankingInfoCreated,
      test100Sent: this.test100Sent,
      businessCardsOrdered: this.businessCardsOrdered,
      BookGiven: this.BookGiven,
      cordOrganizerGiven: this.cordOrganizerGiven,
      journalGiven: this.journalGiven,
      bioPicUploaded: this.bioPicUploaded,
      visaInfoUploaded: this.visaInfoUploaded,
      erpTrainingCompleted: this.erpTrainingCompleted,
      companyShirts: this.companyShirts,
      esetInstalled: this.esetInstalled,
      includedInInsightAwpStaffGroup: this.includedInInsightAwpStaffGroup
    };
  }
}

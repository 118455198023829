<template>
  <div id="admin-window">
    <b-container>
      <div>
        <b-card header="" title="Select Client to Edit">
          <b-row>
            <b-col>
              <b-button @click="newClient" class="clients admin"
                >New Client</b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <nav class="editNav" @change="populateFields($event)">
                <b-button
                  v-for="client in clients"
                  :key="client.id"
                  :value="client.id"
                  class="clients admin"
                  @click="populateFields($event)"
                  >{{ client.clientName }}</b-button
                >
              </nav>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <!-- Client Edit -->
      <transition name="slide">
        <b-row>
          <b-col>
            <b-card>
              <h3 v-if="selectedClient.ClientName">
                Edit Details for {{ selectedClient.clientName }}
              </h3>

              <b-form>
                <!-- Passing ClientID -->
                <input
                  id="ClientID"
                  v-model="selectedClient.id"
                  type="hidden"
                />
                <input
                  id="ClientName"
                  v-model="selectedClient.clientName"
                  type="hidden"
                />

                <div class="form-row">
                  <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                    <label
                      :for="selectedClient.clientName"
                      style="padding-top: 0.375rem"
                      >Client Name:
                    </label>
                  </div>
                  <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                    <b-form-input
                      id="ClientName"
                      v-model="selectedClient.clientName"
                      type="text"
                      class="form-control"
                      placeholder="Client Name"
                    ></b-form-input>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                    <label :for="selectedClient.addressLine1"
                      >Address 1:
                    </label>
                  </div>
                  <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                    <b-form-input
                      id="AddressLine1"
                      v-model="selectedClient.addressLine1"
                      type="text"
                      class="form-control"
                      name="AddressLine1"
                      placeholder="123 here"
                    ></b-form-input>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                    <label :for="selectedClient.addressLine2"
                      >Address 2:
                    </label>
                  </div>
                  <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                    <b-form-input
                      id="AddressLine2"
                      v-model="selectedClient.addressLine2"
                      type="text"
                      class="form-control"
                      name="AddressLine2"
                      placeholder="apt #1"
                    ></b-form-input>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                    <label :for="selectedClient.city">City:</label>
                  </div>
                  <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                    <b-form-input
                      id="City"
                      v-model="selectedClient.city"
                      type="text"
                      class="form-control"
                      name="City"
                      placeholder="Client City"
                    ></b-form-input>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                    <label :for="selectedClient.state">State:</label>
                  </div>
                  <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                    <b-form-input
                      id="StateProvince"
                      v-model="selectedClient.state"
                      type="text"
                      class="form-control"
                      name="StateProvince"
                      placeholder="TX"
                    ></b-form-input>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                    <label for="ZipPostalCode">Zip:</label>
                  </div>
                  <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                    <b-form-input
                      id="ZipPostalCode"
                      v-model="selectedClient.postalCode"
                      type="text"
                      class="form-control"
                      name="ZipPostalCode"
                      placeholder="90210"
                    ></b-form-input>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                    <label :for="selectedClient.Phone">Phone:</label>
                  </div>
                  <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                    <b-form-input
                      id="Phone"
                      v-model="selectedClient.phone"
                      type="tel"
                      class="form-control"
                      name="Phone"
                      placeholder="555-888-8888"
                    ></b-form-input>
                  </div>
                </div>
              </b-form>
            </b-card>
            <b-card title="Contacts">
              <b-row>
                <b-col>
                  <h2>Name</h2>
                </b-col>
                <b-col>
                  <h2>Email</h2>
                </b-col>
                <b-col> </b-col>
              </b-row>

              <b-row
                :key="contact.id"
                v-for="contact in selectedClient.contacts"
              >
                <b-col>
                  <b-form-input
                    v-model="contact.name"
                    type="text"
                    class="row-control form-control"
                    name="ContactName1"
                    placeholder="John Smith"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <b-form-input
                    v-model="contact.email"
                    type="text"
                    class="row-control form-control"
                    name="ContactName1"
                    placeholder="john@example.com"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="img-button bi bi-x-square"
                    fill="#72a02e"
                    xmlns="http://www.w3.org/2000/svg"
                    v-on:click="() => deleteContact(contact)"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </b-col>
              </b-row>
              <div class="form-group col-sm-3"></div>
              <div class="form-group col-sm-9 text-left">
                <button class="btn btn-primary" @click="addNewContact">
                  <i class="fas fa-plus"></i> Add New CC
                </button>
              </div>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <p>{{ selectedClient.ClientName }}</p>
              <p>
                {{ selectedClient.AddressLine1 }}<br /><span
                  v-if="selectedClient.addressLine2"
                >
                  {{ selectedClient.addressLine2 }}<br /></span
                >{{ selectedClient.city }}, {{ selectedClient.state }}<br />{{
                  selectedClient.postalCode
                }}
              </p>
              <p>{{ selectedClient.Phone }}</p>

              <span class="float-right">
                <b-button
                  type="submit"
                  class="admin-btn btn btn-primary float-right"
                  v-on:click="saveData()"
                  >Save Client</b-button
                >
              </span>
            </b-card>
          </b-col>
        </b-row>
      </transition>
    </b-container>
  </div>
</template>

<script>
// External libraries and components imported
import ClientData from "../core/ClientData";
import ContactData from "../core/ContactData";
import { extractError } from "../utilities/ErrorUtilities";

export default {
  // data initialization
  data() {
    return {
      clients: null,
      selectedClient: {},
      isSaving: false,
      showContactDeletePopup: false,
      contactToDelete: null,
      loadFailed: false
    };
  },

  created: function () {
    this.loadData();
  },

  // computed properties
  computed: {
    contactDeleteMessage() {
      return "Contact Delete";
    }
  },

  // All the methods defined for this component
  methods: {
    showContactDeleteConfirmBox() {
      this.showContactDeletePopup = true;
    },

    deleteContact(contact) {
      if (this.selectedClient === null || this.selectedClient.contacts === null)
        return;

      const contactIndex = this.selectedClient.contacts.findIndex(
        (c) => c.name === contact.name
      );
      if (contactIndex >= 0) {
        this.selectedClient.contacts.splice(contactIndex, 1);
      }
    },

    async loadData() {
      try {
        const clientsResponse = await this.$axios.get(`/clients`);
        this.clients = clientsResponse.data.map((c) => ClientData.fromApi(c));
      } catch (err) {
        this.loadFailed = true;
        this.makeToast("danger", extractError(err));
      }
    },

    addNewContact() {
      this.selectedClient.contacts.push(new ContactData());
    },

    newClient() {
      this.selectedClient = new ClientData();
    },

    async saveData() {
      try {
        this.setLoading(true);
        const clientToSend = this.selectedClient.toApi();
        const client = await this.$axios.post(`/clients`, clientToSend);
        this.selectedClient = ClientData.fromApi(client.data);
        this.makeToast(
          `success`,
          `Client ${this.selectedClient.clientName} Saved!`
        );
      } catch (err) {
        this.makeToast("danger", extractError(err));
      } finally {
        this.setLoading(false);
      }
    },

    // populate client data once one is selected
    populateFields: function (event) {
      const { value } = event.target;

      if (value == "") {
        return;
      }
      const selectedClient = this.clients.find(
        (client) => client.id == event.target.value
      );

      if (selectedClient !== null) {
        this.selectedClient = selectedClient;
      }
    }
  }
};
</script>
<template>
  <div class="popup-container">
    <div class="popup-window">
      <b-card class="popup-card" border-variant="danger"
          header-border-variant="danger"
          header-text-variant="danger" :header="title">
        <b-card-text>
          {{ message }}
        </b-card-text>

        <template #footer>
          <b-row style="padding-bottom: 5px">
            <b-col>
              <button class="btn admin btn-primary" @click="$emit('close', true)">
                Yes
              </button>
            </b-col>
            <b-col>
              <button
                class="btn admin btn-primary"
                @click="$emit('close', false)"
              >
                No
              </button>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Delete"
    },
    message: String
  }
};
</script>

<style scoped>
.card {
  margin: 0;
}
.popup-container {
  transition: opacity .15s linear;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background: #00000059;
  z-index: 99;
}
.popup-window {
  box-shadow: 0px 0px 7px 0px #00000045;
}
</style>

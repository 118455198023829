<template>
  <div id="admin-window">
    
    <b-container>
      <div>
        <b-card header="" title="Add New Client"> </b-card>
      </div>
      <b-form @submit.prevent="saveData">
        <b-row>
          <b-col>
            <b-card>
              <!-- Passing ClientID -->
              <!-- <input id="ClientID" v-model="selectedClient.ClientID" type="hidden"> -->
              <div class="form-row">
                <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                  <label :for="ClientName" style="padding-top: 0.375rem"
                    >Client Name:
                  </label>
                </div>
                <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                  <b-form-input
                    id="ClientName"
                    v-model="selectedClient.ClientName"
                    type="text"
                    class="form-control"
                    placeholder="Client Name"
                  ></b-form-input>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                  <label :for="AddressLine1">Address 1: </label>
                </div>
                <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                  <b-form-input
                    id="AddressLine1"
                    v-model="selectedClient.AddressLine1"
                    type="text"
                    class="form-control"
                    name="AddressLine1"
                    placeholder="123 here"
                  ></b-form-input>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                  <label :for="AddressLine2">Address 2: </label>
                </div>
                <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                  <b-form-input
                    id="AddressLine2"
                    v-model="selectedClient.AddressLine2"
                    type="text"
                    class="form-control"
                    name="AddressLine2"
                    placeholder="apt #1"
                  ></b-form-input>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                  <label :for="City">City:</label>
                </div>
                <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                  <b-form-input
                    id="City"
                    v-model="selectedClient.City"
                    type="text"
                    class="form-control"
                    name="City"
                    placeholder="Client City"
                  ></b-form-input>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                  <label :for="StateProvince">State:</label>
                </div>
                <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                  <b-form-input
                    id="StateProvince"
                    v-model="selectedClient.StateProvince"
                    type="text"
                    class="form-control"
                    name="StateProvince"
                    placeholder="TX"
                  ></b-form-input>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                  <label for="ZipPostalCode">Zip:</label>
                </div>
                <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                  <b-form-input
                    id="ZipPostalCode"
                    v-model="selectedClient.ZipPostalCode"
                    type="text"
                    class="form-control"
                    name="ZipPostalCode"
                    placeholder="90210"
                  ></b-form-input>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                  <label :for="Phone">Phone:</label>
                </div>
                <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                  <b-form-input
                    id="Phone"
                    v-model="selectedClient.Phone"
                    type="tel"
                    class="form-control"
                    name="Phone"
                    placeholder="555-888-8888"
                  ></b-form-input>
                </div>
              </div>
              <div class="form-row">
                <p>Add Client Contacts</p>
              </div>
              <div class="form-row">
                <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                  <label :for="ContactName1">Name:</label>
                </div>
                <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                  <b-form-input
                    id="ContactName1"
                    v-model="selectedClient.ContactName1"
                    type="text"
                    class="form-control"
                    name="ContactName1"
                    placeholder="john@example.com"
                  >
                  </b-form-input>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                  <label :for="ContactEmail1">Email:</label>
                </div>
                <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                  <b-form-input
                    id="ContactEmail1"
                    v-model="selectedClient.ContactEmail1"
                    type="text"
                    class="form-control"
                    name="ContactEmail1"
                    placeholder="john@example.com"
                  >
                  </b-form-input>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                  <label :for="ContactName2">Name 2:</label>
                </div>
                <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                  <b-form-input
                    id="ContactName2"
                    v-model="selectedClient.ContactName2"
                    type="text"
                    class="form-control"
                    name="ContactName2"
                    placeholder="john@example.com"
                  >
                  </b-form-input>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-12 col-sm-4 col-md-3 col-lg-3">
                  <label :for="ContactEmail2">Email 2:</label>
                </div>
                <div class="form-group col-12 col-sm-8 col-md-9 col-lg-9">
                  <b-form-input
                    id="ContactEmail2"
                    v-model="selectedClient.ContactEmail2"
                    type="text"
                    class="form-control"
                    name="ContactEmail2"
                    placeholder="john@example.com"
                  >
                  </b-form-input>
                </div>
              </div>

              <div class="form-group col-sm-3"></div>
              <div class="form-group col-sm-9 text-left">
                <button class="btn btn-primary">
                  <i class="fas fa-plus"></i> Add New CC
                </button>
              </div>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <h3 v-if="selectedClient.ClientName">
                {{ selectedClient.ClientName }}
              </h3>
              <p>
                {{ selectedClient.AddressLine1 }}<br /><span
                  v-if="selectedClient.AddressLine2"
                >
                  {{ selectedClient.AddressLine2 }}<br /></span
                >{{ selectedClient.City
                }}<span v-if="selectedClient.City">,</span>
                {{ selectedClient.StateProvince }}<br />{{
                  selectedClient.ZipPostalCode
                }}
              </p>
              <p>{{ selectedClient.Phone }}</p>
              <span class="float-right">
                <b-button
                  type="submit"
                  class="admin-btn btn btn-primary float-right"
                  >Save Client</b-button
                >
              </span>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </div>
</template>

<script>
export default {
  // data initialization
  data() {
    return {
      selectedClient: {}
    };
  },
  created: function() {
    this.loadData();
  },
  // All the methods defined for this component
  methods: {
    loadData() {}
  }
};
</script>

<template>
  <div id="admin-window">
    <b-container fluid="sm">
      <b-row>
        <b-col><h1 class="buttonpad">Verify Timesheet & Expense</h1></b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="col-4">
          <b-form-group id="fieldset1" label="Employee:" label-for="user">
            <b-form-input
              :value="(authedUser && authedUser.Name) || ''"
              class="form-control add_purchaseRequisition"
              placeholder="Choose Name"
              :disabled="true"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="col-4">
          <b-form-group
            id="fieldset2"
            label=" Select Date Range:"
            label-for="selectedPeriod"
          >
            <b-select
              id="fieldPeriod"
              v-model="filter.date_range"
              class="control-no-left-padding form-control"
            >
              <!-- <option :value="null" selected>Please choose...</option> -->
              <option
                :key="index"
                v-for="(period, index) in periods"
                :value="period.key"
              >
                {{ period.value }}
              </option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col class="col-4">
          <b-form-group
            id="fieldset3"
            label=" Select Project:"
            label-for="selectedProject"
          >
            <b-select
              id="fieldProject"
              v-model="filter.project"
              class="control-no-left-padding form-control"
            >
              <!-- <option :value="null" selected>Please choose ...</option> -->
              <option
                :key="index"
                v-for="(project, index) in projects"
                :value="project.ProjectID"
              >
                {{ project.ProjectName }}
              </option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>

      <div v-if="filter.date_range">
        <b-row>
          <b-col cols="6"> </b-col>
        </b-row>

        <b-card>
          <b-row class="align-items-center">
            <b-col class="p-0"><h1>Contractor's Timesheet</h1></b-col>
            <b-col v-if="filteredTimesheets.length" class="align-right p-0">
              <b-button @click="verifyTimesheets" variant="success"
                >Verify Timesheets</b-button
              >
            </b-col>
          </b-row>

          <b-row class="my-2" style="height: 300px; overflow: auto">
            <b-col cols="12">
              <b-row class="header-data">
                <b-col cols="2">
                  <h2>Date</h2>
                </b-col>
                <b-col cols="3"><h2>Project</h2></b-col>
                <b-col cols="1">
                  <h2>Hrs</h2>
                </b-col>

                <b-col cols="4"><h2>Activity Comment</h2></b-col>
                <b-col cols="2"><h2>Edit</h2></b-col>
              </b-row>
              <b-row
                :key="index"
                v-for="(timesheet, index) in filteredTimesheets"
                class="data-table-row"
              >
                <b-col class="text-center" cols="2">
                  {{ formatDate(timesheet.activityDate) }}
                </b-col>
                <b-col class="text-center" cols="3">
                  {{ timesheet.projectName }}
                </b-col>
                <b-col class="text-center" cols="1">
                  {{ timesheet.activityTime }}
                </b-col>
                <b-col class="text-center" cols="4">
                  {{ timesheet.activityComment }}
                </b-col>
                <b-col class="text-center" cols="2">
                  <i
                    v-if="!timesheet.verified_at"
                    title="Edit"
                    @click="editTime(timesheet)"
                    class="fa fa-pencil text-dark"
                  ></i>
                  <span v-else>Verified</span>
                </b-col>
              </b-row>
              <b-row class="footer-data">
                <b-col cols="4">
                  <h2>Total Contractor Time</h2>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col class="text-center" cols="1">
                  <h2>{{ timesheetTotalHrs }}</h2>
                </b-col>
                <b-col cols="3"></b-col>
                <b-col cols="2"></b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <div class="spacer"></div>

        <b-card>
          <b-row class="align-items-center">
            <b-col class="p-0"><h1>Contractor's Expenses</h1></b-col>
            <b-col v-if="filteredExpenses.length" class="align-right p-0">
              <b-button @click="verifyExpenses" variant="success" class="mx-2"
                >Verify Expenses</b-button
              >
              <b-button
                @click="downloadExpenses()"
                variant="primary"
                class="mx-2"
                >Download Attachments</b-button
              >
            </b-col>
          </b-row>

          <b-row class="my-2" style="height: 300px; overflow: auto">
            <b-col cols="12">
              <!-- Table Header-->
              <b-row class="header-data">
                <b-col cols="2"><h2>Date</h2></b-col>
                <b-col cols="3"><h2>Project</h2></b-col>
                <b-col cols="1"><h2 class="text-right">Amount</h2></b-col>
                <b-col cols="2"><h2>File</h2></b-col>
                <b-col cols="2"><h2>Comments</h2></b-col>
                <b-col cols="2"><h2>Edit</h2></b-col>
              </b-row>
              <!-- Table Data-->
              <b-row
                :key="index"
                v-for="(expense, index) in filteredExpenses"
                class="data-table-row"
                id="sortedItems"
              >
                <b-col class="text-center" cols="2">
                  {{ formatDate(expense.expenseDate) }}
                </b-col>
                <b-col class="text-center" cols="3">
                  {{ expense.projectName }}
                </b-col>
                <b-col class="text-right" cols="1">
                  ${{ formatPrice(expense.amount && expense.amount) }}
                </b-col>
                <b-col class="text-center" cols="2">
                  <span v-if="expense.uploadedLink">Attached</span>
                  <span v-else>-</span>
                </b-col>
                <b-col class="text-center" cols="2">
                  <div v-if="expense.comment">
                    <i
                      :id="`popover-1-${expense.id}`"
                      class="fa fa-comment-alt"
                    ></i>

                    <b-popover
                      :target="`popover-1-${expense.id}`"
                      title="Comment"
                      triggers="hover focus"
                      :content="`${expense.comment}`"
                    ></b-popover>
                  </div>
                  <span v-else> - </span>
                </b-col>
                <b-col class="text-center" cols="2">
                  <i
                    v-if="!expense.verified_at"
                    title="Edit"
                    class="fa fa-pencil text-dark"
                    @click="editExpense(expense)"
                  ></i>
                  <span v-else>Verified</span>
                </b-col>
              </b-row>
              <b-row class="footer-data">
                <b-col><h2>Total Expenses</h2></b-col>
                <b-col></b-col>
                <b-col class="text-right">
                  <h2>$ {{ formatPrice(expenseTotal) }}</h2>
                </b-col>
                <b-col></b-col>
                <b-col></b-col>
                <b-col></b-col>
                <b-col sm="1"></b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <hr />

      <div
        class="d-flex preview-wrapper"
        v-if="this.attachments && this.attachments.length"
      ></div>

      <div class="d-flex justify-content-between">
        <b-button
          @click="ShowAttachedFiles()"
          variant="info"
          v-if="!attachments || !attachments.length"
          >Show Files</b-button
        >
        <b-button
          @click="removeFiles()"
          variant="info"
          v-if="attachments && attachments.length"
          >Hide Files</b-button
        >

        <!-- <div>
        <b-button variant="info">Hide Files</b-button>
        </div> -->

        <b-button
          href="https://app.powerbi.com/groups/f3a21ed2-f377-4e4c-9c9f-30822b18936c/rdlreports/0a13515b-edce-4c5f-aef8-81531ce31294"
          target="_blank"
          variant="primary"
          >Review</b-button
        >
      </div>
    </b-container>

    <div>
      <b-modal
        hide-footer
        centered
        title="Edit Timesheet"
        id="edit-time"
        body-class="p-0"
      >
        <div class="d-block text-center p-3">
          <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left">Project:</span>
              <select class="form-control" v-model="selectedRecord.projectId">
                <option
                  :key="index"
                  v-for="(project, index) in projects"
                  :value="project.ProjectID"
                >
                  {{ project.ProjectName }}
                </option>
              </select>
            </div>
          </div>
          <span class="float-left">Hours:</span>
          <b-form-input
            v-model="selectedRecord.activityTime"
            type="number"
            step="0.1"
            placeholder="Add Hours"
          ></b-form-input>
          <br />
          <span class="float-left">activity Comment:</span>
          <b-form-input
            v-model="selectedRecord.activityComment"
            type="text"
          ></b-form-input>
        </div>
        <hr class="m-0" />
        <div class="p-3">
          <b-button variant="outline-danger" @click="$bvModal.hide('edit-time')"
            >Close</b-button
          >
          <b-button class="ml-3" variant="outline-primary" @click="updateTime"
            >Save Changes</b-button
          >
        </div>
      </b-modal>
      <b-modal
        hide-footer
        centered
        title="Edit Expense"
        id="edit-expense"
        body-class="p-0"
      >
        <div class="d-block text-center p-3">
          <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left"
                >Amount ({{ (selectedRecord.currency || "").trim() }})</span
              >
              <b-form-input
                v-model="selectedRecord.amount"
                type="number"
                step="0.01"
                placeholder="Add Hours"
              ></b-form-input>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left"
                >Currency rate: {{ selectedRecord.currencyRate }}</span
              >
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left"
                >Expense Claimed:
                {{
                  formatPrice(
                    selectedRecord.amount * selectedRecord.currencyRate
                  )
                }}
                ({{ selectedRecord.projectCurrency }})</span
              >
            </div>
          </div>
          <span class="float-left">Comments (Optional):</span>
          <b-textarea
            rows="4"
            v-model="selectedRecord.comment"
            placeholder="Edit your comments here..."
          ></b-textarea>
        </div>
        <hr class="m-0" />
        <div class="p-3">
          <b-button
            variant="outline-danger"
            @click="$bvModal.hide('edit-expense')"
            >Close</b-button
          >
          <b-button
            class="ml-3"
            variant="outline-primary"
            @click="updateExpense"
            >Save Changes</b-button
          >
        </div>
      </b-modal>

      <b-modal
        hide-footer
        centered
        title="Verification Invoice"
        id="verify-month-invoice"
        body-class="p-0"
      >
        <div class="d-block text-center p-3">
          <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left"
                >Are you Sure you want to submit this invoice:
              </span>
              <b-select
                v-model="filter.project"
                class="control-no-left-padding form-control"
              >
                <option
                  :key="index"
                  v-for="(project, index) in projects"
                  :value="project.ProjectID"
                  :disabled="true"
                >
                  {{ project.ProjectName }}
                </option>
              </b-select>
            </div>
          </div>
          <span class="float-left">Year & Month:</span>
          <b-select
            v-model="filter.date_range"
            class="control-no-left-padding form-control"
          >
            <option
              :key="index"
              v-for="(period, index) in periods"
              :value="period.key"
              :disabled="true"
            >
              {{ period.value }}
            </option>
          </b-select>
        </div>
        <hr class="m-0" />
        <div class="p-3">
          <b-button variant="outline-danger" @click="$bvModal.hide('edit-time')"
            >Close</b-button
          >
          <b-button class="ml-3" variant="outline-primary" @click="updateTime"
            >Save Changes</b-button
          >
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import TimeSheetData from "../core/TimeSheetData";
import FormatUtilities from "../utilities/FormatUtilities";
import ExpenseData from "../core/ExpenseData";
import { extractError } from "../utilities/ErrorUtilities";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      filter: {},
      periods: [],
      projects: [],
      filteredTimesheets: [],
      users: [],
      attachments: [],
      timesheets: [],
      expenses: [],
      filteredExpenses: [],
      selectedRecord: {},
      expenseTypes: []
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "getLoggedInUser"
    }),
    timesheetTotalHrs() {
      let timesheetTotalHrs = 0.0;
      for (let i = 0; i < this.timesheets.length; i++) {
        timesheetTotalHrs += this.timesheets[i].activityTime;
      }
      return timesheetTotalHrs;
    },
    authedUser() {
      return this.users && this.users[0] ? this.users[0] : null;
    },
    expenseTotal() {
      let expenseTotal = 0.0;
      for (let i = 0; i < this.expenses.length; i++) {
        expenseTotal += this.expenses[i].amount;
      }
      return expenseTotal;
    },
    timesheetTotal() {
      let timesheetTotal = 0.0;
      timesheetTotal = this.timesheetTotalHrs * 200;
      return (Math.trunc(timesheetTotal * 100) / 100).toFixed(2);
    },
    contractorTotal() {
      let contractorTotal = 0.0;
      contractorTotal = eval(this.timesheetTotal) + eval(this.expenseTotal);
      return contractorTotal;
    }
  },

  created() {
    this.setLoading(true);
    this.loadEmployees()
      .then(() => {
        this.loadPeriods();
        this.loadProjects();
        this.loadExpenseTypes();
      })
      .finally(() => this.setLoading(false));
  },

  methods: {
    formatDate: FormatUtilities.formatDate,
    formatPrice: FormatUtilities.formatPrice,

    async downloadExpenses() {
      try {
        this.setLoading(true);
        const periodData = await this.$axios.get("/expenses/download", {
          responseType: "blob",
          params: {
            period: this.filter.date_range,
            project: this.filter.project,
            user_id: this.authedUser.id
          }
        });
        this.downloadFile(periodData.data || null, "expenses.zip");
      } catch (err) {
        console.error(err);
        if (err.response.status === 404) {
          this.makeToast(
            "danger",
            "Files do not exist for current selection, please try modifying the filters."
          );
        }
      } finally {
        this.setLoading(false);
      }
    },

    downloadFile(blob, filename = "file") {
      if (!blob) {
        return;
      }
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.download = filename;
      link.click();
    },

    filterProjects() {
      this.filteredExpenses = this.expenses;
      this.filteredTimesheets = this.timesheets;

      if (!this.filter.project) {
        return;
      }
      let filteredExpenses = this.expenses.filter(
        (e) => e.projectId === this.filter.project
      );
      let filteredTimesheets = this.timesheets.filter(
        (t) => t.projectId === this.filter.project
      );
      this.$set(this, "filteredExpenses", filteredExpenses);
      this.$set(this, "filteredTimesheets", filteredTimesheets);
    },

    loadEmployees() {
      return new Promise(async (resolve, reject) => {
        try {
          var res = await this.$axios.get(`/employees?authed_user=true`);
          if (!res) {
            reject();
          }
          this.users = res.data;
          resolve();
        } catch (err) {
          reject();
        }
      });
    },

    loadPeriods() {
      if (!this.authedUser) {
        return;
      }
      try {
        let max_months = this.authedUser.diff <= 2 ? this.authedUser.diff : 2;

        let max_date = this.formatDate(this.authedUser.max_date);

        for (let month = max_months; month >= 0; month--) {
          let _month_year = this.$moment(max_date).subtract(month, "M");

          this.periods.push({
            key: `${_month_year.year()}-${_month_year.month()}`,
            value: _month_year.format("MMM - Y")
          });
        }
      } catch (err) {
        this.makeToast("danger", extractError(err));
      }
      this.fetchData();
    },

    async fetchData() {
      try {
        if (!this.filter.date_range || !this.authedUser) {
          return;
        }
        this.setLoading(true);

        const user_id = this.authedUser.id;
        const period = this.filter.date_range;

        const timesheets = await this.$axios.get(`/timesheets`, {
          params: {
            period,
            user_id
          }
        });

        const expenseData = await this.$axios.get(`/expenses`, {
          params: {
            period,
            user_id
          }
        });

        this.timesheets = timesheets.data.map((d) =>
          TimeSheetData.fromApi({
            ...d,
            user: this.currentUser.username
          })
        );

        this.expenses = expenseData.data.map((e) =>
          ExpenseData.fromApi({
            ...e,
            user: this.currentUser.username
          })
        );
      } catch (err) {
        this.makeToast("danger", extractError(err));
      } finally {
        this.setLoading(false);
      }
    },

    async editExpense(record) {
      if (!this.authedUser) {
        return this.makeToast("danger", "User could not be found.");
      }

      const user_id = this.authedUser.id;

      try {
        this.setLoading(true);
        await this.$axios.get(
          `/expenses/existTimeExpense/${user_id}/${
            record.projectId
          }/${record.expenseDate.substring(0, 7)}`
        );
        this.makeToast("danger", `${record.projectName} is not editable`);
      } catch (error) {
        if (error.status === 404) {
          this.selectedRecord = Object.assign({}, record);
          this.$bvModal.show("edit-expense");
        }
        console.error(error);
      } finally {
        this.setLoading(false);
      }
    },

    async editTime(record) {
      if (!this.authedUser) {
        this.makeToast("danger", "User could not be found.");
        return;
      }

      const user_id = this.authedUser.id;

      try {
        this.setLoading(true);
        await this.$axios.get(
          `/expenses/existTimeExpense/${user_id}/${
            record.projectId
          }/${record.activityDate.substring(0, 7)}`
        );
        this.makeToast("danger", `${record.projectName} is not editable`);
      } catch (error) {
        if (error.status === 404) {
          this.selectedRecord = Object.assign({}, record);
          return this.$bvModal.show("edit-time");
        }
        console.error(error);
      } finally {
        this.setLoading(false);
      }
    },

    removeFiles() {
      this.attachments = [];
    },

    async ShowAttachedFiles() {
      try {
        this.setLoading(true);

        var response = await this.$axios.get(`/expenses/attachments`, {
          params: {
            period: this.filter.date_range,
            project: this.filter.project,
            user_id: this.authedUser.id
          }
        });

        if (!response.data || !response.data.length) {
          return;
        }
        this.attachments = response.data;
        setTimeout(() => this.showFile(), 500);
      } catch (err) {
        console.error(err);
        if (err.status === 404) {
          this.makeToast(
            "danger",
            "Files do not exist for current selection, please try modifying the filters."
          );
        }
      } finally {
        this.setLoading(false);
      }
    },

    showFile() {
      this.attachments.forEach((file, key) => {
        var element = null;
        if (file.extension.toLowerCase() === "pdf") {
          element = document.createElement("embed");
          element.setAttribute("type", "application/pdf");
        } else {
          element = document.createElement("img");
        }

        element.setAttribute("id", `img-${key}`);
        element.setAttribute("class", "m-2 uploaded-file");
        element.setAttribute("src", file.url);
        element.setAttribute("width", 200);

        document
          .getElementsByClassName("preview-wrapper")[0]
          .appendChild(element);
      });
    },

    async verifyTimesheets() {
      if (!this.authedUser) {
        this.makeToast("danger", "User could not be found.");
        return;
      }

      if (
        !confirm(
          "Are you sure you want to submit all timesheets for the selected period?"
        )
      ) {
        return;
      }

      try {
        this.setLoading(true);
        var res = await this.$axios.put(`/timesheets/verify`, {
          period: this.filter.date_range
        });
        if (res && res.status === 200) {
          this.makeToast("success", "Selected expenses have been verified.");
          this.updateLocalObject(this.timesheets);
        }
      } catch (err) {
        console.error(err);
        this.makeToast("danger", "Error processing your request.");
      } finally {
        this.setLoading(false);
      }
    },

    async verifyExpenses() {
      if (!this.authedUser) {
        this.makeToast("danger", "User could not be found.");
        return;
      }

      if (
        !confirm(
          "Are you sure you want to submit all expenses for the selected period?"
        )
      ) {
        return;
      }

      try {
        this.setLoading(true);
        var res = await this.$axios.put(`/expenses/verify`, {
          period: this.filter.date_range
        });
        if (res && res.status === 200) {
          this.makeToast("success", "Selected expenses have been verified.");
          this.updateLocalObject(this.expenses);
        }
      } catch (err) {
        console.error(err);
        this.makeToast("danger", "Error processing your request.");
      } finally {
        this.setLoading(false);
      }
    },

    updateTime() {
      let data = this.$_.pick(this.selectedRecord, [
        "projectId",
        "activityTime",
        "activityComment"
      ]);
      this.setLoading(true);
      this.$bvModal.hide("edit-time");
      this.$axios
        .put(`/timesheets/${this.selectedRecord.id}`, data)
        .then(() => this.fetchData())
        .catch((err) => console.error(err))
        .finally(() => {
          this.setLoading(false);
          this.selectedRecord = {};
        });
    },

    updateExpense() {
      let data = this.$_.pick(this.selectedRecord, ["amount", "comment"]);
      this.setLoading(true);
      this.$bvModal.hide("edit-expense");
      this.$axios
        .put(`/expenses/${this.selectedRecord.id}`, data)
        .then(() => this.fetchData())
        .catch((err) => console.error(err))
        .finally(() => {
          this.setLoading(false);
        });
    },

    updateLocalObject(array) {
      array.forEach(
        (arr) =>
          (arr.verified_at = this.$moment().utc().format("YYYY-MM-DD HH:mm:ss"))
      );
    },
    async loadProjects() {
      try {
        const res = await this.$axios.get(`/projects?sort_by=ProjectName`);
        if (res && res.data.length > 0) {
          this.projects = res.data;
          this.project = res.data[0];
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loadExpenseTypes() {
      try {
        const expenseTypes = await this.$axios.get(`/expenses/types`);
        this.expenseTypes = expenseTypes.data;
      } catch (err) {
        console.error(err);
      }
    }
  },

  watch: {
    "filter.date_range": {
      handler(_new, _old) {
        this.fetchData();
      }
    },
    "filter.project"() {
      this.filterProjects();
    },
    expenses() {
      this.filterProjects();
    },
    timesheets() {
      this.filterProjects();
    }
  }
};
</script>
<style>
label {
  font-size: 18px;
}
h1 {
  font-size: 1.5rem !important;
  margin: 0;
}
hr {
  margin-top: 0;
}
.card {
  margin: 0;
}

/* .header-data{
color: black !important;
} */
</style>

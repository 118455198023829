<template>
  <div id="page-content-wrapper">
    <div id="admin-window">
      <div class="container-sm">
        <div class="row">
          <div class="col-8">
            <h1 class="mb-2">My Insight-AWP</h1>
          </div>
        </div>

        <hr />

        <div class="card-deck">
          <div
            class="card card-border btn-sp1 max"
            @click="$router.push({ name: 'emp-add-timesheet' })"
          >
            <div class="card-body text-center">
              <h4 class="card-title">Add Timesheet</h4>
              <p class="card-text">
                <i class="fas fa-business-time"></i>
              </p>
            </div>
          </div>
          <div
            class="card card-border btn-sp1 max"
            @click="$router.push({ name: 'emp-add-expense' })"
          >
            <div class="card-body text-center">
              <h4 class="card-title">Add Expense</h4>
              <p class="card-text">
                <i class="fas fa-money-bill-alt"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    isAdmin() {
      return this.isLoggedIn && this.currentUser.isAdmin;
    },

    isLoggedIn() {
      return this.currentUser !== null;
    },

    // mix the getters into computed with object spread operator
    ...mapGetters({
      currentUser: "getLoggedInUser"
    })
  },
  async created() {
    this.setLoading(true);
    await this.checkIsLoggedIn()
      .catch((e) => console.error(e))
      .finally(() => this.setLoading(false));
  }
};
</script>

<style scoped>
.card-deck .card-border {
  border: 1px solid #b7b7b7;
}
.card-deck .card-border:hover {
  background: #f2f2f2;
  cursor: pointer;
}
</style>

<template>
  <div :class="['text-container', className]">
    <div class="overlap-group">
      <img
        class="rectangle"
        alt="Rectangle"
        src="@/assets/images/newHome/Rectangle-5.png"
      />
      <img
        class="img"
        alt="Rectangle"
        src="@/assets/images/newHome/Rectangle-7.png"
      />
      <p class="learn-how-to-submit">
        Learn how to submit different types of expense <br />
        <br />
        Find out how to properly enter your timesheet
      </p>
      <div class="text-wrapper">Need Help?</div>
      <img
        class="user-manual"
        alt="User manual"
        src="@/assets/images/newHome/icons8-user-manual-96-1.png"
      />
      <img
        class="vector"
        alt="Vector"
        src="@/assets/images/newHome/Vector.svg"
        @click="$router.push({ name: 'doc-add-timesheet' })"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TextContainer",
  props: {
    className: {
      type: String,
      default: ""
    }
  }
};
</script>

<style>
.text-container {
  height: 331.1px;
  position: relative;
  width: 586.6px;
}

.text-container .overlap-group {
  height: 375.2px;
  width: 673.4px;
}

.text-container .rectangle {
  background-blend-mode: multiply;
  height: 364px;
  left: 2.1px;
  position: absolute;
  top: 6.3px;
  width: 660.8px;
}

.text-container .img {
  height: 364px;
  left: 2.8px;
  position: absolute;
  transform: rotate(-2.1deg);
  top: -24.5px;
  transition: all 0.2s ease;
  width: 660.8px;
}

.text-container .learn-how-to-submit {
  color: #000000;
  font-family: "Lato", sans-serif;
  font-size: 16.8px;
  font-weight: 500;
  left: 293.3px;
  letter-spacing: 0.504px;
  line-height: normal;
  position: absolute;
  top: 139.3px;
}

.text-container .text-wrapper {
  color: #000000;
  font-family: "Lato", sans-serif;
  font-size: 25.2px;
  font-weight: 700;
  left: 293.3px;
  letter-spacing: 0.756px;
  line-height: normal;
  position: absolute;
  top: 93.8px;
  white-space: nowrap;
}

.text-container .user-manual {
  height: 116.9px;
  left: 120.4px;
  object-fit: cover;
  position: absolute;
  top: 116.2px;
  width: 116.9px;
}

.text-container .vector {
  height: 39.2px;
  left: 539.7px;
  position: absolute;
  top: 252.7px;
  width: 39.2px;
  cursor: pointer;
}

.text-container:hover .img {
  transform: rotate(2.1deg);
}

@media (max-width: 1520px) {
  .text-container {
    height: 300px;
    width: 530px;
  }

  .text-container .overlap-group {
    height: 340px;
    width: 610px;
  }

  .text-container .rectangle {
    height: 330px;
    left: 2px;
    top: 6px;
    width: 600px;
  }

  .text-container .img {
    height: 330px;
    left: 2px;
    top: -28px;
    width: 600px;
  }

  .text-container .learn-how-to-submit {
    font-size: 16px;
    left: 293px;
    top: 122px;
  }

  .text-container .text-wrapper {
    font-size: 24px;
    left: 293px;
    top: 90px;
  }

  .text-container .user-manual {
    height: 125px;
    left: 120px;
    top: 125px;
    width: 125px;
  }

  .text-container .vector {
    height: 42px;
    left: 471px;
    top: 221px;
    width: 42px;
  }

  .text-container:hover .img {
    transform: rotate(2deg);
  }
}
</style>

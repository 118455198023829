<template>
  <div id="admin-window">
    <div class="container-sm">
      <h1>How to Add Timesheet</h1>
      <hr />

      <p>
        Timesheets can easily be added by navigating to "Add Timesheet" and
        filling out the simple form.<br />
        The form looks like the following:
      </p>

      <div class="custom_card card-1">
        <img
          class="img-fluid border"
          src="@/assets/images/docs/admin/image1.png"
          alt="Add Timesheet Image"
        />
      </div>

      <div class="p-2">
        <ol>
          <li>
            <strong>Project:</strong><br />
            This field is mandatory, and you must select the project for which
            you wish to submit the timesheet.<br />
            This can be modified later if need be.
          </li>
          <br />
          <li>
            <strong>Select Day(s):</strong><br />
            This field is mandatory, and you should be able to select one or
            more dates that match the description of the task performed. To
            select multiple days, you can either click multiple dates one by one
            or to select multiple days consecutively, hold down the shift key
            and select the last day of the group.<br /><br />
            Select any date from the <strong>Select Day(s)</strong> field to
            proceed further.
          </li>
          <br />
          <li>
            <strong>Adding Hours and Activity Comment:</strong><br />
            All the dates selected in the previous step will be displayed along
            with the <b>Hours</b> and <b>Activity Comment</b> fields. <br />
            <br />
            <b>Hours:</b> This field is mandatory, and you must enter the number
            of hours spent on the task for that specific project. <br />
            <br />
            <b>Activity Comment:</b> This field is mandatory, and you must enter
            a brief description of the task performed.
            <i>(Character Limit is 50)</i> <br />
            <div class="custom_card card-1">
              <img
                class="img-fluid border"
                src="@/assets/images/docs/admin/image1_1.png"
                alt="Add Timesheet Image"
              />
            </div>
            <br />
            <b>Tip:</b> If you have selected multiple dates and the hours spent
            on the task are the same for all the dates, you can simply enter the
            hours in the input next to the <b>Apply Hours To All</b> button and
            click the button to apply the hours to all the dates.<br />
            <br /><b>Tip:</b>You can also clear the hours for all the dates by
            clicking the <b>Clear All</b> button.
          </li>
          <br />
          <!-- <li>
            <strong>Adding Sub Project:</strong><br />
            If you have selected a project that has sub-projects, you will be
            able to see the <b>Sub Project</b> field. <br />
            <br />
            <b>Sub Project:</b> This field is mandatory, and you must select the
            sub-project for which you wish to submit the timesheet. <br />
            Adding hours and activity comment for the sub-project is similar to
            the steps mentioned above.
            <div class="custom_card card-1">
              <img
                class="img-fluid border"
                src="@/assets/images/docs/admin/image1_2.png"
                alt="Add Timesheet Image"
              />
            </div>
          </li> -->
          <br />
          <li>
            <strong>Save:</strong><br />
            Click save once all the fields are filled correctly. It may throw
            validation error if any field fails to satisfy the required input.
          </li>
        </ol>
      </div>
      <br />
      <br />
      <!-- <h1>How to Add Vacation/STAT Holiday in Timesheet</h1>
      <hr /> -->

      <!-- <div class="p-2">
        <p>
          Now you can add Vacation/STAT Holiday in Timesheet. To add
          Vacation/STAT Holiday in Timesheet, you need to follow the following
          steps:
        </p>
        <ol>
          <li>
            <strong>Project:</strong><br />
            In the project field, you can select either <b>Vacation</b> or
            <b>STAT Holiday</b> from the dropdown.
          </li>
          <br />
          <li>
            <strong>Activity Comments:</strong><br />
            This field is mandatory. <br />
            <ul>
              <li>For <b>Vacation</b>, write <u>Paid Vacation</u></li>
              <li>For <b>STAT Holiday</b>, write the name of the holiday</li>
            </ul>
            <br /><b>Note:</b> All the other steps are similar to the steps
            mentioned above.
          </li>
        </ol>

        <div class="custom_card card-1">
          <img
            class="img-fluid border"
            src="@/assets/images/docs/admin/image1_3_1.png"
            alt="Add Timesheet Image"
          />
        </div>

        <div class="custom_card card-1">
          <img
            class="img-fluid border"
            src="@/assets/images/docs/admin/image1_3_2.png"
            alt="Add Timesheet Image"
          />
        </div>
      </div> -->
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>

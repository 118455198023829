export default class UserNameData{

    constructor(
      //id = -1,
      id = "",
      name = "",
      userName = ""
    )
    {
      this.id = id;
      this.name = name;
      this.userName = userName;
    }
  
    static fromApi(data){
        return new UserNameData(
          data.id,
          data.Name,
          data.UserName
        )
    }
  
    toApi(){
      return{
        id: this.id,
        Name: this.name,
        UserName: this.userName
      }
    }
  
  }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h1',{staticClass:"text-center mb-4"},[_vm._v(" Welcome to Insight- AWP "),_c('em',[_c('b',[_vm._v(_vm._s(_vm.message))])]),_vm._v("! ")]),_c('p',[_vm._v(" This onboarding email will guide you through the first steps and help you settle in smoothly: ")]),_c('div',{staticClass:"py-3",attrs:{"id":"microsoft-account"}},[_c('a',{staticClass:"link d-block",attrs:{"href":"#microsoft-account"}},[_vm._v("Microsoft Account:")]),_c('ul',[_vm._m(0),_c('li',[_c('b',[_vm._v("Insight-AWP Email address:")]),_vm._v(" "),_c('em',[_vm._v(_vm._s(_vm.email))])])]),_c('br'),_vm._m(1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v(" We have set up a Microsoft Office 365 account for you at this "),_c('a',{attrs:{"href":"https://office.com/signin","target":"_blank"}},[_vm._v("link.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Also, you will be asked to setup your MFA (multifactor authentication) Mobile and other email address. ")]),_c('li',[_vm._v(" You can download Office 365 onto 5 Devices from the "),_c('a',{attrs:{"href":"https://office.com/signin","target":"_blank"}},[_vm._v("Office 365 online portal")]),_vm._v(", click on download on the main page. ")]),_c('li',[_vm._v(" You will need to "),_c('a',{attrs:{"href":"https://www.microsoft.com/en-us/microsoft-teams/download-app","target":"_blank"}},[_vm._v("download")]),_vm._v(" Microsoft Teams if you don’t have it installed. ")]),_c('li',[_vm._v("OneDrive is bundled with Office 365.")]),_c('li',[_vm._v(" You can access the Company SharePoint at this "),_c('a',{attrs:{"href":"https://insightawpcom.sharepoint.com/","target":"_blank"}},[_vm._v("link")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3",attrs:{"id":"personal-folder"}},[_c('a',{staticClass:"link d-block",attrs:{"href":"#personal-folder"}},[_vm._v("Personal Folder:")]),_c('ul',[_c('li',[_vm._v(" You can access your personal folder at this "),_c('a',{attrs:{"href":"https://insightawpcom.sharepoint.com/sites/Insight-AWP2/SitePages/Personal-Folders.aspx","target":"_blank"}},[_vm._v("link")]),_vm._v(". Please click on your name box ")]),_c('li',[_vm._v(" You can use this folder to store your personal files and invoices ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3",attrs:{"id":"erp"}},[_c('a',{staticClass:"link d-block",attrs:{"href":"#erp"}},[_vm._v("ERP:")]),_c('ul',[_c('li',[_vm._v(" You can access our ERP at this "),_c('a',{attrs:{"href":"https://erp.insight-awp.com/","target":"_blank"}},[_vm._v("link")]),_vm._v(". ")]),_c('li',[_vm._v(" To log into the Insight-AWP ERP system you will need to use your Insight-AWP email and password. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3",attrs:{"id":"company-onboarding"}},[_c('a',{staticClass:"link d-block",attrs:{"href":"#company-onboarding"}},[_vm._v("Company Onboarding:")]),_c('ul',[_c('li',[_vm._v(" Please review the Insight-AWP onboarding information in the ERP at this "),_c('a',{attrs:{"href":"https://erp.insight-awp.com/onboarding","target":"_blank"}},[_vm._v("link")]),_vm._v(". ")]),_c('li',[_vm._v(" Please acknowledge the Insight-AWP Code of Conduct at this "),_c('a',{attrs:{"href":"https://erp.insight-awp.com/codeOfConduct","target":"_blank"}},[_vm._v("link")]),_vm._v(". ")]),_c('li',[_vm._v(" Please compile the form at this "),_c('a',{attrs:{"href":"https://erp.insight-awp.com/profile","target":"_blank"}},[_vm._v("link")]),_vm._v(" with your personal information and click “Update.” ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3",attrs:{"id":"timesheet-expenses"}},[_c('a',{staticClass:"link d-block",attrs:{"href":"#timesheet-expenses"}},[_vm._v("Timesheet and Expenses:")]),_c('ul',[_c('li',[_vm._v("You can use the ERP System to compile Timesheets, and expenses.")]),_c('li',[_vm._v(" Click at this "),_c('a',{attrs:{"href":"https://erp.insight-awp.com/timesheets/create","target":"_blank"}},[_vm._v("link")]),_vm._v(" to view the Timesheet page. ")]),_c('li',[_vm._v(" Click at this "),_c('a',{attrs:{"href":"https://erp.insight-awp.com/expenses/create","target":"_blank"}},[_vm._v("link")]),_vm._v(" to view the Expenses page. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3"},[_c('p',[_vm._v(" If you have any questions or problems on this setup, please reach out to "),_c('a',{attrs:{"href":"mailto:Navjot.Randhawa@insight-awp.com"}},[_vm._v("Navjot")]),_vm._v(" or "),_c('a',{attrs:{"href":"mailto:gregorio.labbozzetta@insight-awp.com"}},[_vm._v("Gregorio")]),_vm._v(" anytime ")])])
}]

export { render, staticRenderFns }
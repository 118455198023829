<template>
  <div class="container">
    <div class="row">
      <div class="col-12 bg-success text-center py-3">
        <h1 class="values">Our Values</h1>
      </div>
    </div>
    <div class="row h-100 justify-content-center align-items-center">
      <div class="card-deck">
        <div
          class="card text-white bg-success"
          v-for="value in companyValues"
          :key="value.title"
        >
          <div class="card-header text-center">{{ value.title }}</div>
          <div class="card-body bg-light text-dark">
            <ul>
              <li v-for="point in value.points" :key="point">{{ point }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyValues: [
        {
          title: "Integrity",
          points: [
            "We are accountable, truthful, and honest in every aspect of our work.",
            "We do the right thing in the face of adversity and opposition.",
            "We build trust through responsible actions and honest relationships."
          ]
        },
        {
          title: "Leadership",
          points: [
            "We empower each other to take ownership of our actions that are framed in Quality Service, Excellence, and Innovation.",
            "Through education, we challenge the status quo with open minds, focus, and speed to shape a better future.",
            "Each of us is responsible for our words, our actions, and our results."
          ]
        },
        {
          title: "Collaboration",
          points: [
            "We empower each other to take ownership of our actions that are framed in Quality Service, Excellence, and Innovation.",
            "Through education, we challenge the status quo with open minds, focus, and speed to shape a better future.",
            "Each of us is responsible for our words, our actions, and our results."
          ]
        },
        {
          title: "Enthusiasm",
          points: [
            "We are authentic in finding ways and means to deliver positive outcomes",
            "We share in our customer’s curiosity, and their quest for knowledge and understanding that helps to fuel our shared vision"
          ]
        }
      ]
    };
  }
};
</script>

<style scoped>
.container {
  max-width: 90%;
  height: 80vh;
  margin-top: 0px;
}

li {
  margin-bottom: 1.5em; /* Adjust this value as needed */
}

.values {
  font-size: 2rem !important;
  font-weight: bold;
  color: white;
}

.card-header {
  font-weight: bold;
  font-size: 1.5rem;
}
</style>
